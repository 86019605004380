import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import TicketsManager from "../../components/TicketsManager/";
import Ticket from "../../components/Ticket/";
import openSocket from "../../services/socket-io";
import { i18n } from "../../translate/i18n";
import Hidden from "@material-ui/core/Hidden";
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    // // backgroundColor: "#eee",
    // padding: theme.spacing(4),
    height: `calc(100% - 48px)`,
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },
  blinking: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    animation: "$blinking 1s infinite",
  },
  "@keyframes blinking": {
    "0%": { opacity: 1 },
    "50%": { opacity: 0 },
    "100%": { opacity: 1 },
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Tickets = () => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const [refresh, setRefresh] = useState(0);
  const [socketState, setSocketState] = useState(true);
  const [prevSocketState, setPrevSocketState] = useState(socketState);


  useEffect(() => {
    if (socketState && !prevSocketState) {
      console.log("conectado ao socket Tickets");
      setRefresh(oldKey => oldKey + 1);
    } else if (!socketState) {
      console.log("desconectado ao socket Tickets");
    }
    setPrevSocketState(socketState);
  }, [socketState]);

  useEffect(() => {



    const interval = setInterval(() => {
      setRefresh(oldKey => oldKey + 1);
    }, 60000 * 10);

    return () => clearInterval(interval);


  }, []);

  useEffect(() => {

    const socket = openSocket();

    socket.on("connect", () => {
      setSocketState(true)

    });
    socket.on("connect_error", () => {
      setSocketState(false)
     
      // Lógica para lidar com erro de conexão
    });

    socket.on("disconnect", () => {
      setSocketState(false)
   
      // Lógica para lidar com desconexão
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className={classes.chatContainer}>

      {!socketState ? <div className={classes.blinking} style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000, // Certifique-se de que o z-index é alto o suficiente
        // Adicione um fundo para garantir visibilidade
      }}>
        <Tooltip title="Falha na sua conexão com a internet"><WifiOffIcon style={{ color: "orange", fontSize: 75 }} /></Tooltip>
      </div> : null}


      <div className={classes.chatPapper}>

        <Grid container spacing={0}>
          {/* <Grid item xs={4} className={classes.contactsWrapper}> */}
          <Grid
            item
            xs={12}
            md={4}
            className={
              ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
            }
          >
            <TicketsManager refresh={refresh} />

          </Grid>
          <Grid item xs={12} md={8} className={classes.messagessWrapper}>
            {/* <Grid item xs={8} className={classes.messagessWrapper}> */}
            {ticketId ? (
              <>
                <Ticket refresh={refresh} />
              </>
            ) : (
              <Hidden only={["sm", "xs"]}>
                <Paper className={classes.welcomeMsg}>
                  {/* <Paper square variant="outlined" className={classes.welcomeMsg}> */}
                  <span>{i18n.t("chat.noTicketMessage")}</span>
                </Paper>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Tickets;
