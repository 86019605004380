import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ListItemLink } from '../ListItemLink';
import { Notes } from '@material-ui/icons';
import { i18n } from '../../translate/i18n';

export default function SuperAdminMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button variant='contained' color='primary' size='large' aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
       Menu Super Admin
      </Button>
      <Menu
        style={{marginTop:59}}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
        <ListItemLink
                      to="/messageLogs"
                      primary={i18n.t("mainDrawer.listItems.messageLogs")}
                      icon={<Notes />}
                    />

        </MenuItem>

      </Menu>
    </div>
  );
}
