const messages = {
  pt: {

    translations: {
      common: {
        save:"Salvar",
        cancel: "Cancelar",
        goBack: "Voltar",
        actions:"Ações",
        accept: "Aceitar",
        start: "Iniciar",
      
      },
      notWorkingHoursModal:{
        title:{
          add:"Adicionar horário",
          edit:"Editar horário"
        },
        form:{
          message:"Mensagem personalizada",
          type:"Tipo de expediente",
          description:"Descrição",
          startHour:"Inicio do não expediente",
          endHour:"Encerramento do não expediente"
        }
      },
      
      workingHoursModal:{
        title:{
          add:"Adicionar horário",
          edit:"Editar horário"
        },
        form:{
          type:"Tipo de expediente",
          description:"Descrição",
          startHour:"Inicio do expediente",
          endHour:"Encerramento do expediente"
        }
      },
      weeklyHour:{
        form:{
          weeklyDay:"Dia da semana",
          type:"Tipo de expediente",
          description:"Descrição",
          startHour:"Inicio do expediente",
          endHour:"Encerramento do expediente"
        }
      },
      expedient:{
        toasts:{
          edited: "Expediente editado com sucesso",
          created: "Expediente criado com sucesso, crie os horários no formulário a baixo",
          deleted: "Expediente deletado com sucesso",
          createdHigh:"Expediente criado com sucesso através de um template padrão, você deve renomear e ajustar os horários de acordo com a sua necessidade",

        },
        buttons:{
          addNormal:"Criar Expediente",
          addHigh:"Criar Expediente modo rápido"
        },
        title:{
          edit:"Editar horário de expediente",
          add:"Criar horário de expediente",
          title:"Horário de expediente"
        },
        table:{
          name:"Nome",
          message:"Mensagem"
        },
        form:{
          name:"Nome",
          message:"Mensagem de fora de horário",
        }
      },
      notWorkingHours:{
        type:{
          yearly:"Anual",
          weekly:"Semanal"
        },
        title:"Horário de Não funcionamento",
        buttons:{
          add: "Adicionar Horário"
        },
        table:{
          type:"Tipo",
          description:"Descrição",
          startHour:"Inicio do não expediente",
          endHour:"Termino do não expediente"
        }
      },
      
      workingHours:{
        type:{
          yearly:"Anual",
          weekly:"Semanal"
        },
        title:"Horário de funcionamento",
        buttons:{
          add: "Adicionar Horário"
        },
        table:{
          type:"Tipo",
          description:"Descrição",
          startHour:"Inicio do expediente",
          endHour:"Termino do expediente"
        }
      },
      reports: {
        nps: "Pesquisa de satisfação (NPS)",
        reports: "Relatórios",
        reportsGenerate: "Gerar Relatórios",
        reportService: "Atendimentos",
        indicators: "Indicadores",
        satisfactionSurvey: "Pesquisa de satisfação",
        initialDate: "Data Inicial",
        finalDate: "Data Final",
        generate: "Gerar Relatório",
        attendance: "Relatório de atendimento",
        classification: "Relatório de classificação"

      },
      toForward: {
        success: "Encaminhado com sucesso"

      },
      classificationModal: {
        form: {
          description: "Observação",
          classification: "Classificação",
          subClassification: "SubClassificação"
        },
        buttons: {
          okAdd: "Classificar",
          okEdit: "Salvar Classificação",
          notClassification: "Não Classificar"
        },

        title: {
          add: "Criar Classificação",
          edi: "Editar Classificação"
        },
        success: "Classificação criada com sucesso"
      },

      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets hoje: ",
          },
        },
        messages: {
          inAttendance: {
            title: "Em Atendimento"
          },
          waiting: {
            title: "Aguardando"
          },
          closed: {
            title: "Finalizado"
          },
        },
      },
      bots: {
        title: "Chatbot",
        table: {
          commandBot: "Comando",
          commandType: "Tipo",
          description: "Descrição",
          showMessage: "Mensagem de saudação",
          userId: "Atendente",
          queueId: "Setor",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar opção",
          hierarchy: "Estrutura do Bot",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida!",
        },
        messages: {
          saved: "Comando salvo com sucesso!",
          deleted: "Comando deletado com sucesso!",
        },
        hierarchy: {
          title: "Estrutura do Bot"
        }
      },
      botModal: {
        success: "Fluxo adicionado com sucesso",
        title: {
          add: "Adicionar comando",
          edit: "Editar comando",
        },
        form: {
          commandBot: "Comando",
          descriptionBot: "Descrição",
          commandType: "Tipo",
          showMessage: "Mensagem",
          userId: "Atendente",
          queueId: "Setor",
        },
        messages: {
          saved: "Comando salvo com sucesso!",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          restartapp:"Reiniciar Conexões",
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          countMaxMsgReturnWebhook:"Limite de mensagens retornadas webhook",
          webhookUrl:"Url webhook",
          countErrorsBot:"Contagem de erros maxima BOT",
          messageOnErrorsBot:"Mensagem de captura de erros do BOT",
          name: "Nome",
          default: "Padrão",
          farewellMessage: "Mensagem de despedida"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importas todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
        },
        table: {
          queue:"Fila para isolamento do BOT",
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          queueSelect:"Fila para isolamento do BOT",
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          expedient:"Expediente",
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          token: "Token",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          enableTransfer:"Habilitar mensagem de transferência?",
          enableFarewell:"Habilitar mensagem de despedida?",
          enableGreeting: "Habilitar mensagem de saudação?",
          allowViewPendingTickets: "Permite visualizar tickets pendentes?",
          allowEnableGreeting: "Permite usuário desabilitar mensagem de saudação, despedida e transferência?",
          allowTicketExport: "Permite usuário exportar ticket?",
          allowNoQueue: "Permite para este usuários visualizar os ticket sem fila?",
          allowMultipleTickets: "Permite criar múltiplos tickets em aberto para um contato, não recomendado",
          allowViewClosedTickets: "Permite usuário visualizar os tickets fechados, quais?",
          allowNoQueue_short: "Sem fila",
          classificationPermission: "Permite usuário classificar Ticket?",
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      scheduleModal: {
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },
      tagModal: {
        title: {
          add: "Nova Tag",
          edit: "Editar Tag",
        },
        form: {
          name: "Nome",
          color: "Cor"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Tag salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldQueuePlaceholder: "Selecione uma fila",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      categoryClModal: {
        title: {
          add: "Criar Classificação",
          edit: "Editar Classificação"
        },
        form: {
          name: "Classificação"
        },
        buttons: {
          cancel: "Cancelar",
          okAdd: "Adicionar Classificação",
          okEdit: "Salvar"
        }
      },
      subCategoryClModal: {
        title: {
          add: "Criar Subclassificação",
          edit: "Editar Subclassificação"
        },
        form: {
          name: "Sub Classificação"
        },
        buttons: {
          cancel: "Cancelar",
          okAdd: "Adicionar Subclassificação",
          okEdit: "Salvar"
        }
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum ticket encontrado com esse status ou termo pesquisado",
        buttons: {
          accept: "Aceitar",
        },
        acceptModal: {
          title: "Aceitar Chat",
          queue: "Selecionar setor",
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      classifications: {
        title: "Classificação",
        table: {
          classification: "Classificação",
          actions: "Ações"
        },
        buttons: {
          classification: "Adicionar Classificação",
        }

      },
      nps: {
        status: {
          notResponded: "Não Respondeu",
          notAuthorized:"Não Participou"
        }
      },
      npsSurvey: {
        title: "Pesquisas de satisfação (NPS)",
        table: {
          name: "Pesquisa de satisfação",
          description: "Descrição",
          actions: "Ações"
        },
        buttons: {
          addNormal: "Criar pesquisa modo avançado",
          addHigh: "Criar pesquisa modo rápido",
        },
        toasts: {
          edited: "Nps editado com sucesso",
          created: "Nps criado com sucesso, crie as opções de seleção no formulário a baixo",
          deleted: "Nps deletado com sucesso"
        },
        confirmationModal: {
          deleteTitle: "Deletar Pesquisa de satisfação (NPS)",
          deleteMessage: "Este processo é irreversível !!!"
        }

      },
      npsOptions: {
        title: "Notas de classificação",
        table: {
          order: "Ordem de exibição",
          command: "Comando",
          description: "Descrição",
          actions: "Ações"
        },
        buttons: {
          add: "Adicionar",
        },
        toasts: {
          edited: "Nota editada com sucesso",
          created: "Nota criada com sucesso",
        },
        confirmationModal: {
          deleteTitle: "Deletar Pesquisa de satisfação (NPS)",
          deleteMessage: "Este processo é irreversível !!!"
        }


      },
      npsOptionModal: {
        title: {
          add: "Adicionar opção de nota a pesquisa de satisfação (NPS)",
          edit: "Editar Configurações de Pesquisa de Satisfação (NPS)"
        },
        form: {
          command: "Comando da nota",
          order: "Ordem de exibição no menu",
          description: "Descrição da nota",
          msgReqParticipation: "Mensagem de solicitação de participação",
          msgThankNotParticipation: "Mensagem de agradecimento por não participação",
          msgThankParticipation: "Mensagem de agradecimento por participação",
          msgReqPoints: "Mensagem de solicitação da nota",
          msgReqComments: "Mensagem de solicitação de comentário",
          timeEndOfNotResponse: "Tempo limite minutos",
          timeEndOfNotResponseExplication: "Tempo limite em minutos se o contato não responder a pesquisa",
          msgUnreportedClosed: "Mensagem por tempo esgotado",
        },
        buttons: {
          add: "Criar",
          edit: "Editar"
        }

      },
      npsSurveyModal: {
        title: {
          add: "Configurações de Pesquisa de Satisfação (NPS)",
          edit: "Editar Configurações de Pesquisa de Satisfação (NPS)"
        },
        form: {
          name: "Nome da pesquisa",
          description: "Descrição",
          msgReqParticipation: "Mensagem de solicitação de participação",
          msgThankNotParticipation: "Mensagem de agradecimento por não participação",
          msgThankParticipation: "Mensagem de agradecimento por participação",
          msgReqPoints: "Mensagem de solicitação da nota",
          msgReqComments: "Mensagem de solicitação de comentário",
          timeEndOfNotResponse: "Tempo limite minutos",
          timeEndOfNotResponseExplication: "Tempo limite em minutos se o contato não responder a pesquisa",
          msgUnreportedClosed: "Mensagem por tempo esgotado",
        },
        buttons: {
          add: "Criar",
          edit: "Editar"
        }

      },
      subClassifications: {
        title: "Subclassificação",
        table: {
          subClassification: "Subclassificação",
          actions: "Ações"
        },
        buttons: {
          subClassification: "Adicionar Subclassificação",
        }

      },
      mainDrawer: {
        listItems: {
          expedient:"Horário de expediente",
          messageLogs: "Logs de Mensagens",
          bots: "Chat Bot",
          npsSurvey:"Pesquisa de satisfação NPS",
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Atendimento",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          schedules: "Agendamentos",
          queues: "Filas",
          tags: "Tags",
          classification: "Classificação",
          administration: "Administração",
          users: "Usuários",
          settings: "Configurações",
          messagesAPI: "API",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      messagesAPI: {
        title: 'API',
        textMessage: {
          number: 'Número',
          body: 'Mensagem',
          token: 'Token cadastrado',
        },
        mediaMessage: {
          number: 'Número',
          body: 'Nome do arquivo',
          media: 'Arquivo',
          token: 'Token cadastrado',
        }
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      queues: {
        title: "Filas",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          actions: "Ações",
          none: "Nenhum",
          all: "Todos",
          user: "Próprios",
          allowClosedTickets: "Ver Tickets Fechados",
          allowNoQueue: "Filas",
          status:"Status",
          lastPresence:"Confirmação de presença"

        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
        },
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          actions: "Ações",
        },
        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        }
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Tag?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          name: "Nome",
          color: "Cor",
          tickets: "Registros Tagdos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Tag",
        },
        toasts: {
          deleted: "Tag excluído com sucesso.",
        }
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
            newTicket: "Nova Conversa"
          },
        },
      },
      messagesInput: {
        greeting: "Saudação",
        placeholderOpen: "Digite uma mensagem",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        schedule: "Agendamento",
        delete: "Deletar",
        transfer: "Transferir",
        confirmationModal: {
          title: "Deletar o ticket do contato",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        toForward: "Encaminhar",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um tíquete aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_USER_CREATION_LIMITED:
          " Não foi possível criar usuário porque sua conta atingiu o limite, entre contato com o administrador para solicitar uma alteração no seu plano",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_INVALID_TOKEN_FOUND: "Token invalido",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhatsApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de uma fila.",
          ERR_VALIDATE_QUICK_ANSWER:
          "Erro na validação da resposta Rápida",
          ERR_VALIDATE_CONTACT:
          "Erro na validação do contato",
          ERR_SOCKET_NOT_INIT:
          "Erro na validação da resposta Rápida",
          ERR_NO_BAILEYS_DATA_FOUND:"Algum problema na baileys"
      },
    },
  },
};

export { messages };