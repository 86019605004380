import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { CircularProgress, Button, Dialog, DialogContent, TextField, FormControl, DialogActions } from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";





const useStyles = makeStyles(theme => ({
    button: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ExportMessages = ({ contact, ...rest }) => {
    const initialState = {
        initialDate: moment().add(-30, "days").format("YYYY-MM-DDTHH:mm"),
        finalDate: moment().add(0, "days").format("YYYY-MM-DDTHH:mm"),
    };

    const classes = useStyles();
    const [initial, setInitial] = useState(initialState);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setInitial(initialState);
    };


    const handleExport = async (values) => {
        const dataGenerate = ({ ...values, contact })
        setLoading(true)
        try {

            const { data } = await api.post("/ticketsExport", dataGenerate);
            if (data) {
                const link = document.createElement('a');
                link.href = data;
                link.download = 'nome_do_arquivo.extensao'; // Especifique o nome e a extensão do arquivo
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(data);
                setLoading(false);
                setOpen(false);
                setInitial(initialState);
                //handleClose;
            } else {
                setLoading(false)
                console.error('Falha ao fazer o download do arquivo');
            }
        } catch (error) {
            setLoading(false)
            toastError(error);
            console.error('Erro durante o download do arquivo:', error);
        }
    };

    return (<>


        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            fullWidth
            scroll="paper"
        >

            <Formik
                initialValues={initial}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleExport(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ touched, errors, isSubmitting, values, props }) => (
                    <>

                        <Form>
                            <DialogContent dividers>
                                <h3>Exportação de conversas</h3>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("reports.initialDate")}
                                        type="datetime-local"
                                        name="initialDate"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={touched.sendAt && Boolean(errors.sendAt)}
                                        helperText={touched.sendAt && errors.sendAt}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <hr></hr>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("reports.finalDate")}
                                        type="datetime-local"
                                        name="finalDate"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={touched.sendAt && Boolean(errors.sendAt)}
                                        helperText={touched.sendAt && errors.sendAt}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <hr></hr>
                                {/*                                 <div className={classes.multFieldLine}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                            fullWidth
                                            name="queue"
                                            value={currentQueue}
                                            options={queues}
                                            required
                                            onChange={(e, queue) => {
                                                setCurrentQueue(
                                                    queue ? queue : initialState.initialQueue
                                                );
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => {
                                                return value.id === option.id;
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Fila"
                                                    placeholder="Fila"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>
                                <hr></hr>
                                <div className={classes.multFieldLine}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                            fullWidth
                                            value={currentUser}
                                            options={users}
                                            required
                                            onChange={(e, user) => {
                                                setCurrentUser(user ? user : initialState.initialUser);
                                            }}
                                            getOptionLabel={(user) => user.name}
                                            getOptionSelected={(user, value) => {
                                                return value.id === user.id;
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Atendente"
                                                    placeholder="Atendente"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div> */}
                                <br />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("common.cancel")}
                                </Button>
                                {/*                                 <Button
                                    type="submit"
                                    color="primary"
                                    //disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    EXPORTAR
                                </Button> */}
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    className={classes.button}
                                    disabled={loading}
                                //startIcon={<SaveAlt />}
                                //onClick={() => setOpen(true)}
                                >
                                    Exportar
                                    {loading && (
                                        <CircularProgress size={24} className={classes.buttonProgress} />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    </>
                )}
            </Formik>
        </Dialog>


        <Button
            size="small"
            variant="contained"
            color="default"
            className={classes.button}
            disabled={loading}
            startIcon={<SaveAlt />}
            onClick={() => setOpen(true)}
            {...rest} >
            Exportar
            {loading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
            )}
        </Button>


    </>

    );
};

export default ExportMessages;
