import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    width: 300,
    // marginBottom: 20
  },
  maxWidth: {
    width: "100%",
  },
  buttonColorError: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

const AcceptTicketWithouSelectQueue = ({
  modalOpen,
  onClose,
  ticket
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [selectedQueue, setSelectedQueue] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
 const {  queueId, uuid, id } = ticket
 
  const handleClose = () => {
    onClose();
    setSelectedQueue("");
  };

  const handleUpdateTicketStatus = async (queueId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id || null,
        queueId: queueId,
        reason:"user-accept"
      });

      setLoading(false);
      if (user.allowViewPendingTickets){
        history.push(`/tickets/`);
        await new Promise(r => setTimeout(r, 200));
      }
      history.push(`/tickets/${uuid}`);
      handleClose();
   
    } catch (err) {
      console.log(err)
      setLoading(false);
      toastError(err);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      if (queueId) {
        setTimeout(() => {
          handleUpdateTicketStatus(queueId);
        }, 10);
      } else if (user.queues.length === 1) {
        setTimeout(() => {
          handleUpdateTicketStatus(user.queues[0].id);
        }, 10);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  return (
    <>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          {i18n.t("common.accept")}
        </DialogTitle>
        <DialogContent dividers>
          <FormControl variant="outlined" className={classes.maxWidth}>
            <InputLabel>{i18n.t("ticketsList.acceptModal.queue")}</InputLabel>
            <Select
              value={selectedQueue}
              className={classes.autoComplete}
              onChange={(e) => setSelectedQueue(e.target.value)}
              label={i18n.t("ticketsList.acceptModal.queue")}
            >
              <MenuItem value={""}>&nbsp;</MenuItem>
              {user.queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>
                  {queue.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className={classes.buttonColorError}
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("common.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="button"
            disabled={selectedQueue === ""}
            onClick={() => handleUpdateTicketStatus(selectedQueue)}
            color="primary"
            loading={loading}
          >
            {i18n.t("common.start")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AcceptTicketWithouSelectQueue;
