import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import * as Sentry from "@sentry/react";
import App from "./App";
Sentry.init({
	dsn: process.env.SENTRY_DSN,
	integrations: [
	  new Sentry.BrowserTracing({
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	  }),
	  new Sentry.Replay({
		maskAllText: false,
		blockAllMedia: false,
	  }),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });



ReactDOM.render(
	<CssBaseline>
		<App />
	</CssBaseline>,
	document.getElementById("root")
);

// ReactDOM.render(
// 	<React.StrictMode>
// 		<CssBaseline>
// 			<App />
// 		</CssBaseline>,
//   </React.StrictMode>

// 	document.getElementById("root")
// );
