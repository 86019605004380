import React, { useState,   } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

import ContactAutocomplete from "./contactAutocomplete";
const useStyles = makeStyles(theme => ({
	forward: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	// formControl: {
	// 	margin: theme.spacing(1),
	// 	minWidth: 120,
	// },
}));


const ForwardModal = ({ open, onClose, message }) => {
	const classes = useStyles();
	const initialContact = {
		id: "",
		name: ""
	}

	const [currentContacts, setCurrentContact] = useState([initialContact]);
	const [signMessage, setSignMessage] = useState(true);


	const handleClose = () => {
		onClose();
	};
	const handleForward = async () => {

		const values = { currentContacts, message, signMessage }
		await api.post("/forwardmessage", values);
		//console.log("teste", currentContacts, message ,{signMessage})
		handleClose();
		toast.success(i18n.t("toForward.success"));
	};
	return (
		<div className={classes.forward}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<Formik
					enableReinitialize={true}
				>
					{({ isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<FormControl
										variant="outlined"
										fullWidth
									>

										<ContactAutocomplete
											selectedContacts={e => setCurrentContact(e)}
										/>
									</FormControl>
								</div>
							</DialogContent>
							<DialogActions>
								<FormControlLabel
									style={{ marginRight: 7, color: "gray" }}
									label={i18n.t("messagesInput.signMessage")}
									labelPlacement="start"
									control={
										<Switch
											size="small"
											checked={signMessage}
											onChange={(e) => {
												setSignMessage(e.target.checked);
											}}
											name="showAllTickets"
											color="primary"
										/>
									}
								/>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("scheduleModal.buttons.cancel")}
								</Button>

								<Button
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
									onClick={() => handleForward()}
								>
									{`Encaminhar`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>

							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ForwardModal;
