import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NpsOptionModal from "../../components/NpsOptionModal";
import { async } from "@dabeng/react-orgchart";

const reducer = (state, action) => {
  if (action.type === "LOAD_DATAS") {
    const npsSurveys = action.payload;
    const newNpsSurveys = [];

    npsSurveys.forEach((npsSurvey) => {
      const npsSurveyIndex = state.findIndex(
        (u) => u.id === npsSurvey.id
      );
      if (npsSurveyIndex !== -1) {
        state[npsSurveyIndex] = npsSurvey;
      } else {
        newNpsSurveys.push(npsSurvey);
      }
    });

    return [...state, ...newNpsSurveys];
  }

  if (action.type === "UPDATE_DATAS") {
    const npsSurvey = action.payload;
    const npsSurveyIndex = state.findIndex(
      (u) => u.id === npsSurvey.id
    );

    if (npsSurveyIndex !== -1) {
      state[npsSurveyIndex] = npsSurvey;
      return [...state];
    } else {
      return [npsSurvey, ...state];
    }
  }

  if (action.type === "DELETE_DATA") {
    const npsSurveyId = action.payload;

    const npsSurveyIndex = state.findIndex(
      (u) => u.id === npsSurveyId
    );
    if (npsSurveyIndex !== -1) {
      state.splice(npsSurveyIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    margin: theme.spacing(1),
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  headerBar: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-around",
  },
}));

const NpsSurvey = () => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedNpsSurvey, setSelectedNpsSurvey] =
    useState(null);

  const [deletingNpsSurvey, setDeletingNpsSurvey] =
    useState(null);
  const [npsOptionModalOpen, setNpsOptionModalOpen] =
    useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [npsSurveys, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  const fetchNpsSurveys = async () => {
    try {
      const { data } = await api.get("/npsSurvey/", {
        params: { searchParam, pageNumber },
      });
      dispatch({
        type: "LOAD_DATAS",
        payload: data.npsSurveys,
      });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchNpsSurveys();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("npsSurvey", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({
          type: "UPDATE_DATAS",
          payload: data.npsSurvey,
        });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_DATA",
          payload: +data.npsSurveyId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleCreteNpsHigh = async () => {

    try {

        const {data} = await api.post("/npsSurveyHigh");
        toast.success(i18n.t("npsOptions.toasts.created"));
        history.push(`./npsManager/${data.id}`) 
      }
      
     catch (err) {
      toastError(err);
    }


  };


  const handleCloseNpsOptionModal = () => {
    setSelectedNpsSurvey(null);
    setNpsOptionModalOpen(false);
    fetchNpsSurveys();
  };


  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditNpsSurvey = (npsSurvey) => {
    setSelectedNpsSurvey(npsSurvey);
    setNpsOptionModalOpen(true);
  };

  const handleDeleteNpsSurvey = async (
    npsSurveyId
  ) => {
    try {
      await api.delete(`/npsSurvey/${npsSurveyId}`);
      toast.success(i18n.t("npsSurvey.toasts.deleted"));
      dispatch({ type: "RESET" });
      fetchNpsSurveys();
    } catch (err) {
      toastError(err);
    }
    setDeletingNpsSurvey(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingNpsSurvey &&
          `${i18n.t("npsSurvey.confirmationModal.deleteTitle")} ${
            deletingNpsSurvey.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() =>
          handleDeleteNpsSurvey(deletingNpsSurvey.id)
        }
      >
        {i18n.t("npsSurvey.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <NpsOptionModal
        open={npsOptionModalOpen}
        onClose={handleCloseNpsOptionModal}
        aria-labelledby="form-dialog-title"
        npsSurveyId={
          selectedNpsSurvey && selectedNpsSurvey.id
        }
      />
      <MainHeader>
        <Title>{i18n.t("npsSurvey.title")}</Title>
      </MainHeader>
      <Grid spacing={10} container>
        <Grid item xs={12}>
          <div className={classes.headerBar}>
            <TextField
              placeholder={i18n.t("contacts.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"

              onClick={()=>{ history.push("/npsManager");}}
            >
              {i18n.t("npsSurvey.buttons.addNormal")}
            </Button>
            <Button
              variant="contained"
              color="primary"

              onClick={handleCreteNpsHigh}
            >
              {i18n.t("npsSurvey.buttons.addHigh")}
            </Button>
          </div>

          <Paper
            style={{ minHeight: "500px", maxHeight: "500px" }}
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {i18n.t("npsSurvey.table.name")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("npsSurvey.table.description")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("npsSurvey.table.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {npsSurveys.map((npsSurvey) => (
                    <TableRow key={npsSurvey.id}>
                      <TableCell align="center">
                        {npsSurvey.name}
                      </TableCell>
                      <TableCell align="center">
                        {npsSurvey?.description}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => { history.push(`./npsManager/${npsSurvey.id}`) }
                          }
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingNpsSurvey(
                              npsSurvey
                            );
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton columns={1} />}
                </>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default NpsSurvey;
