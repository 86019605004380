import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete/Autocomplete";
import { TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const filterOptions = createFilterOptions({
  trim: true,
});
/* 
<AutoCompleteGeneric
  label={i18n.t("classificationAutoComplete.fieldLabel")}
  route={"categoryCl"}
  value={testeValue}
  onChange={(values) => console.log(values)}
/> */

const AutoCompleteGeneric = ({ label, route, value, onChange, required, autoFocus ,...rest }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selected, setSelected] = useState(null);



  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/${route}`);
        setOptions(Object.values(data)[0]);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);
  
  useEffect(() => {
    (async () => {
      try {
        if (value?.id) {
          setSelected(value);
        }
      } catch (err) {
        toastError(err);
      }
    })();
  }, [value]);
  
  useEffect(() => {
    if (searchParam.length < 2) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchDatas = async () => {
        try {
          const { data } = await api.get(`/${route}`, {
            params: { searchParam },
          });
          setOptions(Object.values(data)[0]);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchDatas();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam]);

  return (
    <div >
      <FormControl fullWidth margin="dense" variant="outlined">
        <Autocomplete
          {...rest}
          getOptionLabel={(option) => `${option.name}`}
          onChange={(e, newValue) => {
            setSelected(newValue);
            onChange(newValue);
          }}
          value={selected}
          options={options}
          filterOptions={filterOptions}
          noOptionsText={i18n.t("Resultado não encontrado")}
          //freeSolo
          //autoHighlight
          {...rest}
          
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              size="small"
              variant="outlined"
              autoFocus={autoFocus}
              required={required}
              onChange={(e) => setSearchParam(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

export default AutoCompleteGeneric;
