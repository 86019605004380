import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Grid } from "@material-ui/core";
import AutoCompleteGeneric from "../AutoCompleteGeneric";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    marginRight: "10px",
    marginTop: "10px",
    minWidth: 120,
  },
  formControlEnd: {
    marginRight: "10px",
    marginTop: "10px",
    minWidth: 140,
  },
}));

const UserSchema = Yup.object().shape({

});

const ClassificationModal = ({
  open,
  onClose,
  classificationId,
  ticketId,
  user,
  onSuccess
}) => {
  const classes = useStyles();
  const initialState = {
    description: "",
  };

  const [classification, setClassification] = useState(initialState);
  const [categoryCl, setCategoryCl] = useState(initialState);
  const [subCategoryCl, setSubCategoryCl] = useState(initialState);
  useEffect(() => {
    const fetchUser = async () => {
      if (!classificationId) return;
      try {
        const { data } = await api.get(`/classification/${classificationId}`);
        setClassification((prevState) => {
          return { ...prevState, ...data };
        });
      } catch (err) {
        toastError(err);
      }
    };

    fetchUser();
  }, [classificationId, open]);

  const handleClose = () => {
    onClose();
    setClassification(initialState);
  };

  const handleNotClassification = () => {
    onSuccess(true)
    handleClose()
  }

  const handleSaveUser = async (values) => {
    const classificationData = {
      ...values,
      categoryClId: categoryCl?.id,
      subCategoryClId: subCategoryCl?.id,
      ticketId: ticketId,
    };
    try {
      if (classificationId) {
        await api.put(
          `/classification/${classificationId}`,
          classificationData
        );
      } else {
        await api.post("/classification", classificationData);
      }
      toast.success(i18n.t("classificationModal.success"));
      onSuccess(true)
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {classificationId
            ? `${i18n.t("classificationModal.title.edit")}`
            : `${i18n.t("classificationModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={classification}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveUser(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <AutoCompleteGeneric
                        id="classificationModal_categoryCl"
                        name="categoryCl"
                        label={i18n.t("classificationModal.form.classification")}
                        route={"categoryCl"}
                        value={null}
                        onChange={(values) => setCategoryCl(values)}
                        required={true}
                        autoFocus
                        noOptionsText={i18n.t("Classificação não encontrada")}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <AutoCompleteGeneric
                        id="classificationModal_subCategoryCl"
                        name="subCategoryCl"
                        label={i18n.t(
                          "classificationModal.form.subClassification"
                        )}
                        route={"subCategoryCl"}
                        value={null}
                        required={user?.classificationPermission === "optional_S" || user?.classificationPermission === "required_S"}
                        onChange={(values) => setSubCategoryCl(values)}
                        noOptionsText={i18n.t("SubClassificação não encontrada")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        id="classificationModal_form_description"
                        as={TextField}
                        label={i18n.t("classificationModal.form.description")}
                        minRows={6}
                        multiline={true}
                        name="description"
                        error={
                          touched.description && Boolean(errors.description)
                        }
                        helperText={touched.description && errors.description}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("common.cancel")}
                </Button>
                {(user?.classificationPermission === "optional" || user?.classificationPermission === "optional_S") ? (
                  <Button
                    onClick={handleNotClassification}
                    color="secondary"
                    disabled={isSubmitting}
                    variant="contained"
                  >
                    {i18n.t("classificationModal.buttons.notClassification")}
                  </Button>
                ) : (
                  <></>
                )}
                <Button
                  id="classificationModal_buttons_save"
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {classificationId
                    ? `${i18n.t("classificationModal.buttons.okEdit")}`
                    : `${i18n.t("classificationModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ClassificationModal;
