import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Add, CheckBox, MoreVert, Replay } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import ClassificationModal from "../ClassificationModal";
import NewTicketModal from "../NewTicketModal";
import ExportMessages from "../ExportMessages";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: 6,
    marginTop: 6,
    //flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
}));

const TicketActionButtons = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [classificationModalOpen, setClassificationModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const handleOpenClassificationModal = () => {
    setClassificationModalOpen(true);
  };
  const handleCloseClassificationModal = () => {
    setClassificationModalOpen(false);
  };

  const handleOpenTicketOptionsMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleClassification = async (e, status, userId) => {
    if (
      status === "closed" &&
      (user?.classificationPermission === "required" ||
        user?.classificationPermission === "optional" ||
        user?.classificationPermission === "optional_S" ||
        user?.classificationPermission === "required_S")
    ) {
      handleOpenClassificationModal();
      return;
    } else {
      await handleUpdateTicketStatus(e, status, userId);
    }
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        reason: `user-${status}`
      });
      setLoading(false);
      if (status === "open") {
        history.push(`/tickets/${ticket.uuid}`);
      } else {
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };
  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  }

  return (
    <>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {
          handleCloseOrOpenTicket(ticket);
        }}
        initialContact={ticket?.contact}
        initialQueue={ticket?.queue}
      />
      <ClassificationModal
        open={classificationModalOpen}
        onClose={handleCloseClassificationModal}
        aria-labelledby="form-dialog-title"
        classificationId={null}
        ticketId={ticket?.id}
        user={user}
        onSuccess={(e) => handleUpdateTicketStatus(e, "closed", user.id)}
      />
      <div className={classes.actionButtons}>
        {user.allowTicketExport ?
          <ExportMessages contact={ticket.contact} />
          : null}

        {ticket.status === "closed" && (
          <ButtonWithSpinner
            loading={loading}
            startIcon={<Add />}
            size="small"
            onClick={(e) => {
              setNewTicketModalOpen(true);
            }}
          >
            {i18n.t("messagesList.header.buttons.newTicket")}
          </ButtonWithSpinner>
        )}

        {ticket.status === "open" && (
          <>
            <ButtonWithSpinner
              loading={loading}
              startIcon={<Replay />}
              variant="contained"
              size="small"
              onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
            >
              {i18n.t("messagesList.header.buttons.return")}
            </ButtonWithSpinner>
            <ButtonWithSpinner
              id="messagesList_header_buttons_resolve"
              loading={loading}
              startIcon={<CheckBox />}
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => handleClassification(e, "closed", user?.id)}
            >
              {i18n.t("messagesList.header.buttons.resolve")}
            </ButtonWithSpinner>
            <IconButton onClick={handleOpenTicketOptionsMenu}>
              <MoreVert />
            </IconButton>
            <TicketOptionsMenu
              ticket={ticket}
              anchorEl={anchorEl}
              menuOpen={ticketOptionsMenuOpen}
              handleClose={handleCloseTicketOptionsMenu}
            />
          </>
        )}
{/*         {ticket.status === "pending" && (
          <ButtonWithSpinner
            loading={loading}
            size="small"
            variant="contained"
            color="primary"
            onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
          >
            {i18n.t("messagesList.header.buttons.accept")}
          </ButtonWithSpinner>
        )} */}
      </div>
    </>
  );
};

export default TicketActionButtons;
