import {
  Box,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Button,
} from "@material-ui/core";
import React, { useRef } from "react";
import { Chart } from "react-google-charts";
import _ from "lodash";
import ReactToExcel from "react-html-table-to-excel";
import { makeStyles } from "@material-ui/core/styles";
import { useReactToPrint } from "react-to-print";
import { PrintOutlined } from "@material-ui/icons";
import moment from "moment";
import { i18n } from "../../translate/i18n";


const useStyles = makeStyles((theme) => ({
  buttonExportToExcel: {
    boxShadow: "rgb(62, 115, 39) 0px 10px 14px -7px",
    background:
      "linear-gradient(rgb(119, 181, 90) 5%, rgb(114, 179, 82) 100%) rgb(119, 181, 90)",
    borderRadius: "4px",
    border: "1px solid rgb(75, 143, 41)",
    display: "inline-block",
    cursor: "pointer",
    color: "rgb(255, 255, 255)",
    fontFamily: "Arial",
    fontSize: "13px",
    fontWeight: "bold",
    padding: "14px 17px",
    textDecoration: "none",
    textShadow: "rgb(91, 138, 60) 0px 1px 0px",
    "&:hover": {
      background:
        "linear-gradient(rgb(18, 89, 37) 5%, rgb(92, 191, 42) 100%) rgb(18, 89, 37)",
    },
  },
  dataReport: {
    border: "solid 2px gray",
    borderRadius: 16,
    padding: 12,
    marginTop: 16,
  },
  tableStyle: {
    marginTop: '12px',
    maxWidth: "220mm",
    marginLeft: 18,
    marginRight: 60
  },
  buttonExportToPdf: {
    marginLeft: "12px",
    boxShadow: "rgb(207, 134, 108) 0px 1px 0px 0px inset",
    background:
      "linear-gradient(rgb(208, 69, 27) 5%, rgb(188, 51, 21) 100%) rgb(208, 69, 27)",
    borderRadius: "4px",
    border: "1px solid rgb(75, 143, 41)",
    display: "inline-block",
    cursor: "pointer",
    color: "rgb(255, 255, 255)",
    fontFamily: "Arial",
    fontSize: "13px",
    fontWeight: "bold",
    padding: "8px 17px",
    textDecoration: "none",
    textShadow: "rgb(133, 70, 41) 0px 1px 0px",
    "&:hover": {
      background:
        "linear-gradient(rgb(110, 84, 77) 5%, rgb(188, 51, 21) 100%) rgb(110, 84, 77)",
    },
  },
  row: {
    width: "220mm",
  },
  newPage: {
    pageBreakBefore: "always",
    marginTop: "20mm",
  },
  page: {
    margin: "18mm",
    border: "0",
  },
  reportData: {
    width: "210mm",
    margin: "auto",
    backgroundColor: "white",
  },
}));

export const optionsPie = {
  title: "Tickets por atendente",
  is3D: true,
  width: "100%",
  height: 260,
  backgroundColor: "transparent",
};

export const optionsPieQueue = {
  title: "Avaliações",
  is3D: true,
  width: "100%",
  height: 260,
  backgroundColor: "transparent",
};

export const optionsBar = {
  title: "Tickets por atendente",
  is3D: true,
  width: "90%",
  height: 180,
  chartArea: {
    backgroundColor: "#fcfcfc",
  },
};

export const optionsBarQueue = {
  title: "Tickets por Fila",
  is3D: true,
  width: "100%",
  height: 180,
  chartArea: {
    backgroundColor: "#fcfcfc",
  },
};

const loadChartByClassification = (data) => {
  const values = _.groupBy(
    data,
    (value) => value?.command + " - " + value?.description
  );
  const result = _.map(values, (value, key) => {
    return [key, parseFloat(value.length)];
  });
  return [["Classificação", ""], ...result];
};
const loadChartByUser = (data) => {
  const values = _.groupBy(data, (value) => value?.user?.name);
  const result = _.map(values, (value, key) => {
    return [key, parseFloat(value.length)];
  });
  return [["Usuário", "Usuário"], ...result];
};

const loadChartByUserPoints = (data) => {
  const values = _.groupBy(data, (value) => value?.user?.name);
  const result = _.map(values, (value, key) => {
    const arrayDeObjetos = value
    const teste = (value.reduce((acumulador, objeto) => acumulador + Number(objeto.command), 0) / arrayDeObjetos.length).toFixed(2)
    return [key, teste];
  });
  return [["Média por usuário", "Usuário"], ...result];
};

const loadGroupByUser = (data) => {
  const values = _.groupBy(data, (value) => value?.user?.name);
  return values;
};
const loadGroupByClassification = (data) => {
  const values = _.groupBy(
    data,
    (value) => value?.command
  );
  return values;
};

export const ReportNpsData = ({ data }) => {
  let numberTotalInquiry = data.report.npsResults.length
  let dataNotFiltered = data.report.npsResults
  let numberNotRespondedInquiry = data.report.npsResults.filter(e => e.command === null).length
  
  let dataFiltered = data.report.npsResults.filter(e => e.command !== null)
  let numberRespondedInquiry = dataFiltered.length
  const objetoClassifications = loadGroupByClassification(
    dataFiltered
  );
  const totalClassificationCount = dataFiltered.length;
  let reportByClassifications = [];
  for (const chave in objetoClassifications) {
    const arrayDeObjetos = objetoClassifications[chave];

    reportByClassifications.push({
      nota: `${chave} - ${arrayDeObjetos[0].description}`,
      command: chave,
      qtd: arrayDeObjetos.length,
      percentage: (arrayDeObjetos.length / totalClassificationCount) * 100,
    });
  }

  const objetoUsers = loadGroupByUser(dataFiltered);
  const totalUserCount = dataFiltered.length;
  let reportByUsers = [];
  for (const chave in objetoUsers) {
    const arrayDeObjetos = objetoUsers[chave];
    reportByUsers.push({
      user: chave,
      media: (arrayDeObjetos.reduce((acumulador, objeto) => acumulador + Number(objeto.command), 0) / arrayDeObjetos.length).toFixed(2),
      qtd: arrayDeObjetos.length,
      percentage: (arrayDeObjetos.length / totalUserCount) * 100,
    });
  }

  const componentRef = useRef();
  const classes = useStyles();
  let filter = { ...data.filter };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <Grid item xs={10}>
        <Box marginBottom={3}>
          <ReactToExcel
            className={classes.buttonExportToExcel}
            table="newReport"
            filename={
              "Relatório_período_" +
              filter.initialDate
                .toLocaleString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/") +
              "_a_" +
              filter.finalDate
                .toLocaleString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/")
            }
            sheet="relatório"
            buttonText="EXPORTAR PARA EXCEL"
          />
          <Button
            className={classes.buttonExportToPdf}
            onClick={handlePrint}
            endIcon={<PrintOutlined></PrintOutlined>}
          >
            IMPRIMIR
          </Button>
        </Box>
      </Grid>
      <div style={{ display: "none" }}>
        <Table
          border="1"
          id="newReport"
          cellPadding={"25"}
          cellSpacing={"8"}
          style={{ textAlign: "center" }} align="center"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={12} style={{ textAlign: "center" }} align="center" >
                <h2>Relatório </h2>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
                Data do relatório
              </TableCell>
              <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
                Data Inicial
              </TableCell>
              <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
                Data Final
              </TableCell>
              <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
                Pesquisa
              </TableCell>
              <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
                Atendente
              </TableCell>
              <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
                Fila
              </TableCell>
            </TableRow>
          </TableHead>
          <TableRow>
            <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
              {moment().format("DD/MM/YYYY")}
            </TableCell>
            <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
              {moment(filter.initialDate).format("DD/MM/YYYY")}
            </TableCell>
            <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
              {moment(filter.finalDate).format("DD/MM/YYYY")}
            </TableCell>
            <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
              {filter.currentNps.name}
            </TableCell>
            <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
              {filter.currentUser.name}
            </TableCell>

            <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >
              {filter.currentQueue.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: "center", backgroundColor: "black" }} align="center" >

            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: "center" }} align="center"  >
              <h3>Pesquisas</h3>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
              Solicitações
            </TableCell>
            <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
              Solicitações Respondidas
            </TableCell>
            <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
              Solicitações não Respondidas
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
              <b>{numberTotalInquiry}</b>
            </TableCell>
            <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
              <b>{numberRespondedInquiry}</b>
            </TableCell>
            <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
              <b>{numberNotRespondedInquiry}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: "center", backgroundColor:"black" }} align="center" >
              
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: "center" }} align="center"  >
              <h3>Relatório por avaliação  </h3>
            </TableCell>

          </TableRow>
          <TableRow>

            <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >Avaliação </TableCell>
            <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
              Qt.Avaliações
            </TableCell>
            <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
              {" "}
              % Avaliações
            </TableCell>
          </TableRow>
          {reportByClassifications.map((row) => (
            <TableRow
              key={row?.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
                {row?.nota}
              </TableCell>
              <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
                {row?.qtd}
              </TableCell>
              <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
                {row?.percentage.toFixed(2)} %
              </TableCell>
            </TableRow>



          ))}
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: "center", backgroundColor: "black" }} align="center" >

            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: "center" }} align="center" >
              <h3>Avaliações por usuário</h3>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >Usuário</TableCell>
            <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >
              Qt.classificações
            </TableCell>
            <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >
              {" "}
              % Classificações
            </TableCell>
            <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >
              Média
            </TableCell>
          </TableRow>
          <TableBody>
            {reportByUsers.map((row) => (
              <TableRow
                key={row?.user}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >{row?.user}</TableCell>
                <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >{row?.qtd}</TableCell>
                <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >
                  {row?.percentage.toFixed(2)} %
                </TableCell>
                <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >{row?.media}</TableCell>
              </TableRow>
            ))}

          </TableBody>
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: "center", backgroundColor: "black" }} align="center" >

            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: "center" }} align="center" >
              <h3>Avaliações detalhadas</h3>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >Usuário </TableCell>
            <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >Fila</TableCell>
            <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >Avaliação</TableCell>
            <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >Comentário</TableCell>
            <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >Contato</TableCell>
          </TableRow>
          {dataFiltered.map((row) => (
            <TableRow
              key={row?.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell colSpan={2} width={"33%"} style={{ textAlign: "center" }} align="center" >
                  <div>{row?.user?.name}</div>
              </TableCell>
              <TableCell colSpan={2} width={"33%"} style={{ textAlign: "center" }} align="center" >
                  <div>{row?.queue?.name}</div>
              </TableCell>
              <TableCell colSpan={2} width={"33%"} style={{ textAlign: "center" }} align="center" >
                <div>
                  {row?.command ?
                    <>
                      {row?.command} - {row?.description}
                    </> :
                    <>
                      {i18n.t(`nps.status.${row?.status}`)}
                    </>}
                </div>
              </TableCell>
              <TableCell colSpan={4} width={"33%"} style={{ textAlign: "center" }} align="center" >
                <div >
                  {row?.comment ? <>{row?.comment}</> : <></>}

                </div>
              </TableCell>
              <TableCell colSpan={2} style={{ textAlign: "center" }} align="center" >{row?.ticket?.uuid ?
                <>
                  <a
                    href={`${window.location}/../tickets/${row?.ticket?.uuid}`}
                    target="_blank" >{row?.contact?.name}
                  </a>
                </> : <></>}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </div>



      <div ref={componentRef} className={classes.reportData}>
        <div className={classes.newPage}>
          <Table
            className={classes.tableStyle}
            size="small"
          >
            <TableHead>
              <TableRow className={classes.row}>
                <TableCell colSpan={9} style={{ textAlign: "center" }} align="center" >
                  <h2>Relatório de pesquisa de satisfação NPS </h2>
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Data do relatório
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Data Inicial
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Data Final
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Pesquisa NPS
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Atendente
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Fila
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{moment().format("DD/MM/YYYY")}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{moment(filter.initialDate).format("DD/MM/YYYY")}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{moment(filter.finalDate).format("DD/MM/YYYY")}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{filter.currentNps.name}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{filter.currentUser.name}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{filter.currentQueue.name}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br />
          <Table
            className={classes.tableStyle}
            border="1"
            size="small"
            style={{ textAlign: "center" }} align="center"
          >

            <TableHead>
              <TableRow className={classes.row}>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Total de Solicitações
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Solicitações Respondidas
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Solicitações não Respondidas
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{numberTotalInquiry}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{numberRespondedInquiry}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{numberNotRespondedInquiry}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>





          <Table className={classes.tableStyle}
            border="1"
            size="small"
            style={{ textAlign: "center" }} align="center"

          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >
                  Relatório por avaliação
                </TableCell>
              </TableRow>
              <TableRow>

                <TableCell style={{ textAlign: "center" }} align="center" >Avaliação </TableCell>
                <TableCell style={{ textAlign: "center" }} align="center" >
                  Qt.Avaliações
                </TableCell>
                <TableCell style={{ textAlign: "center" }} align="center" >
                  {" "}
                  % Avaliações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportByClassifications.map((row) => (
                <TableRow
                  key={row?.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell style={{ textAlign: "center" }} align="center" >{row?.nota}</TableCell>
                  <TableCell style={{ textAlign: "center" }} align="center" >{row?.qtd}</TableCell>
                  <TableCell style={{ textAlign: "center" }} align="center" >
                    {row?.percentage.toFixed(2)} %
                  </TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >
                  <div>
                    <Chart
                      chartType="PieChart"
                      data={loadChartByClassification(
                        dataFiltered
                      )}
                      options={optionsPieQueue}
                    />
                  </div>
                </TableCell>

              </TableRow>
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }} align="center" >
                  <div style={{ margin: "40px", marginRight: "40px", }}>
                    <Chart
                      chartType="Bar"
                      data={loadChartByClassification(
                        dataFiltered
                      )}
                      options={optionsBarQueue}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className={classes.newPage}>
          <Table
            className={classes.tableStyle}
            size="small"
          >
            <TableHead>
              <TableRow className={classes.row}>
                <TableCell colSpan={9} style={{ textAlign: "center" }} align="center" >
                  <h2>Relatório de pesquisa de satisfação NPS </h2>
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Data do relatório
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Data Inicial
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Data Final
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Pesquisa NPS
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Atendente
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Fila
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{moment().format("DD/MM/YYYY")}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{moment(filter.initialDate).format("DD/MM/YYYY")}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{moment(filter.finalDate).format("DD/MM/YYYY")}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{filter.currentNps.name}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{filter.currentUser.name}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{filter.currentQueue.name}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.tableStyle}
            border="1"
            size="small"
            style={{ textAlign: "center" }} align="center" >
            <TableHead>
              <TableRow>
                <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >Avaliações por usuário </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textAlign: "center" }} align="center" >Usuário</TableCell>
                <TableCell style={{ textAlign: "center" }} align="center" >
                  Qt.classificações
                </TableCell>
                <TableCell style={{ textAlign: "center" }} align="center" >
                  {" "}
                  % Classificações
                </TableCell>
                <TableCell style={{ textAlign: "center" }} align="center" >
                  Média
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportByUsers.map((row) => (
                <TableRow
                  key={row?.user}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell style={{ textAlign: "center" }} align="center" >{row?.user}</TableCell>
                  <TableCell style={{ textAlign: "center" }} align="center" >{row?.qtd}</TableCell>
                  <TableCell style={{ textAlign: "center" }} align="center" >
                    {row?.percentage.toFixed(2)} %
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }} align="center" >{row?.media}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >
                  <div style={{ margin: "40px", marginRight: "40px", }}>
                    <Chart
                      chartType="Bar"
                      data={loadChartByUserPoints(dataFiltered)}
                      options={optionsPieQueue}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>


        </div>

        <div className={classes.newPage}>
          <Table
            className={classes.tableStyle}
            size="small"
          >
            <TableHead>
              <TableRow className={classes.row}>
                <TableCell colSpan={9} style={{ textAlign: "center" }} align="center" >
                  <h2>Relatório de pesquisa de satisfação NPS </h2>
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Data do relatório
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Data Inicial
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Data Final
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Pesquisa NPS
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Atendente
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  Fila
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{moment().format("DD/MM/YYYY")}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{moment(filter.initialDate).format("DD/MM/YYYY")}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{moment(filter.finalDate).format("DD/MM/YYYY")}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{filter.currentNps.name}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{filter.currentUser.name}</b>
                </TableCell>
                <TableCell colSpan={1} style={{ textAlign: "center" }} align="center" >
                  <b>{filter.currentQueue.name}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.tableStyle}
            border="1"
            size="small"
            style={{ textAlign: "center" }} align="center" >
            <TableHead>
              <TableRow>
                <TableCell colSpan={4} style={{ textAlign: "center" }} align="center" >Avaliações detalhadas</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textAlign: "center" }} align="center" >Usuário / Fila</TableCell>
                <TableCell style={{ textAlign: "center" }} align="center" >Avaliação / Comentário</TableCell>
                <TableCell style={{ textAlign: "center" }} align="center" >Contato</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataFiltered.map((row) => (
                <TableRow
                  key={row?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell width={"33%"} style={{ textAlign: "center" }} align="center" >
                    <div>
                      <div>{row?.user?.name}</div>
                      <hr />
                      <div>{row?.queue?.name}</div>
                    </div>
                  </TableCell>
                  <TableCell width={"33%"} style={{ textAlign: "center" }} align="center" >
                    <div>
                      {row?.command ?
                        <>
                          {row?.command} - {row?.description}
                        </> :
                        <>
                          {i18n.t(`nps.status.${row?.status}`)}
                        </>}
                    </div>
                    <div >
                      {row?.comment ? <><hr />{row?.comment}</> : <></>}

                    </div>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }} align="center" >{row?.ticket?.uuid ?
                    <>
                      <a
                        href={`tickets/${row?.ticket?.uuid}`}
                        target="_blank" >{row?.contact?.name}
                      </a>
                    </> : <></>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>


        </div>
      </div >

    </>
  );
};
