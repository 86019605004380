import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },
  multField: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    marginRight: "10px",
    marginTop: "10px",
    minWidth: 120,
  },
  formControlEnd: {
    marginRight: "10px",
    marginTop: "10px",
    minWidth: 140,
  },
}));

const UserSchema = Yup.object().shape({

});

const NpsOptionModal = ({
  open,
  onClose,
  npsSurveyId,
  npsOptionId,

}) => {
  const classes = useStyles();
  const initialState = {
    order: 0,
    command: "",
    description: "",
  };

  const [npsOption, setClassification] = useState(initialState);
  useEffect(() => {
    const fetchUser = async () => {
      if (!npsOptionId) return;
      try {
        const { data } = await api.get(`/npsOption/${npsOptionId}`);
        setClassification((prevState) => {
          return { ...prevState, ...data };
        });
      } catch (err) {
        toastError(err);
      }
    };

    fetchUser();
  }, [npsOptionId, open]);

  const handleClose = () => {
    onClose();
    setClassification(initialState);
  };



  const handleSave = async (values) => {


    const npsOptionData = { ...values, npsSurveyId};
    try {
      if (npsOptionId) {
        await api.put(`/npsOption/${npsOptionId}`, npsOptionData);
        toast.success(i18n.t("npsOptions.toasts.edited"));
      } else {
        await api.post("/npsOption", npsOptionData);
        toast.success(i18n.t("npsOptions.toasts.created"));
      }
      
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"        
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {npsOptionId
            ? `${i18n.t("npsOptionModal.title.edit")}`
            : `${i18n.t("npsOptionModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={npsOption}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSave(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form style={{ height: "100%", marginBottom: "40px" }}>
              <DialogContent dividers>
                <div className={classes.multField}>
                  <div style={{ width: "430px" }}>
                    <Field
                      as={TextField}
                      className={classes.selectColor}
                      name="order"
                      label={i18n.t("npsOptionModal.form.order")}
                      type="number"
                      InputProps={{ inputProps: { min: 0} }}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                    //onChange={e => setTimeOpeningYellow(e.target.value)}
                    //value={timeOpeningYellow}
                    />

                  </div>
                </div>
                <div className={classes.multField}>
                  <div style={{ width: "430px" }}>
                    <Field
                      as={TextField}
                      label={i18n.t("npsOptionModal.form.command")}
                      name="command"
                      error={
                        touched.command && Boolean(errors.command)
                      }
                      helperText={touched.command && errors.command}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                </div>
                <div className={classes.multField}>
                  <div style={{ width: "430px" }}>
                    <Field
                      as={TextField}
                      label={i18n.t("npsOptionModal.form.description")}
                      name="description"
                      error={
                        touched.description && Boolean(errors.description)
                      }
                      helperText={touched.description && errors.description}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                </div>


                <div className={classes.multField}>

                  <div style={{ width: "430px" }}>

                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={isSubmitting}
                        variant="outlined"
                      >
                        {i18n.t("common.cancel")}
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        variant="contained"
                        className={classes.btnWrapper}
                      >
                        {npsOptionId
                          ? `${i18n.t("common.save")}`
                          : `${i18n.t("npsOptionModal.buttons.add")}`}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </Button>
                    </DialogActions>

                  </div>


                </div>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default NpsOptionModal;
