import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import {  FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import moment from "moment"
import { AuthContext } from "../../context/Auth/AuthContext";
import { capitalize } from "lodash";
import useQueues from "../../hooks/useQueues";
import AutoCompleteGeneric from "../AutoCompleteGeneric";
import Autocomplete  from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
		marginBottom: 12
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));




const ScheduleModal = ({ open, onClose, scheduleId, contactId, cleanContact, reload }) => {

	const initialState = {
		body: "",
		contactId: "",
		sendAt: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm'),
		sentAt: ""
	};

	const initialContact = {
		id: "",
		name: ""
	}
	const classes = useStyles();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const [queues, setQueues] = useState(user.queues);
	const [loading, setLoading] = useState(false);
	const [allQueues, setAllQueues] = useState([]);
	const [selectedUser, setSelectedUser] = useState(user);
	const [selectedQueue, setSelectedQueue] = useState('');
	const [sendAt, setSelectedSendAt] = useState(initialState.sendAt);
	const [body, setBodyMessage] = useState("");
	const [schedule, setSchedule] = useState(initialState);
	const [currentContact, setCurrentContact] = useState(initialContact);
	const [ticketStatus, setTicketStatus] = useState("pending");
	const { findAll: findAllQueues } = useQueues();
	const isMounted = useRef(true);
	const [searchParam, setSearchParam] = useState("");
	const [options, setOptions] = useState([]);
	

	useEffect(() => {
		return () => {
			isMounted.current = false;
		}
	}, []);

	useEffect(() => {
		if (open) {
			setLoading(true)
			const loadQueues = async () => {
				const list = await findAllQueues();
				setAllQueues(list);

				if (user.id != selectedUser?.id) {
					setQueues(list);
				}

			}

			const loadContacts = async () => {
			if (contactId) {
					const { data } = await api.get(`/contacts/${contactId}`)
					setCurrentContact(data);
					
				}

			}
			const loadSchedule = async () => {
				if (scheduleId) {
					const { data } = await api.get(`/schedules/${scheduleId}`)
					setCurrentContact(data?.contact);
					setSelectedUser(data?.user)
					setSelectedQueue(data?.queueId)
					setSelectedSendAt(moment(data?.sendAt).format('YYYY-MM-DDTHH:mm'),)
					setTicketStatus(data?.ticketStatus)
					setBodyMessage(data?.body)
				}

			}
			loadContacts();
			loadSchedule();
			loadQueues();

			setLoading(false)


		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);


	useEffect(() => {
		if (!open || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		//setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam },
					});
					console.log(data)
					setOptions(data.contacts);
					//setLoading(false);
				} catch (err) {
					//setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam]);

	const renderOptionLabel = (option) => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};
	const renderOption = (option) => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${i18n.t("newTicketModal.add")} ${option.name}`;
		}
	};


	const handleClose = () => {
		onClose();
		setSchedule(initialState);
		setSelectedQueue("")
		setSelectedUser(null)
		setCurrentContact(initialContact)
		setTicketStatus("pending")
	};

	const handleSaveTicket = async (contactId) => {
		if (!contactId) return;
		if (selectedQueue === "") {
			toast.error("Selecione uma fila");
			return;
		}
		setLoading(true);
		try {
			const queueId = selectedQueue !== "" ? selectedQueue : null;
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				queueId,
				userId: user.id,
				status: "open",
			});
			onClose(ticket);
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
	};

	const handleSelectUser = (newValue) => {
		if (newValue) {
			setSelectedUser(newValue)
			if (newValue != null && Array.isArray(newValue.queues)) {
				setQueues(newValue.queues);
			} else {
				setQueues(allQueues);
				setSelectedQueue('');
			}
		} else {
			setQueues(allQueues);
			setSelectedQueue('');
			setSelectedUser(null)
		}
	}
	const handleSaveSchedule = async values => {


		const userId = selectedUser?.id || null

		const scheduleData = { contactId: currentContact?.id, userId, ticketStatus, queueId: selectedQueue, sendAt, body, ticketStatus, };
		try {
			if (scheduleId) {
				await api.put(`/schedules/${scheduleId}`, scheduleData);
			} else {
				await api.post("/schedules", scheduleData);
			}
			toast.success(i18n.t("scheduleModal.success"));
			if (typeof reload == 'function') {
				reload();
			}
			if (contactId) {
				if (typeof cleanContact === 'function') {
					cleanContact();
					history.push('/schedules');
				}
			}
		} catch (err) {
			toast.error("Erro no componente de agendamento de mensagens");
		}
		setCurrentContact(initialContact);
		setSchedule(initialState);
		handleClose();
	};

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setCurrentContact(newValue);
		}
	};

	const renderContactAutocomplete = () => {
		return (
			<Autocomplete
				id="newTicketModalFormContact"
				fullWidth
				options={options}
				loading={loading}
				value={currentContact}
				clearOnBlur
				autoHighlight
				freeSolo
				clearOnEscape
				getOptionLabel={renderOptionLabel}
				renderOption={renderOption}
				//filterOptions={createAddContactOption}
				onChange={(e, newValue) => handleSelectOption(e, newValue)}
				renderInput={(params) => (
					<TextField
						{...params}
						label={i18n.t("newTicketModal.fieldLabel")}
						variant="outlined"
						autoFocus
						id="newTicketModalFormContactItem"
						onChange={(e) => setSearchParam(e.target.value)}
						onKeyPress={(e) => {
							if (loading || !currentContact) return;
							else if (e.key === "Enter") {
								handleSaveTicket(currentContact.id);
							}
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>

		);
	};

	return (
		<>

			{loading ? <></> :

				<div className={classes.root}>
					<Dialog
						open={open}
						onClose={handleClose}
						maxWidth="xs"
						fullWidth
						scroll="paper"
					>
						<DialogTitle id="form-dialog-title">
							{schedule.status === 'ERRO' ? 'Erro de Envio' : `Mensagem ${capitalize(schedule.status)}`}
						</DialogTitle>
						<Formik
							initialValues={schedule}
							enableReinitialize={true}
							onSubmit={(values, actions) => {
								setTimeout(() => {
									handleSaveSchedule(values);
									actions.setSubmitting(false);
								}, 400);
							}}
						>
							{({ touched, errors, isSubmitting, values }) => (
								<Form>
									<DialogContent dividers>
										<div className={classes.multFieldLine}>
											<FormControl
												variant="outlined"
												fullWidth
												size="small"
											>
												{renderContactAutocomplete()}
												{/* 												<Autocomplete
													size="small"
													fullWidth
													value={currentContact}
													options={contacts}
													onChange={(e, contact) => {
														const contactId = contact ? contact.id : '';
														setSchedule({ ...schedule, contactId });
														setCurrentContact(contact ? contact : initialContact);
													}}
													getOptionLabel={(option) => option?.number ? option.name + " " + option.number : ""}
													getOptionSelected={(option, value) => {
														return value.id === option.id
													}}
													renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Contato" />}
												/>
 */}
												{/* 												<AutoCompleteGeneric
													name="contacts"
													label={i18n.t(
														"Contato"
													)}
													route={"contacts"}
													value={currentContact}
													onChange={(values) => setCurrentContact(values)}
												/> */}
											</FormControl>
										</div>
										<div className={classes.multFieldLine}>

											<FormControl
												variant="outlined"
												fullWidth
											>
												<AutoCompleteGeneric
													name="users"
													label={i18n.t(
														"Usuário"
													)}
													route={"users"}

													value={selectedUser}
													//required={user?.classificationPermission === "optional_S" || user?.classificationPermission === "required_S"}
													onChange={(values) => handleSelectUser(values)}
												/>
											</FormControl>
										</div>
										<div className={classes.multFieldLine}>
											<FormControl size="small" variant="outlined" fullWidth className={classes.maxWidth}>
												<InputLabel>{i18n.t("Fila")}</InputLabel>
												<Select
													size="small"
													value={selectedQueue}
													onChange={(e) => setSelectedQueue(e.target.value)}
													label={i18n.t("Fila")}
												>
													<MenuItem value={""}>&nbsp;</MenuItem>
													{queues.map((queue) => (
														<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
													))}
												</Select>
											</FormControl>
										</div>

										<div className={classes.multFieldLine}>
											<Field
												as={TextField}
												label={i18n.t("scheduleModal.form.sendAt")}
												type="datetime-local"
												name="sendAt"
												InputLabelProps={{
													shrink: true,
												}}
												error={touched.sendAt && Boolean(errors.sendAt)}
												helperText={touched.sendAt && errors.sendAt}
												variant="outlined"
												fullWidth
												value={sendAt}
												onChange={(e) => setSelectedSendAt(e.target.value)}
											/>
										</div>
										<div className={classes.multFieldLine}>
											<FormControl
												variant="outlined"
												//className={classes.formControl}
												margin="dense"
												fullWidth
											>
												<InputLabel id="ticketSetStatus-selection-input-label">
													{i18n.t("Status do ticket após envio")}
												</InputLabel>

												<Field
													as={Select}
													label={i18n.t("Status do ticket após envio")}
													title={i18n.t("Status do ticket após envio")}
													name="ticketSetStatus"
													labelId="ticketSetStatus-selection-label"
													id="ticketSetStatus-selection"
													required
													value={ticketStatus}
													onChange={(e) => setTicketStatus(e.target.value)}
												>
													<MenuItem key={"open"} value={"open"}>
														Aberto
													</MenuItem>
													<MenuItem key={"pending"} value={"pending"}>
														Aguardando
													</MenuItem>
													<MenuItem key={"closed"} value={"closed"}>
														Fechado
													</MenuItem>
												</Field>
											</FormControl>
										</div>
										<div className={classes.multFieldLine}>
											<Field
												as={TextField}
												//rows={5}
												minRows={5}
												multiline={true}
												label={i18n.t("scheduleModal.form.body")}
												name="body"
												error={touched.body && Boolean(errors.body)}
												helperText={touched.body && errors.body}
												variant="outlined"
												margin="dense"
												fullWidth
												value={body}
												onChange={(e) => setBodyMessage(e.target.value)}
											/>
										</div>


									</DialogContent>
									<DialogActions>
										<Button
											onClick={handleClose}
											color="secondary"
											disabled={isSubmitting}
											variant="outlined"
										>
											{i18n.t("scheduleModal.buttons.cancel")}
										</Button>
										{(schedule.sentAt === null || schedule.sentAt === "") && (
											<Button
												type="submit"
												color="primary"
												disabled={isSubmitting}
												variant="contained"
												className={classes.btnWrapper}
											>
												{scheduleId
													? `${i18n.t("scheduleModal.buttons.okEdit")}`
													: `${i18n.t("scheduleModal.buttons.okAdd")}`}
												{isSubmitting && (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												)}
											</Button>
										)}
									</DialogActions>
								</Form>
							)}
						</Formik>
					</Dialog>
				</div>
			}
		</>

	);
};

export default ScheduleModal;
