
import { styled, keyframes } from '@stitches/react';
import { violet, mauve} from '@radix-ui/colors';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
export const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  });
  
  export const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
  });
  
  export const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  });
  
  export const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
  });
  
  export const contentStyles = {
    minWidth: 220,
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 5,
    marginLeft:50,
    boxShadow:
      '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
    '@media (prefers-reduced-motion: no-preference)': {
      animationDuration: '400ms',
      animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
      willChange: 'transform, opacity',
      '&[data-state="open"]': {
        '&[data-side="top"]': { animationName: slideDownAndFade },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade },
      },
    },
  };

  export const itemStyles = {
    all: 'unset',
    fontSize: 16,
    lineHeight: 1,
    color: '#337ab7',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    height: 25,
    padding: '0 5px',
    position: 'relative',
    paddingLeft: 25,
    userSelect: 'none',
  
    '&[data-disabled]': {
      color: mauve.mauve8,
      pointerEvents: 'none',
    },
  
    '&[data-highlighted]': {
      backgroundColor: '#337ab7',
      color: violet.violet1,
    },
  };


  export const StyledItem = styled(DropdownMenuPrimitive.Item, { ...itemStyles });

export const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: violet.violet6,
  margin: 5,
});
export function Content({ children, ...props }) {
    return (
      <DropdownMenuPrimitive.Portal>
        <StyledContent {...props}>
          {children}
          <StyledArrow />
        </StyledContent>
      </DropdownMenuPrimitive.Portal>
    );
  }

 export const DropdownMenu = DropdownMenuPrimitive.Root;
 export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
 export const DropdownMenuContent = Content;
 export const DropdownMenuItem = StyledItem;
 export const DropdownMenuSeparator = StyledSeparator;
 export const StyledContent = styled(DropdownMenuPrimitive.Content, { ...contentStyles });

export const StyledArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: 'white',
});


// Your app...
export const Box = styled('div', {});

export const RightSlot = styled('div', {
  marginLeft: 'auto',
  paddingLeft: 20,
  color: '#337ab7',
  '[data-highlighted] > &': { color: 'white' },
  '[data-disabled] &': { color: '#337ab7' },
});
