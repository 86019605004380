import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import {
        Dialog,
        DialogContent,
        Button,
        DialogActions,
        CircularProgress,
        TextField,
        Switch,
        FormControlLabel,
        FormControl,
        InputLabel,
        Select,
        MenuItem,
        Grid,
} from "@material-ui/core";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import MainHeader from "../MainHeader";
import Title from "../Title";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";
import { hours, minutes } from "../../helpers/commonConsts";


const useStyles = makeStyles(theme => ({
        root: {
                display: "flex",
                flexWrap: "wrap",
        },

        multFieldLine: {
                display: "flex",
                "& > *:not(:last-child)": {
                        marginRight: theme.spacing(1),
                },
        },

        btnWrapper: {
                position: "relative",
        },

        buttonProgress: {
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: -12,
                marginLeft: -12,
        },
}));

const SessionSchema = Yup.object().shape({
        name: Yup.string()
                .min(2, "Too Short!")
                .max(50, "Too Long!")
                .required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
        const classes = useStyles();
        const initialState = {
                name: "",
                greetingMessage: "",
                isDefault: false,
                token: "",
                farewellMessage: "",
                webhookUrl: "",
                messageOnErrorsBot: "*Atendente virtual* desabilitado devido a tentativas incorretas digite # para ver as opções novamente.",
                countErrorsBot: 3,
                actionNoQueue: "none",
                callEnabled: false,
                callReturnMessage: `*Mensagem Automática:* As chamadas de voz e vídeo estão desabilitas para esse WhatsApp, favor enviar uma mensagem de texto. Obrigado`
        };
        const [countErrors, setCountErrors] = useState(initialState.countErrorsBot);
        const [whatsApp, setWhatsApp] = useState(initialState);
        const [selectedQueueIds, setSelectedQueueIds] = useState([]);
        const [selectedQueue, setSelectedQueue] = useState(-1);
        const [page, setPage] = useState("general")
        const [webhookLimitMinutes, setwebhookLimitMinutes] = useState(25)
        const [webhookLimitHours, setwebhookLimitHours] = useState(0)
        const [countMaxMsgReturnWebhook, setCountMaxMsgReturnWebhook] = useState(3)
        const [botSetup, setBotSetup] = useState(initialState);
        const [webhookUrl, setWebhookUrl] = useState("")
        const [queues, setQueues] = useState([]);
        useEffect(() => {
                if(open){
                        const fetchSession = async () => {

                                try {
                                        const { data } = await api.get("/queue");
                                        setQueues([{ id: -1, name: "Desabilitado" }, ...data]);
                                } catch (err) {
                                        toastError(err);
                                }
        
                                if (!whatsAppId) return;
        
                                try {
                                        const { data } = await api.get(`whatsapp/${whatsAppId}`);
                                        setWhatsApp(data);
                                        setCountErrors(data?.countErrorsBot)
                                        setWebhookUrl(data?.webhookUrl)
                                        setwebhookLimitMinutes(data?.webhookLimitMinutes)
                                        setwebhookLimitHours(data?.webhookLimitHours)
                                        setCountMaxMsgReturnWebhook(data?.countMaxMsgReturnWebhook)
                                        const whatsQueueIds = data.queues?.map(queue => queue.id);
                                        setSelectedQueueIds(whatsQueueIds);
                                        setBotSetup({
                                                ...botSetup, actionMessageNoQueue: data?.actionMessageNoQueue,
                                                actionNoQueue: data?.actionNoQueue,
                                                timeNoQueue: data?.timeNoQueue
                                        })
                                        setSelectedQueue(data?.timeNoQueueQueueId)
        
                                } catch (err) {
                                        toastError(err);
                                }
        
        
                        };
                        fetchSession();

                }

        }, [whatsAppId, open]);

        const handleSaveWhatsApp = async values => {

                const whatsappData = {
                        ...values,
                        queueIds: selectedQueueIds,
                        lib: "baileys",
                        countErrorsBot: countErrors,
                        webhookLimitMinutes,
                        webhookLimitHours,
                        webhookUrl,
                        countMaxMsgReturnWebhook,
                        actionMessageNoQueue: botSetup.actionMessageNoQueue,
                        actionNoQueue: botSetup.actionNoQueue,
                        timeNoQueueQueueId: selectedQueue,
                        timeNoQueue: botSetup.timeNoQueue
                };
                try {
                        if (whatsAppId) {
                                await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
                        } else {
                                await api.post("/whatsapp", whatsappData);
                        }
                        toast.success(i18n.t("whatsappModal.success"));
                        handleClose();
                } catch (err) {
                        toastError(err);
                }
        };

        const handleClose = () => {
                onClose();
                setWhatsApp(initialState);
        };

        return (
                <div className={classes.root}>
                        <Dialog
                                open={open}
                                onClose={handleClose}
                                maxWidth="lg"
                                fullWidth
                                scroll="paper"
                        >
                                <MainHeader>
                                        <Title>
                                                {whatsAppId
                                                        ? i18n.t("whatsappModal.title.edit")
                                                        : i18n.t("whatsappModal.title.add")}
                                        </Title>
                                        <MainHeaderButtonsWrapper>
                                                <Button
                                                        variant="contained"
                                                        color={page === "general" ? "primary" : "inherit"}
                                                        onClick={() => setPage("general")}
                                                >
                                                        Configurações de Gerais
                                                </Button>
                                                <Button
                                                        variant="contained"
                                                        color={page === "bot" ? "primary" : "inherit"}
                                                        onClick={() => setPage("bot")}
                                                >
                                                        Configurações de Bot
                                                </Button>
                                                <Button
                                                        startIcon={<CodeRoundedIcon />}
                                                        variant="contained"
                                                        color={page === "api" ? "primary" : "inherit"}
                                                        onClick={() => setPage("api")}
                                                >
                                                        Configurações de api
                                                </Button>
                                        </MainHeaderButtonsWrapper>
                                </MainHeader>
                                <Formik
                                        initialValues={whatsApp}
                                        enableReinitialize={true}
                                        validationSchema={SessionSchema}
                                        onSubmit={(values, actions) => {
                                                setTimeout(() => {
                                                        handleSaveWhatsApp(values);
                                                        actions.setSubmitting(false);
                                                }, 400);
                                        }}
                                >
                                        {({ values, touched, errors, isSubmitting }) => (


                                                <Form> {page === "general" ?
                                                        <DialogContent dividers>
                                                                <div className={classes.multFieldLine}>
                                                                        <Field
                                                                                as={TextField}
                                                                                label={i18n.t("whatsappModal.form.name")}
                                                                                autoFocus
                                                                                name="name"
                                                                                error={touched.name && Boolean(errors.name)}
                                                                                helperText={touched.name && errors.name}
                                                                                variant="outlined"
                                                                                margin="dense"
                                                                                className={classes.textField}
                                                                        />
                                                                        <FormControlLabel
                                                                                control={
                                                                                        <Field
                                                                                                as={Switch}
                                                                                                color="primary"
                                                                                                name="isDefault"
                                                                                                checked={values.isDefault}
                                                                                        />
                                                                                }
                                                                                label={i18n.t("whatsappModal.form.default")}
                                                                        />

                                                                </div>
                                                                <div className={classes.multFieldLine}>
                                                                        <Field
                                                                                as={TextField}
                                                                                label={i18n.t("queueModal.form.greetingMessage")}
                                                                                type="greetingMessage"
                                                                                multiline
                                                                                minRows={5}
                                                                                fullWidth
                                                                                name="greetingMessage"
                                                                                error={
                                                                                        touched.greetingMessage && Boolean(errors.greetingMessage)
                                                                                }
                                                                                helperText={
                                                                                        touched.greetingMessage && errors.greetingMessage
                                                                                }
                                                                                variant="outlined"
                                                                                margin="dense"
                                                                        />

                                                                        <Field
                                                                                as={TextField}
                                                                                label={i18n.t("whatsappModal.form.farewellMessage")}
                                                                                type="farewellMessage"
                                                                                multiline
                                                                                minRows={5}
                                                                                fullWidth
                                                                                name="farewellMessage"
                                                                                error={
                                                                                        touched.farewellMessage && Boolean(errors.farewellMessage)
                                                                                }
                                                                                helperText={
                                                                                        touched.farewellMessage && errors.farewellMessage
                                                                                }
                                                                                variant="outlined"
                                                                                margin="dense"
                                                                        />
                                                                </div>


                                                                <QueueSelect
                                                                        selectedQueueIds={selectedQueueIds}
                                                                        onChange={selectedIds => setSelectedQueueIds(selectedIds)}
                                                                />

                                                                {selectedQueueIds.length <= 1 ? <p style={{color:"orange"}}>Atenção para que o chatbot funcione você precisa adicionar 2 filas ou mais </p> :null}
                                                                
                                                        </DialogContent> : null}

                                                        {page === "api" ?
                                                                <DialogContent dividers>
                                                                        <div className={classes.multFieldLine}>


                                                                                <div style={{ width: "100%" }}>
                                                                                        <div>
                                                                                                <Field
                                                                                                        as={TextField}
                                                                                                        label={i18n.t("queueModal.form.token")}
                                                                                                        type="text"
                                                                                                        focused
                                                                                                        fullWidth
                                                                                                        name="token"
                                                                                                        variant="outlined"
                                                                                                        margin="dense"
                                                                                                />

                                                                                        </div>
                                                                                        <div>
                                                                                                <Field
                                                                                                        as={TextField}
                                                                                                        label={i18n.t("whatsappModal.form.webhookUrl")}
                                                                                                        type="text"
                                                                                                        focused
                                                                                                        fullWidth
                                                                                                        name="webhookUrl"
                                                                                                        error={
                                                                                                                touched.webhookUrl && Boolean(errors.webhookUrl)
                                                                                                        }
                                                                                                        helperText={
                                                                                                                touched.webhookUrl && errors.webhookUrl
                                                                                                        }
                                                                                                        variant="outlined"
                                                                                                        margin="dense"

                                                                                                        value={webhookUrl}
                                                                                                        onChange={(e) => setWebhookUrl(e.target.value)}

                                                                                                />

                                                                                        </div>
                                                                                        {webhookUrl ? <>

                                                                                                <div style={{ width: 350 }}>
                                                                                                        <Field
                                                                                                                name="countMaxMsgReturnWebhook"
                                                                                                                variant="outlined"
                                                                                                                margin="dense"
                                                                                                                component={TextField}
                                                                                                                label={i18n.t("whatsappModal.form.countMaxMsgReturnWebhook")}
                                                                                                                type="number"
                                                                                                                InputProps={{ inputProps: { min: 1, max: 20 } }}
                                                                                                                value={countMaxMsgReturnWebhook}
                                                                                                                onChange={(e) => setCountMaxMsgReturnWebhook(e.target.value)}
                                                                                                                focused
                                                                                                                fullWidth
                                                                                                        />
                                                                                                </div>


                                                                                        </> : null}


                                                                                </div>

                                                                                {webhookUrl ? <>
                                                                                        <div style={{ width: 350, border: "solid 2px #e0e0e0", padding: 15, borderRadius: 6 }}>
                                                                                                <div style={{ textAlign: "center" }} >
                                                                                                        Tempo ouvindo webhook após criação de ticket via Api
                                                                                                </div>
                                                                                                <div>
                                                                                                        <FormControl
                                                                                                                style={{ width: 95 }}>
                                                                                                                <InputLabel htmlFor="hours">Horas</InputLabel>
                                                                                                                <Select
                                                                                                                        value={webhookLimitHours}
                                                                                                                        onChange={(e) => { setwebhookLimitHours(e.target.value) }}
                                                                                                                        inputProps={{
                                                                                                                                name: 'hour',
                                                                                                                                id: 'hour',
                                                                                                                        }}
                                                                                                                >
                                                                                                                        {hours.map(hour => (
                                                                                                                                <MenuItem key={`m-${hour}`} value={parseInt(hour)}>
                                                                                                                                        {hour}
                                                                                                                                </MenuItem>
                                                                                                                        ))}
                                                                                                                </Select>
                                                                                                        </FormControl>

                                                                                                        <FormControl style={{ width: 95, marginLeft: 25 }}>
                                                                                                                <InputLabel htmlFor="minute">Minutos</InputLabel>
                                                                                                                <Select
                                                                                                                        value={webhookLimitMinutes}
                                                                                                                        onChange={(e) => { setwebhookLimitMinutes(e.target.value) }}
                                                                                                                        inputProps={{
                                                                                                                                name: 'minute',
                                                                                                                                id: 'minute',
                                                                                                                        }}
                                                                                                                >
                                                                                                                        {minutes.map(minute => (
                                                                                                                                <MenuItem key={`m-${minute}`} value={parseInt(minute)}>
                                                                                                                                        {minute}
                                                                                                                                </MenuItem>
                                                                                                                        ))}
                                                                                                                </Select>
                                                                                                        </FormControl>

                                                                                                </div>
                                                                                        </div>


                                                                                </> : null}






                                                                        </div>

                                                                        <div className={classes.multFieldLine}>


                                                                        </div>
                                                                </DialogContent> : null}

                                                        {page === "bot" ?
                                                                <DialogContent dividers>
                                                                        <div className={classes.multFieldLine}>
                                                                                <FormControlLabel
                                                                                        control={
                                                                                                <Field
                                                                                                        as={Switch}
                                                                                                        color="primary"
                                                                                                        name="callEnabled"
                                                                                                        checked={values.callEnabled}

                                                                                                />
                                                                                        }
                                                                                        label={i18n.t("Aceitar chamadas de audio e video")}
                                                                                />
                                                                                {!values.callEnabled ?
                                                                                        <Field
                                                                                                as={TextField}
                                                                                                label={i18n.t("Mensagem de recusa de chamada")}
                                                                                                type="callReturnMessage"
                                                                                                multiline
                                                                                                minRows={3}
                                                                                                fullWidth
                                                                                                name="callReturnMessage"
                                                                                                error={
                                                                                                        touched.messageOnErrorsBot && Boolean(errors.messageOnErrorsBot)
                                                                                                }
                                                                                                helperText={
                                                                                                        touched.messageOnErrorsBot && errors.messageOnErrorsBot
                                                                                                }
                                                                                                variant="outlined"
                                                                                                margin="dense"
                                                                                        />

                                                                                        : null}
                                                                        </div>

                                                                        <div className={classes.multFieldLine}>
                                                                                <div style={{ width: "70%" }}>


                                                                                        <Field
                                                                                                as={TextField}
                                                                                                label={i18n.t("whatsappModal.form.messageOnErrorsBot")}
                                                                                                type="messageOnErrorsBot"
                                                                                                multiline
                                                                                                minRows={3}
                                                                                                fullWidth
                                                                                                name="messageOnErrorsBot"
                                                                                                error={
                                                                                                        touched.messageOnErrorsBot && Boolean(errors.messageOnErrorsBot)
                                                                                                }
                                                                                                helperText={
                                                                                                        touched.messageOnErrorsBot && errors.messageOnErrorsBot
                                                                                                }
                                                                                                variant="outlined"
                                                                                                margin="dense"
                                                                                        />
                                                                                </div>
                                                                                <div style={{ width: "30%" }}>

                                                                                        <Field
                                                                                                name="countErrorsBot"
                                                                                                component={TextField}
                                                                                                label={i18n.t("whatsappModal.form.countErrorsBot")}
                                                                                                type="number"
                                                                                                InputProps={{ inputProps: { min: 1, max: 10 } }}
                                                                                                value={countErrors}
                                                                                                onChange={(e) => setCountErrors(e.target.value)}
                                                                                                focused
                                                                                                fullWidth
                                                                                        />

                                                                                </div>

                                                                        </div>
                                                                        <div>
                                                                                <Grid container spacing={1} >
                                                                                        <Grid item xs={4} >
                                                                                                <FormControl
                                                                                                        variant="outlined"
                                                                                                        //className={classes.formControl}
                                                                                                        margin="dense"
                                                                                                        fullWidth
                                                                                                >
                                                                                                        <InputLabel id="typeMenu-selection-input-label">
                                                                                                                {i18n.t("Ação se contato não selecionar fila")}
                                                                                                        </InputLabel>

                                                                                                        <Field
                                                                                                                as={Select}
                                                                                                                label={i18n.t("Ação se contato não selecionar fila")}
                                                                                                                title={i18n.t("Ação se contato não selecionar fila")}
                                                                                                                name="actionNoQueue"
                                                                                                                labelId="typeMenu-selection-label"
                                                                                                                id="actionNoQueue-selection"
                                                                                                                required
                                                                                                                value={botSetup.actionNoQueue}
                                                                                                                onChange={(e) => {
                                                                                                                        if (e.target.value === "sendMessageAndClosed") {
                                                                                                                                setBotSetup({
                                                                                                                                        ...botSetup, actionNoQueue: e.target.value,
                                                                                                                                        actionMessageNoQueue: "Estamos finalizando o seu atendimento por falta de interação 😞.\nCaso necessite de um novo atendimento é só mandar uma nova mensagem.\nEstamos à disposição para vos atender."
                                                                                                                                })
                                                                                                                        } else
                                                                                                                                if (e.target.value === "transferOnQueue") {
                                                                                                                                        setBotSetup({
                                                                                                                                                ...botSetup, actionNoQueue: e.target.value,
                                                                                                                                                actionMessageNoQueue: "Estamos transferindo seu atendimento para um atendente por favor envie sua duvida, que em breve lhe atenderemos."
                                                                                                                                        })
                                                                                                                                } else {
                                                                                                                                        setBotSetup({
                                                                                                                                                ...botSetup, actionNoQueue: e.target.value,
                                                                                                                                                actionMessageNoQueue: ""
                                                                                                                                        })

                                                                                                                                }

                                                                                                                }}
                                                                                                        >
                                                                                                                <MenuItem key={"null"} default value={"none"}>Nenhuma ação</MenuItem>
                                                                                                                <MenuItem key={"sendMessageAndClosed"} value={"sendMessageAndClosed"}>Enviar mensagem de alerta e encerrar ticket</MenuItem>
                                                                                                                <MenuItem key={"transferOnQueue"} value={"transferOnQueue"}>Enviar mensagem e transferir para fila  </MenuItem>

                                                                                                        </Field>
                                                                                                </FormControl>

                                                                                        </Grid>
                                                                                        <Grid item xs={2} >

                                                                                                {botSetup.actionNoQueue !== "none" ? <>
                                                                                                        <FormControl
                                                                                                                variant="outlined"
                                                                                                                //className={classes.formControl}
                                                                                                                margin="dense"
                                                                                                                fullWidth
                                                                                                        >
                                                                                                                <InputLabel id="typeMenu-selection-input-label">
                                                                                                                        {i18n.t("Tempo Sem Fila")}
                                                                                                                </InputLabel>

                                                                                                                <Field
                                                                                                                        as={Select}
                                                                                                                        label={i18n.t("Tempo Sem Fila")}
                                                                                                                        title={i18n.t("Tempo Sem Fila antes da ação")}
                                                                                                                        name="timeNoQueue"
                                                                                                                        labelId="typeMenu-selection-label"
                                                                                                                        id="timeNoQueue-selection"
                                                                                                                        required
                                                                                                                        value={botSetup.timeNoQueue}
                                                                                                                        onChange={(e) => { setBotSetup({ ...botSetup, timeNoQueue: e.target.value }) }}
                                                                                                                >
                                                                                                                        <MenuItem key={"1m"} value={1}>1 minuto</MenuItem>
                                                                                                                        <MenuItem key={"2m"} value={2}>2 minutos</MenuItem>
                                                                                                                        <MenuItem key={"3m"} value={3}>3 minutos</MenuItem>
                                                                                                                        <MenuItem key={"5m"} value={5}>5 minutos</MenuItem>
                                                                                                                        <MenuItem key={"10m"} value={10}>10 minutos</MenuItem>
                                                                                                                        <MenuItem key={"15m"} value={15}>15 minutos</MenuItem>
                                                                                                                        <MenuItem key={"30m"} value={30}>30 minutos</MenuItem>
                                                                                                                </Field>
                                                                                                        </FormControl>

                                                                                                </> : <></>}
                                                                                        </Grid>
                                                                                        <Grid item xs={4} >
                                                                                                {botSetup.actionNoQueue !== "none" ? <>
                                                                                                        <Field
                                                                                                                as={TextField}
                                                                                                                minRows={3}
                                                                                                                focused
                                                                                                                multiline={true}
                                                                                                                label={i18n.t("Mensagem Sem Fila")}
                                                                                                                name="actionMessageNoQueue"
                                                                                                                size="small"
                                                                                                                variant="outlined"
                                                                                                                margin="dense"
                                                                                                                fullWidth
                                                                                                                //placeholder="Bot desabilitado devido a tentativas incorretas digite # para ver as opções novamente."
                                                                                                                value={botSetup.actionMessageNoQueue}
                                                                                                                onChange={(e) => { setBotSetup({ ...botSetup, actionMessageNoQueue: e.target.value }) }}
                                                                                                        />
                                                                                                </> : <></>}

                                                                                        </Grid>
                                                                                        <Grid item xs={2} >

                                                                                                {botSetup.actionNoQueue === "transferOnQueue" ? <>
                                                                                                        <FormControl size="small" variant="outlined" fullWidth style={{ marginTop: 8 }} >
                                                                                                                <InputLabel>{i18n.t("Fila")}</InputLabel>
                                                                                                                <Select
                                                                                                                        size="small"
                                                                                                                        value={selectedQueue}
                                                                                                                        onChange={(e) => setSelectedQueue(e.target.value)}
                                                                                                                        label={i18n.t("Fila")}
                                                                                                                >
                                                                                                                        {/* <MenuItem value={""}>&nbsp;</MenuItem> */}
                                                                                                                        {queues.map((queue) => (
                                                                                                                                <MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
                                                                                                                        ))}
                                                                                                                </Select>
                                                                                                        </FormControl>
                                                                                                </> : <></>}

                                                                                        </Grid>
                                                                                </Grid>
                                                                        </div>
                                                                </DialogContent> : null}
                                                        <DialogActions>
                                                                <Button
                                                                        onClick={handleClose}
                                                                        color="secondary"
                                                                        disabled={isSubmitting}
                                                                        variant="outlined"
                                                                >
                                                                        {i18n.t("whatsappModal.buttons.cancel")}
                                                                </Button>
                                                                <Button
                                                                        type="submit"
                                                                        color="primary"
                                                                        disabled={isSubmitting}
                                                                        variant="contained"
                                                                        className={classes.btnWrapper}
                                                                >
                                                                        {whatsAppId
                                                                                ? i18n.t("whatsappModal.buttons.okEdit")
                                                                                : i18n.t("whatsappModal.buttons.okAdd")}
                                                                        {isSubmitting && (
                                                                                <CircularProgress
                                                                                        size={24}
                                                                                        className={classes.buttonProgress}
                                                                                />
                                                                        )}
                                                                </Button>
                                                        </DialogActions>
                                                </Form>
                                        )}


                                </Formik>
                        </Dialog>
                </div>
        );
};

export default React.memo(WhatsAppModal);


