import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import SubCategoryClassificationModal from "../../components/SubCategoryClassificationModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Grid } from "@material-ui/core";


const reducer = (state, action) => {
  if (action.type === "LOAD_DATAS") {
    const subCategoryClassifications = action.payload;
    const newSubCategoryClassifications = [];

    subCategoryClassifications.forEach((subCategoryClassification) => {
      const subCategoryClassificationIndex = state.findIndex(
        (u) => u.id === subCategoryClassification.id
      );
      if (subCategoryClassificationIndex !== -1) {
        state[subCategoryClassificationIndex] = subCategoryClassification;
      } else {
        newSubCategoryClassifications.push(subCategoryClassification);
      }
    });

    return [...state, ...newSubCategoryClassifications];
  }

  if (action.type === "UPDATE_DATAS") {
    const subCategoryClassification = action.payload;
    const subCategoryClassificationIndex = state.findIndex(
      (u) => u.id === subCategoryClassification.id
    );

    if (subCategoryClassificationIndex !== -1) {
      state[subCategoryClassificationIndex] = subCategoryClassification;
      return [...state];
    } else {
      return [subCategoryClassification, ...state];
    }
  }

  if (action.type === "DELETE_DATA") {
    const subCategoryClassificationId = action.payload;

    const subCategoryClassificationIndex = state.findIndex(
      (u) => u.id === subCategoryClassificationId
    );
    if (subCategoryClassificationIndex !== -1) {
      state.splice(subCategoryClassificationIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    margin: theme.spacing(1),
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  headerBar: {
    marginBottom:theme.spacing(3),
    display: "flex",
    justifyContent: "space-around"
  },
}));

const SubCategoryClassification = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedSubCategoryClassification, setSelectedSubCategoryClassification] =
    useState(null);
  const [
    selectedSubSubCategoryClassification,
    setSelectedSubSubCategoryClassification,
  ] = useState(null);
  const [deletingSubCategoryClassification, setDeletingSubCategoryClassification] =
    useState(null);
  const [
    deletingSubSubCategoryClassification,
    setDeletingSubSubCategoryClassification,
  ] = useState(null);
  const [subCategoryClassificationModalOpen, setSubCategoryClassificationModalOpen] =
    useState(false);
  const [
    subSubCategoryClassificationModalOpen,
    setSubSubCategoryClassificationModalOpen,
  ] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [subCategoryClassifications, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  const fetchSubCategoryClassifications = async () => {
    try {
      const { data } = await api.get("/subCategoryCl/", {
        params: { searchParam, pageNumber },
      });
      dispatch({
        type: "LOAD_DATAS",
        payload: data.subCategoryClassifications,
      });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchSubCategoryClassifications();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("subCategoryClassification", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({
          type: "UPDATE_DATAS",
          payload: data.subCategoryClassification,
        });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_DATA",
          payload: +data.subCategoryClassificationId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenSubCategoryClassificationModal = () => {
    setSelectedSubCategoryClassification(null);
    setSubCategoryClassificationModalOpen(true);
  };

  const handleOpenSubSubCategoryClassificationModal = () => {
    setSelectedSubSubCategoryClassification(null);
    setSubSubCategoryClassificationModalOpen(true);
  };

  const handleCloseSubCategoryClassificationModal = () => {
    setSelectedSubCategoryClassification(null);
    setSubCategoryClassificationModalOpen(false);
    fetchSubCategoryClassifications();
  };

  const handleCloseSubSubCategoryClassificationModal = () => {
    setSelectedSubSubCategoryClassification(null);
    setSubSubCategoryClassificationModalOpen(false);
    fetchSubCategoryClassifications();
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditSubCategoryClassification = (subCategoryClassification) => {
    setSelectedSubCategoryClassification(subCategoryClassification);
    setSubCategoryClassificationModalOpen(true);
  };

  const handleDeleteSubCategoryClassification = async (
    subCategoryClassificationId
  ) => {
    try {
      await api.delete(`/subCategoryCl/${subCategoryClassificationId}`);
      toast.success(i18n.t("classifications.toasts.deleted"));
      dispatch({ type: "RESET" });
      fetchSubCategoryClassifications();
    } catch (err) {
      toastError(err);
    }
    setDeletingSubCategoryClassification(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingSubCategoryClassification &&
          `${i18n.t("classifications.confirmationModal.deleteTitle")} ${
            deletingSubCategoryClassification.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() =>
          handleDeleteSubCategoryClassification(deletingSubCategoryClassification.id)
        }
      >
        {i18n.t("classifications.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <SubCategoryClassificationModal
        open={subCategoryClassificationModalOpen}
        onClose={handleCloseSubCategoryClassificationModal}
        aria-labelledby="form-dialog-title"
        subCategoryClassificationId={
          selectedSubCategoryClassification && selectedSubCategoryClassification.id
        }
      />
      <MainHeader>
        <Title>{i18n.t("subClassifications.title")}</Title>
      </MainHeader>
      <Grid spacing={12} container>
        <Grid item xs={12}>
          <div className={classes.headerBar}>
            <TextField
              placeholder={i18n.t("contacts.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenSubCategoryClassificationModal}
            >
              {i18n.t("subClassifications.buttons.subClassification")}
            </Button>
          </div>

          <Paper
            style={{ minHeight: "500px", maxHeight: "500px" }}
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {i18n.t("subClassifications.table.subClassification")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("subClassifications.table.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {subCategoryClassifications.map((subCategoryClassification) => (
                    <TableRow key={subCategoryClassification.id}>
                      <TableCell align="center">
                        {subCategoryClassification.name}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleEditSubCategoryClassification(
                              subCategoryClassification
                            )
                          }
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingSubCategoryClassification(
                              subCategoryClassification
                            );
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton columns={1} />}
                </>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default SubCategoryClassification;
