import React, { useContext, useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import Button from "@material-ui/core/Button";
import { Box } from "../../components/DropDownMenu";
import { ReportAttendanceData } from "../../components/ReportAttendanceData";
import { DialogContent, TextField, makeStyles } from "@material-ui/core";
import moment from "moment";
import "moment/locale/pt-br";
import toastError from "../../errors/toastError";
import _ from "lodash";
import DashStatusGeral from "../../components/DashStatusGeral";
import DashStatusByUser from "../../components/DashStatusByUser";
import DashStatusByQueue from "../../components/DashStatusByQueue";
import CircularProgress from "@mui/material/CircularProgress";
import { Field, Form, Formik } from "formik";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const startOfMonth = moment().startOf("month").format("YYYY-MM-DDTHH:mm");
const today = moment().format("YYYY-MM-DDTHH:mm");
let initialState = {
  initialDate: startOfMonth,
  finalDate: today,
  currentQueue: { id: -1, name: "Todos" },
  currentUser: { id: -1, name: "Todos" },
};
export const Dashboard = () => {
  const classes = useStyles();
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportData, setReportData] = useState(false);
  const [dashData, setDashdData] = useState();
  const [query, setQuery] = useState(initialState);
  const [initial, setInitial] = useState(initialState);
  const handleGenerateReport = async (values) => {
    let newQuery = {
      initialDate: values.initialDate,
      finalDate: values.finalDate,
      currentQueue: { id: -1, name: "Todos" },
      currentUser: { id: -1, name: "Todos" },
    };
    setQuery(newQuery);
    const { data } = await api.get("/dash/attendance", { params: newQuery });
    setDashdData(data);
  };
  let count = 0;
  useEffect(() => {
    const fetchData = async () => {
      count = 0;
      try {
        const { data } = await api.get("/dash/attendance", { params: query });

        setDashdData(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchData();
  }, [count]);

  const dashboardData = { ...dashData }.reportData;

  const handleCloseReportModal = () => {
    setReportModalOpen(false);
    //console.log("modal fechado");
    document.getElementById("dashboard").style.display = "none";
    document.getElementById("report").style.display = "block";
  };

  const goBackDashboard = () => {
    document.getElementById("dashboard").style.display = "block";
    document.getElementById("report").style.display = "none";
  };

  const handleSaveReport = (e) => {
    setReportData(e);
  };

  return (
    <div>
      <Container
        id="dashboard"
        style={{ display: "block" }}
        maxWidth="lg"
        className={classes.container}
      >
        <Grid>
          <Formik
            initialValues={initial}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleGenerateReport(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting, values, props }) => (
              <Form>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Field
                        as={TextField}
                        label={i18n.t("reports.initialDate")}
                        type="datetime-local"
                        name="initialDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.sendAt && Boolean(errors.sendAt)}
                        helperText={touched.sendAt && errors.sendAt}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        as={TextField}
                        label={i18n.t("reports.finalDate")}
                        type="datetime-local"
                        name="finalDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.sendAt && Boolean(errors.sendAt)}
                        helperText={touched.sendAt && errors.sendAt}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        type="submit"
                        color="primary"
                        size="large"
                        //disabled={isSubmitting}
                        variant="contained"
                        className={classes.btnWrapper}
                      >
                        Filtrar
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Legenda:
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom>
                        TMA: Tempo médio de atendimento
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom>
                        TME: Tempo médio de espera
                      </Typography>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Form>
            )}
          </Formik>
        </Grid>
        {/* <Grid className={classes.btnReports}>
                                        
                                        <Box>
                                                <DropdownMenu>
                                                        <DropdownMenuTrigger asChild>
                                                                <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                >
                                                                        {i18n.t("reports.reportsGenerate")}
                                                                </Button>
                                                        </DropdownMenuTrigger>

                                                        <DropdownMenuContent sideOffset={5}>
                                                                <DropdownMenuItem onClick={handleOpenScheduleModal}>
                                                                        {i18n.t("reports.indicators")} <RightSlot></RightSlot>
                                                                </DropdownMenuItem>
                                                                <DropdownMenuItem onClick={handleOpenScheduleModal}>
                                                                        {i18n.t("reports.reportService")} <RightSlot></RightSlot>
                                                                </DropdownMenuItem>
                                                                <DropdownMenuSeparator />
                                                                <DropdownMenuItem disabled>
                                                                        {i18n.t("reports.satisfactionSurvey")}<RightSlot></RightSlot>
                                                                </DropdownMenuItem>
                                                        </DropdownMenuContent>
                                                </DropdownMenu>
                                        </Box>

                                </Grid> */}
        {dashData ? (
          <table
            border="0"
            id="mi"
            style={{ width: 1200, border: "1" }}
            cellPadding={"5"}
            align="center"
          >
            <caption>
              <h2>
                {`Indicadores de atendimentos de `}
                {new Date(query.initialDate)
                  .toLocaleString()
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")}{" "}
                a{" "}
                {query.finalDate
                  .toLocaleString()
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")}
              </h2>
            </caption>

            <tr>
              <th>Status Geral</th>
            </tr>
            <tr>
              <th>
                <DashStatusGeral
                  data={dashData}
                  query={query}
                ></DashStatusGeral>
              </th>
            </tr>
            <tr>
              <th>
                <hr></hr>
              </th>
            </tr>
            <tr>
              <th>Por Usuário</th>
            </tr>
            <tr>
              <th>
                <DashStatusByUser
                  data={dashData}
                  query={query}
                ></DashStatusByUser>
              </th>
            </tr>
            <tr>
              <th>
                <hr></hr>
              </th>
            </tr>
            <tr>
              <th>Por Fila</th>
            </tr>
            <tr>
              <th>
                <DashStatusByQueue
                  data={dashData}
                  query={query}
                ></DashStatusByQueue>
              </th>
            </tr>
          </table>
        ) : (
          <CircularProgress></CircularProgress>
        )}
      </Container>

      <Container
        id="report"
        style={{ display: "none" }}
        maxWidth="lg"
        className={classes.container}
      >
        <Box className={classes.btnReports}>
          <Button variant="contained" color="primary" onClick={goBackDashboard}>
            {i18n.t("common.goBack")}
          </Button>
        </Box>
        {reportData && <ReportAttendanceData data={reportData} />}
      </Container>
    </div>
  );
};

export default Dashboard;
