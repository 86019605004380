import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    marginBottom: 22,
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },
  multField: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    marginRight: "10px",
    marginTop: "10px",
    minWidth: 120,
  },
  formControlEnd: {
    marginRight: "10px",
    marginTop: "10px",
    minWidth: 140,
  },
}));

const UserSchema = Yup.object().shape({

});

const NotWorkingHoursModal = ({
  open,
  onClose,
  expedientId,
  notWorkingHoursId,

}) => {
  const classes = useStyles();
  const initialState = {
    type: "weekly",
    description: "",
    message: "",
    startHour: moment().format("YYYY-MM-DDTHH:mm"),
    endHour: moment().format("YYYY-MM-DDTHH:mm"),
    dayOfWeek: "1970-01-05",
    weeklyStartHour: moment(new Date("1970-01-05T07:00")).format("HH:mm"),
    weeklyEndHour: moment(new Date("1970-01-05T19:00")).format("HH:mm")
  };

  const [notWorkingHours, setWorkingHour] = useState(initialState);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchUser = async () => {
      console.log(notWorkingHoursId)
      if (!notWorkingHoursId) return;

      try {
        setLoading(true)
        const { data } = await api.get(`/notWorkingHour/${notWorkingHoursId}`);
        setWorkingHour({
          ...data, dayOfWeek: moment(data.startHour).format("YYYY-MM-DD"),
          weeklyStartHour: moment(data.startHour).format("HH:mm"),
          weeklyEndHour: moment(data.endHour).format("HH:mm")
        })

          ;
      } catch (err) {
        toastError(err);
      }
      setLoading(false)
    };

    fetchUser();
  }, [notWorkingHoursId, open]);



  const handleClose = () => {
    onClose();
    setWorkingHour(initialState);
  };



  const handleSave = async () => {

    let notWorkingHourssData = { ...notWorkingHours, expedientId };
    if (notWorkingHours.type === "weekly") {
      const startHour = `${notWorkingHours.dayOfWeek}T${notWorkingHours.weeklyStartHour}`
      const endHour = `${notWorkingHours.dayOfWeek}T${notWorkingHours.weeklyEndHour}`
      notWorkingHourssData = { ...notWorkingHours, expedientId, startHour, endHour };
    }

    try {
      if (notWorkingHoursId) {
        await api.put(`/notWorkingHour/${notWorkingHoursId}`, notWorkingHourssData);
        toast.success(i18n.t("notWorkingHourss.toasts.edited"));
      } else {
        await api.post("/notWorkingHour", notWorkingHourssData);
        toast.success(i18n.t("notWorkingHourss.toasts.created"));
      }

    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <>
      {loading ? <></> :

        <div className={classes.root}>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            scroll="paper"
          >
            <DialogTitle id="form-dialog-title">
              {notWorkingHoursId
                ? `${i18n.t("notWorkingHoursModal.title.edit")}`
                : `${i18n.t("notWorkingHoursModal.title.add")}`}
            </DialogTitle>
            <Formik
              initialValues={notWorkingHours}
              enableReinitialize={true}
              validationSchema={UserSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSave(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form style={{ height: "100%", marginBottom: "40px" }}>
                  <DialogContent dividers>
                    <div style={{ marginBottom: 22 }} className={classes.multFieldLine}>
                      <div style={{ width: 230 }}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className={classes.formControl}
                          margin="dense"
                        >
                          <InputLabel id="notWorkingHoursModal.form.type">
                            {i18n.t(
                              "notWorkingHoursModal.form.type"
                            )}
                          </InputLabel>
                          <Field
                            fullWidth
                            as={Select}
                            label={i18n.t(
                              "notWorkingHoursModal.form.type"
                            )}
                            name="type"
                            labelId="notWorkingHoursModal.form.type"
                            id="notWorkingHoursModal.form.type"
                            required
                            value={notWorkingHours.type}
                            onChange={(e) => {
                              setWorkingHour({ ...notWorkingHours, type: e.target.value })
                            }
                            }
                            title={i18n.t(
                              "notWorkingHoursModal.form.type"
                            )}

                          >
                            <MenuItem value={"weekly"}>Semanal</MenuItem>
                            <MenuItem value={"yearly"}>Anual</MenuItem>
                          </Field>
                        </FormControl>
                      </div>
                      <div style={{ width: "100%", paddingTop: 2, marginLeft: 6 }}>

                        <Field
                          as={TextField}
                          label={i18n.t("notWorkingHoursModal.form.description")}
                          name="description"
                          value={notWorkingHours.description}
                          onChange={(e) => {
                            setWorkingHour({ ...notWorkingHours, description: e.target.value });
                          }}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />


                      </div>
                    </div>
                    <div style={{ width: "100%", marginBottom:12  }}>

                      <Field
                        as={TextField}
                        minRows={6}
                        multiline={true}
                        label={i18n.t("notWorkingHoursModal.form.message")}
                        name="message"
                        value={notWorkingHours.message}
                        onChange={(e) => {
                          setWorkingHour({ ...notWorkingHours, message: e.target.value });
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />


                    </div>



                    <>{notWorkingHours.type === 'yearly' ?
                      <div className={classes.multFieldLine}>
                        <Field
                          as={TextField}
                          label={i18n.t("notWorkingHoursModal.form.startHour")}
                          type="datetime-local"
                          name="startHour"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          fullWidth
                          value={moment(notWorkingHours.startHour).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          onChange={(e) => {
                            setWorkingHour({ ...notWorkingHours, startHour: e.target.value });
                          }}
                        />
                        <Field
                          as={TextField}
                          label={i18n.t("notWorkingHoursModal.form.endHour")}
                          type="datetime-local"
                          name="endHour"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={touched.sendAt && Boolean(errors.sendAt)}
                          helperText={touched.sendAt && errors.sendAt}
                          variant="outlined"
                          fullWidth
                          value={moment(notWorkingHours.endHour).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          onChange={(e) => {
                            setWorkingHour({ ...notWorkingHours, endHour: e.target.value });
                          }}
                        />
                      </div>
                      :
                      null
                    }</>

                    <>{notWorkingHours.type === 'weekly' ?
                      <div>



                        <>
                          <div style={{ width: 230, marginBottom: 22 }}>
                            <FormControl
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            >
                              <InputLabel id="weeklyHour.form.weeklyDay">
                                {i18n.t(
                                  "weeklyHour.form.weeklyDay"
                                )}
                              </InputLabel>
                              <Field
                                as={Select}
                                label={i18n.t(
                                  "weeklyHour.form.weeklyDay"
                                )}
                                name="weeklyDay"
                                labelId="weeklyHour.form.weeklyDay"
                                id="allowViewCloseTickets-selection"
                                required
                                value={notWorkingHours.dayOfWeek}
                                onChange={(e) => {
                                  setWorkingHour({ ...notWorkingHours, dayOfWeek: e.target.value });
                                }}
                              >
                                <MenuItem key="Sunday" value="1970-01-04">Domingo</MenuItem>
                                <MenuItem key="Monday" value="1970-01-05">Segunda</MenuItem>
                                <MenuItem key="Tuesday" value="1970-01-06">Terça</MenuItem>
                                <MenuItem key="Wednesday" value="1970-01-07">Quarta</MenuItem>
                                <MenuItem key="Thursday" value="1970-01-08">Quinta</MenuItem>
                                <MenuItem key="Friday" value="1970-01-09">Sexta</MenuItem>
                                <MenuItem key="Saturday" value="1970-01-10">Sábado</MenuItem>
                              </Field>
                            </FormControl>
                          </div>
                          <div className={classes.multFieldLine}>
                            <Field
                              as={TextField}
                              label={i18n.t("weeklyHour.form.startHour")}
                              type="time"
                              name="startHour"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={notWorkingHours.weeklyStartHour}
                              onChange={(e) => {
                                setWorkingHour({ ...notWorkingHours, weeklyStartHour: e.target.value });
                              }}
                              variant="outlined"
                              fullWidth
                            />
                            <Field
                              as={TextField}
                              label={i18n.t("weeklyHour.form.endHour")}
                              type="time"
                              name="endHour"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={notWorkingHours.weeklyEndHour}
                              onChange={(e) => {
                                setWorkingHour({ ...notWorkingHours, weeklyEndHour: e.target.value });
                              }}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </>
                      </div>
                      :
                      null
                    }</>

                    <div className={classes.multField}>

                      <div style={{ width: "430px" }}>

                        <DialogActions>
                          <Button
                            onClick={handleClose}
                            color="secondary"
                            disabled={isSubmitting}
                            variant="outlined"
                          >
                            {i18n.t("common.cancel")}
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                            variant="contained"
                            className={classes.btnWrapper}
                          >
                            {notWorkingHoursId
                              ? `${i18n.t("common.save")}`
                              : `${i18n.t("common.save")}`}
                            {isSubmitting && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </Button>
                        </DialogActions>

                      </div>


                    </div>
                  </DialogContent>
                </Form>
              )}
            </Formik>
          </Dialog>
        </div>
      }
    </>

  );
};

export default NotWorkingHoursModal;
