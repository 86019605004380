import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { isArray } from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ReportNpsModal = ({
  open,
  onClose,
  onAddReport,
}) => {
  const classes = useStyles();
  const initialState = {
    initialDate: moment().add(-30, "days").format("YYYY-MM-DDTHH:mm"),
    finalDate: moment().add(0, "days").format("YYYY-MM-DDTHH:mm"),
    initialUser: { id: -1, name: "Todos" },
    initialQueue: { id: -1, name: "Todos" },
    initialNps: { id: -1, name: "Todos" },
  };
  const [initial, setInitial] = useState(initialState);
  const [currentQueue, setCurrentQueue] = useState(initialState.initialQueue);
  const [currentUser, setCurrentUser] = useState(initialState.initialUser);
  const [currentNps, setCurrentNps] = useState(initialState.initialNps);
  const [queues, setQueues] = useState([initialState.initialQueue]);
  const [users, setUsers] = useState([]);
  const [npss, setNpss] = useState([]);
  const [sub, setSub] = useState(false);


  useEffect(() => {
    if(open){
      try {
        (async () => {
          const { data: queueList } = await api.get("/queue");
          queueList.push(initialState.initialQueue);
          let customQueueList = queueList.map((c) => ({
            id: c.id,
            name: c.name,
          }));
  
          if (isArray(customQueueList)) {
            setQueues([{ id: "", name: "" }, ...customQueueList]);
          }
  
          const { data: userList } = await api.get("/users/list");
          userList.push(initialState.initialUser);
          let customUserList = userList.map((c) => ({ id: c.id, name: c.name }));
          if (isArray(customUserList)) {
            setUsers([{ id: "", name: "" }, ...customUserList]);
          }
          const {data} = await api.get("/npsSurvey", {
            params: { searchParam: "reportModal"},
          });
          let customNpsList = data?.npsSurveys
          customNpsList = customNpsList.map((c) => ({ id: c.id, name: c.name }));
          if (isArray(customNpsList)) {
            setNpss([{ id: "", name: "" }, ...customNpsList]);
          }
        })();
      } catch (err) {
        toastError(err);
      }
    }

  }, [open]);

  const handleClose = () => {
    onClose();
    setInitial(initialState);
    setCurrentQueue(initialState.initialQueue)
    setCurrentNps(initialState.initialNps)
    setCurrentUser(initialState.initialUser)
  };
  const handleGenerateReport = async (values) => {
    if(sub === false)return
    const { initialDate, finalDate } = values;
    const filter = { initialDate, finalDate, currentQueue, currentUser, currentNps };
    const { data } = await api.get("/report/nps", {
      params: { initialDate, finalDate, queueId:currentQueue.id, userId:currentUser.id, npsSurveyId: currentNps.id },
    });
    console.log(data)
    const report = data.reportData
    const dataList = { filter: filter, report };
    onAddReport(dataList);
    handleClose();
  };
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <Formik
          initialValues={initial}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleGenerateReport(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, props }) => (
            <Form>
              <DialogContent dividers>
              <h3>{i18n.t("reports.classification")}</h3>
              <div className={classes.multFieldLine}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      fullWidth
                      value={currentNps}
                      options={npss}
                      required
                      onChange={(e, nps) => {
                        setCurrentNps(nps ? nps : initialState.initialNps);
                      }}
                      getOptionLabel={(nps) => nps.name}
                      getOptionSelected={(nps, value) => {
                        return value.id === nps.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Pesquisa NPS"
                          placeholder="Pesquisa NPS"
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <hr></hr>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("reports.initialDate")}
                    type="datetime-local"
                    name="initialDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.sendAt && Boolean(errors.sendAt)}
                    helperText={touched.sendAt && errors.sendAt}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <hr></hr>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("reports.finalDate")}
                    type="datetime-local"
                    name="finalDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.sendAt && Boolean(errors.sendAt)}
                    helperText={touched.sendAt && errors.sendAt}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <hr></hr>
                <div className={classes.multFieldLine}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      fullWidth
                      name="queue"
                      value={currentQueue}
                      options={queues}
                      required
                      onChange={(e, queue) => {
                        setCurrentQueue(
                          queue ? queue : initialState.initialQueue
                        );
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => {
                        return value.id === option.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Fila"
                          placeholder="Fila"
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <hr></hr>
                <div className={classes.multFieldLine}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      fullWidth
                      value={currentUser}
                      options={users}
                      required
                      onChange={(e, user) => {
                        setCurrentUser(user ? user : initialState.initialUser);
                      }}
                      getOptionLabel={(user) => user.name}
                      getOptionSelected={(user, value) => {
                        return value.id === user.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Atendente"
                          placeholder="Atendente"
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <br />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("common.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  //disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                  onClick={()=>setSub(true)}
                >
                  {i18n.t("reports.generate")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ReportNpsModal;
