import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import NpsOptions from "./options";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },
    multField: {
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        marginRight: "10px",
        marginTop: "10px",
        minWidth: 120,
    },
    boxTextField: {
        minWidth: "650px",
        marginLeft: "22px"
    },
    formControlEnd: {
        marginRight: "10px",
        marginTop: "10px",
        minWidth: 140,
    },
}));

const UserSchema = Yup.object().shape({

});

const NpsManager = ({
    open,
    onClose
}) => {
    const classes = useStyles();
    const history = useHistory();

    const { npsSurveyId } = useParams();
    const initialState = {
        name: "",
        description: "",
        msgReqParticipation: "",
        msgUnreportedClosed: "",
        msgThankNotParticipation: "",
        msgThankParticipation: "",
        msgReqPoints: "",
        msgReqComments: "",
        msgThankParticipation: "",
        timeEndOfNotResponse: 1,
    };

    const [npsSurvey, setClassification] = useState(initialState);
    useEffect(() => {
        const fetchUser = async () => {
            if (!npsSurveyId) return;
            try {
                const { data } = await api.get(`/npsSurvey/${npsSurveyId}`);
                setClassification((prevState) => {
                    return { ...prevState, ...data };
                });
            } catch (err) {
                toastError(err);
            }
        };

        fetchUser();
    }, [npsSurveyId, open]);

    const handleClose = () => {
        onClose();
        setClassification(initialState);
    };



    const handleSaveUser = async (values) => {
        const npsSurveyData = { ...values };
        try {
            if (npsSurveyId) {
                await api.put(`/npsSurvey/${npsSurveyId}`, npsSurveyData);
                toast.success(i18n.t("npsSurvey.toasts.edited"));
            } else {
                const { data } = await api.post("/npsSurvey", npsSurveyData);
                toast.info(i18n.t("npsSurvey.toasts.created"), { autoClose: 10000 });
                setTimeout(() => {
                    history.push(`./npsManager/${data.id}`)
                }, 300);
            }
        } catch (err) {
            toastError(err);
        }

    };

    return (
        <div className={classes.root}>
            <DialogTitle id="form-dialog-title">
                {npsSurveyId
                    ? `${i18n.t("npsSurveyModal.title.edit")}`
                    : `${i18n.t("npsSurveyModal.title.add")}`}
            </DialogTitle>
            <Formik
                initialValues={npsSurvey}
                enableReinitialize={true}
                validationSchema={UserSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSaveUser(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form style={{ height: "100%", marginBottom: "40px" }}>
                        <DialogContent dividers>
                            <div className={classes.multField}>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("npsSurveyModal.form.name")}
                                        name="name"
                                        error={
                                            touched.name && Boolean(errors.name)
                                        }
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        className={classes.selectColor}
                                        name="timeEndOfNotResponse"
                                        label={i18n.t("npsSurveyModal.form.timeEndOfNotResponse")}
                                        type="number"
                                        title={i18n.t("npsSurveyModal.form.timeEndOfNotResponseExplication")}
                                        InputProps={{ inputProps: { min: parseInt(1), max: parseInt(60) } }}
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                    //onChange={e => setTimeOpeningYellow(e.target.value)}
                                    //value={timeOpeningYellow}
                                    />

                                </div>
                            </div>
                            <div className={classes.multField}>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("npsSurveyModal.form.description")}
                                        minRows={2}
                                        multiline={true}
                                        name="description"
                                        error={
                                            touched.description && Boolean(errors.description)
                                        }
                                        helperText={touched.description && errors.description}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("npsSurveyModal.form.msgReqParticipation")}
                                        minRows={2}
                                        multiline={true}
                                        name="msgReqParticipation"
                                        error={
                                            touched.msgReqParticipation && Boolean(errors.msgReqParticipation)
                                        }
                                        helperText={touched.msgReqParticipation && errors.msgReqParticipation}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className={classes.multField}>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("npsSurveyModal.form.msgReqPoints")}
                                        minRows={2}
                                        multiline={true}
                                        name="msgReqPoints"
                                        error={
                                            touched.msgReqPoints && Boolean(errors.msgReqPoints)
                                        }
                                        helperText={touched.msgReqPoints && errors.msgReqPoints}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("npsSurveyModal.form.msgReqComments")}
                                        minRows={2}
                                        multiline={true}
                                        name="msgReqComments"
                                        error={
                                            touched.msgReqComments && Boolean(errors.msgReqComments)
                                        }
                                        helperText={touched.msgReqComments && errors.msgReqComments}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>

                            </div>
                            <div className={classes.multField}>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("npsSurveyModal.form.msgUnreportedClosed")}
                                        minRows={2}
                                        multiline={true}
                                        name="msgUnreportedClosed"
                                        error={
                                            touched.msgUnreportedClosed && Boolean(errors.msgUnreportedClosed)
                                        }
                                        helperText={touched.msgUnreportedClosed && errors.msgUnreportedClosed}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("npsSurveyModal.form.msgThankNotParticipation")}
                                        minRows={2}
                                        multiline={true}
                                        name="msgThankNotParticipation"
                                        error={
                                            touched.msgThankNotParticipation && Boolean(errors.msgThankNotParticipation)
                                        }
                                        helperText={touched.msgThankNotParticipation && errors.msgThankNotParticipation}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className={classes.multField}>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("npsSurveyModal.form.msgThankParticipation")}
                                        minRows={2}
                                        multiline={true}
                                        name="msgThankParticipation"
                                        error={
                                            touched.msgThankParticipation && Boolean(errors.msgThankParticipation)
                                        }
                                        helperText={touched.msgThankParticipation && errors.msgThankParticipation}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.boxTextField}>
                                    <DialogActions>
                                        <Button
                                            onClick={() => { history.push(npsSurveyId ? "../npsSurvey" : "./npsSurvey") }}
                                            color="secondary"
                                            disabled={isSubmitting}
                                            variant="outlined"
                                        >
                                            {i18n.t("common.goBack")}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {npsSurveyId
                                                ? `${i18n.t("common.save")}`
                                                : `${i18n.t("npsSurveyModal.buttons.add")}`}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </div>
                            </div>
                        </DialogContent>
                    </Form>
                )}
            </Formik>
            {npsSurveyId ?
                <div style={{marginLeft:"50px",marginRight:"50px", padding:"18px",  minWidth:"80%", border:"solid 1px #e9e9e9 ", borderRadius:"8px"}}>
                    <NpsOptions
                        npsSurveyId={npsSurveyId}
                    />
                </div>


                : null}


        </div>
    );
};

export default NpsManager;
