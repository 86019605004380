import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const UsersSelect = ({
    userList,
    selectedUserIds = [],
    onChange,
}) => {
    const handleChange = (event) => {
        const value = event.target.value;
        if (value.indexOf("selectAll") > -1) {
            if (userList.length !== selectedUserIds.length) {
                onChange(userList.map(user => user.id));
            }
        } else if (value.indexOf("deselectAll") > -1) {
            onChange([]);
        } else {
            onChange(value);
        }
    };

    return (
        <div style={{ width: 150, marginRight: 10, marginBottom: 4, marginTop: -6 }}>
            <FormControl fullWidth margin="dense">          
                <Select
                    multiple
                    displayEmpty
                    variant="outlined"
                    value={selectedUserIds}
                    onChange={handleChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                    renderValue={() => selectedUserIds.length > 0 ? selectedUserIds.length + " Usuários" : i18n.t("Usuários")}
                >
                            <MenuItem dense value="selectAll">
                                <ListItemText primary="Selecionar Todos" />
                            </MenuItem>
                            <MenuItem dense value="deselectAll">
                                <ListItemText primary="Nenhum" />
                            </MenuItem>
                            {userList?.length > 0 &&
                                userList.map(user => (
                                    <MenuItem dense key={user.id} value={user.id}>
                                        <Checkbox
                                            size="small"
                                            color="primary"
                                            checked={selectedUserIds.indexOf(user.id) > -1}
                                        />
                                        <ListItemText primary={user.name} />
                                    </MenuItem>
                                ))}         
                </Select>
            </FormControl>
        </div>
    );
};

export default UsersSelect;
