import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { i18n } from "../../translate/i18n";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  RightSlot,
} from "../../components/DropDownMenu";
import ReportAttendanceModal from "../../components/ReportAttendanceModal";
import { ReportAttendanceData } from "../../components/ReportAttendanceData";
import ReportClassificationModal from "../../components/ReportClassificationModal";
import { ReportClassificationData } from "../../components/ReportClassificationData";
import ReportNpsModal from "../../components/ReportNpsModal";
import { ReportNpsData } from "../../components/ReportNpsData";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btnReports: {
    marginBottom: "6vh",
  },
}));

export const Reports = () => {
  const classes = useStyles();
  const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);
  const [reportAttendanceData, setReportAttendanceData] = useState(false);
  const [classificationModalOpen, setClassificationModalOpen] = useState(false);
  const [npsModalOpen, setNpsModalOpen] = useState(false);
  const [reportClassificationData, setReportClassificationData] = useState(false);
  const [reportNpsData, setReportNpsData] = useState(false);


  useEffect(() => {
    console.log(reportNpsData)
  }, [reportNpsData]);

  return (
    <div className={classes.root}>
      <ReportAttendanceModal
        open={attendanceModalOpen}
        onClose={() => setAttendanceModalOpen(false)}
        aria-labelledby="form-dialog-title"
        onAddReport={(e) => setReportAttendanceData(e)}
      />
      <ReportClassificationModal
        open={classificationModalOpen}
        onClose={() => setClassificationModalOpen(false)}
        aria-labelledby="form-dialog-title"
        onAddReport={(e) => setReportClassificationData(e)}
      />
      <ReportNpsModal
        open={npsModalOpen}
        onClose={() => setNpsModalOpen(false)}
        aria-labelledby="form-dialog-title"
        onAddReport={(e) => setReportNpsData(e)}
      />

      {!reportClassificationData && !reportAttendanceData && !reportNpsData && (
        <Grid className={classes.btnReports}>
          <Box>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="contained" color="primary">
                  {i18n.t("reports.reportsGenerate")}
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent sideOffset={5}>
                <DropdownMenuItem onClick={() => setAttendanceModalOpen(true)}>
                  {i18n.t("reports.attendance")} <RightSlot></RightSlot>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setClassificationModalOpen(true)}
                >
                  {i18n.t("reports.classification")} <RightSlot></RightSlot>
                </DropdownMenuItem>

                <DropdownMenuItem
                  onClick={() => setNpsModalOpen(true)}
                >
                  {i18n.t("reports.nps")} <RightSlot></RightSlot>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </Box>
        </Grid>
      )}

      {reportAttendanceData && (
        <Container id="report" maxWidth="lg" className={classes.container}>
          <>
            <Box className={classes.btnReports}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setReportAttendanceData();
                  setReportClassificationData();
                  setReportNpsData();
                }}
              >
                {i18n.t("common.goBack")}
              </Button>
            </Box>

            <ReportAttendanceData data={reportAttendanceData} />
          </>
        </Container>
      )}
      {reportClassificationData && (
        <Container id="report" maxWidth="lg" className={classes.container}>
          <>
            <Box className={classes.btnReports}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setReportAttendanceData();
                  setReportClassificationData();
                  setReportNpsData();
                }}
              >
                {i18n.t("common.goBack")}
              </Button>
            </Box>

            <ReportClassificationData data={reportClassificationData} />
          </>
        </Container>
      )}

      {reportNpsData && (
        <Container id="report" maxWidth="lg" className={classes.container}>
          <>
            <Box className={classes.btnReports}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setReportAttendanceData();
                  setReportClassificationData();
                  setReportNpsData();
                }}
              >
                {i18n.t("common.goBack")}
              </Button>
            </Box>

            <ReportNpsData data={reportNpsData} />
          </>
        </Container>
      )}
    </div>
  );
};

export default Reports;
