// import { Dialog } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from "react";

import { SketchPicker } from "react-color";

const ColorPicker = ({ onChange, currentColor, handleClose, open }) => {
  const [selectedColor, setSelectedColor] = useState(currentColor);

    const handleChange = (color) => {
    setSelectedColor(color.hex);
    // handleClose();
  };

  return (

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Seletor de Cores</DialogTitle>
        <DialogContent>
    <SketchPicker
    color={selectedColor}
    onChange={handleChange}
    onChangeComplete={(color) => onChange(color.hex)}
    />
        </DialogContent>

    </Dialog>
  );
};

export default ColorPicker;
