import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import WorkingHours from "./workingHours";
import NotWorkingHours from "./notWorkingHours";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },
    multField: {
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        marginRight: "10px",
        marginTop: "10px",
        minWidth: 120,
    },
    boxTextField: {
        minWidth: "650px",
        marginLeft: "22px"
    },
    formControlEnd: {
        marginRight: "10px",
        marginTop: "10px",
        minWidth: 140,
    },
}));

const UserSchema = Yup.object().shape({

});


const ExpedientManager = ({
    open,
    onClose
}) => {
    const classes = useStyles();
    const history = useHistory();

    const { expedientId } = useParams();
    const initialState = {
        name: "",
        message: "",
        msgReqParticipation: "",
        msgUnreportedClosed: "",
        msgThankNotParticipation: "",
        msgThankParticipation: "",
        msgReqPoints: "",
        msgReqComments: "",
        msgThankParticipation: "",
        timeEndOfNotResponse: 1,
    };

    const [expedient, setClassification] = useState(initialState);
    const [tab, setTab] = useState("WorkingHours");
    useEffect(() => {
        const fetchUser = async () => {
            if (!expedientId) return;
            try {
                const { data } = await api.get(`/expedient/${expedientId}`);
                setClassification((prevState) => {
                    return { ...prevState, ...data };
                });
            } catch (err) {
                toastError(err);
            }
        };

        fetchUser();
    }, [expedientId, open]);

    const handleClose = () => {
        onClose();
        setClassification(initialState);
    };



    const handleSaveUser = async (values) => {
        const expedientData = { ...values };
        try {
            if (expedientId) {
                await api.put(`/expedient/${expedientId}`, expedientData);
                toast.success(i18n.t("expedient.toasts.edited"));
            } else {
                const { data } = await api.post("/expedient", expedientData);
                toast.info(i18n.t("expedient.toasts.created"), { autoClose: 10000 });
                setTimeout(() => {
                    history.push(`./expedientManager/${data.id}`)
                }, 300);
            }
        } catch (err) {
            toastError(err);
        }

    };

    return (
        <div 
        //className={classes.root}
        >
            <DialogTitle id="form-dialog-title">
                {expedientId
                    ? `${i18n.t("expedient.title.edit")}`
                    : `${i18n.t("expedient.title.add")}`}
            </DialogTitle>
            <Formik
                initialValues={expedient}
                enableReinitialize={true}
                validationSchema={UserSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSaveUser(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form style={{ height: "100%", marginBottom: "40px" }}>
                        <DialogContent dividers>
                            <div className={classes.multField}>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("expedient.form.name")}
                                        name="name"
                                        error={
                                            touched.name && Boolean(errors.name)
                                        }
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className={classes.multField}>
                                <div className={classes.boxTextField}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("expedient.form.message")}
                                        minRows={2}
                                        multiline={true}
                                        name="message"
                                        error={
                                            touched.message && Boolean(errors.message)
                                        }
                                        helperText={touched.message && errors.message}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className={classes.multField}>

                                <div className={classes.boxTextField}>
                                    <DialogActions>
                                        <Button
                                            onClick={() => { history.push(expedientId ? "../expedient" : "./expedient") }}
                                            color="secondary"
                                            disabled={isSubmitting}
                                            variant="outlined"
                                        >
                                            {i18n.t("common.goBack")}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {expedientId
                                                ? `${i18n.t("common.save")}`
                                                : `${i18n.t("common.save")}`}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </div>
                            </div>
                        </DialogContent>
                    </Form>
                )}
            </Formik>
            <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: "50px", marginRight: "50px", padding: "18px", minWidth: "80%", }} >
                <Button
                    style={{ marginRight: 12 }}
                    variant="contained"
                    color={tab === "WorkingHours" ? "secondary" : "default"}
                    onClick={() => setTab("WorkingHours")}

                >Horário de funcionamento</Button>
                <Button
                    variant="contained"
                    color={tab === "NotWorkingHours" ? "secondary" : "default"}
                    onClick={() => setTab("NotWorkingHours")}

                >Horário de não funcionamento</Button>

            </div>
            {expedientId ?
                <>
                {tab === "WorkingHours" ? 
                
                <div style={{ marginLeft: "50px", marginRight: "50px", padding: "18px", minWidth: "80%", border: "solid 1px #e9e9e9 ", borderRadius: "8px" }}>
                <WorkingHours
                    expedientId={expedientId}
                />
            </div> 
                : 
                
                <div style={{ marginLeft: "50px", marginRight: "50px", padding: "18px", minWidth: "80%", border: "solid 1px #e9e9e9 ", borderRadius: "8px" }}>
                <NotWorkingHours
                    expedientId={expedientId}
                />
            </div>
                
                }



                </>



                : null}


        </div>
    );
};

export default ExpedientManager;
