import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { isArray } from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Paper, Table, TableCell, TableContainer, TableRow } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    justifyContent: 'space-between',
    display: "flex",
    flexWrap: "wrap",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    marginBottom: "12px",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    marginRight: "10px",
    marginTop: "-3px",
    marginBottom: "-3px",
    minWidth: 120,
  },
  formControlEnd: {
    marginRight: "10px",
    //marginTop: "10px",
    minWidth: 200,
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const UserModal = ({ open, onClose, userId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    email: "",
    password: "",
    profile: "user",
    allowNoQueue: 1,
    allowTicketExport: 0,
    enableGreeting: 1,
    enableTransfer: 1,
    enableFarewell: 1,
    allowViewPendingTickets: 0,
    allowEnableGreeting: 0,

    allowViewCloseTickets: "all",
    classificationPermission: "disabled",
    initialNps: { id: -1, name: "Desabilitado" },
    allowSignature: true,
    enableSignature: true,
  };

  const { user: loggedInUser } = useContext(AuthContext);

  const [user, setUser] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [npss, setNpss] = useState([initialState.initialNps]);
  const [currentNps, setCurrentNps] = useState(initialState.initialNps);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const { data } = await api.get(`/users/${userId}`);
        if (data?.npsSurvey?.id) {
          setCurrentNps({ id: data?.npsSurvey?.id, name: data?.npsSurvey?.name })
        } else {
          setCurrentNps({ id: -1, name: "Desabilitado" })
        }
        setUser((prevState) => {
          return { ...prevState, ...data };
        });

        const userQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(userQueueIds);
      } catch (err) {
        toastError(err);
      }
    };
    const fetchNps = async () => {
      if (!open) return;
      try {
        const { data } = await api.get("/npsSurvey");
        let customNpsList = data?.npsSurveys
        customNpsList = customNpsList.map((c) => ({ id: c.id, name: c.name }));
        if (isArray(customNpsList)) {
          setNpss([{ id: -1, name: "Desabilitado" }, ...customNpsList]);
        }
      } catch (err) {
        toastError(err);
      }
    };
    fetchNps();
    fetchUser();
  }, [userId, open]);

  const handleClose = () => {
    onClose();
    setUser(initialState);
  };

  const handleSaveUser = async (values) => {
    const userData = { ...values, queueIds: selectedQueueIds, npsSurveyId: currentNps?.id };
    //console.log(values);
    try {
      if (userId) {
        await api.put(`/users/${userId}`, userData);
      } else {
        await api.post("/users", userData);
      }
      toast.success(i18n.t("userModal.success"));
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {userId
            ? `${i18n.t("userModal.title.edit")}`
            : `${i18n.t("userModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveUser(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine} >
                  <div style={{ width: 350, margin: 12 }}>
                    <Field
                      as={TextField}
                      label={i18n.t("userModal.form.name")}
                      autoFocus
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                  <div style={{ width: 220, margin: 12 }}>
                    <Field
                      as={TextField}
                      label={i18n.t("userModal.form.password")}
                      type="password"
                      name="password"
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>


                  <div style={{ width: 350, margin: 12 }}>
                    <Field
                      as={TextField}
                      label={i18n.t("userModal.form.email")}
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                  <div style={{ width: 220, margin: 12 }}>

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      margin="dense"
                    >
                      <Can
                        role={loggedInUser.profile}
                        perform="user-modal:editProfile"
                        yes={() => (
                          <>
                            <InputLabel id="profile-selection-input-label">
                              {i18n.t("userModal.form.profile")}
                            </InputLabel>

                            <Field
                              as={Select}
                              label={i18n.t("userModal.form.profile")}
                              name="profile"
                              labelId="profile-selection-label"
                              id="profile-selection"
                              required
                            >
                              <MenuItem value="admin">Admin</MenuItem>
                              <MenuItem value="user">User</MenuItem>
                            </Field>
                          </>
                        )}
                      />
                    </FormControl>

                  </div>
                  <div style={{ width: "100%" }}>
                    <Can
                      role={loggedInUser.profile}
                      perform="user-modal:editQueues"
                      yes={() => (
                        <QueueSelect
                          selectedQueueIds={selectedQueueIds}
                          onChange={(values) => setSelectedQueueIds(values)}
                        />
                      )}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  {loggedInUser.profile === "admin" ? <>
                    <div style={{ width: "50%", margin: 12 }}>
                      <TableContainer component={Paper}>
                        <Table size="small" >
                          <TableRow>
                            <TableCell align="center" colSpan={2} > <h2 style={{ padding: 0, margin: 0 }}>Permissões de usuário</h2></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell><b>Descrição</b></TableCell>
                            <TableCell><b>Definição</b></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Permite usuário desabilitar assinatura</TableCell>
                            <TableCell>

                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                margin="dense"
                              >
                                <Field
                                  as={Select}
                                  name="allowSignature"
                                  labelId="allowSignature-selection-label"
                                  id="allowSignature-selection"
                                >
                                  <MenuItem value={true}>Sim</MenuItem>
                                  <MenuItem value={false}>Não</MenuItem>
                                </Field>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Permite visualizar tickets sem fila</TableCell>
                            <TableCell>

                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                margin="dense"
                              >
                                <Field
                                  as={Select}
                                  name="allowNoQueue"
                                  labelId="allowNoQueue-selection-label"
                                  id="allowNoQueue-selection"
                                >
                                  <MenuItem value={1}>Sim</MenuItem>
                                  <MenuItem value={0}>Não</MenuItem>
                                </Field>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{
                              i18n.t("userModal.form.allowViewClosedTickets")}
                            </TableCell>
                            <TableCell>

                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                margin="dense"
                              >
                                <Field
                                  as={Select}
                                  name="allowViewCloseTickets"
                                >
                                  <MenuItem value={"all"}>Todos</MenuItem>
                                  <MenuItem value={"user"}>Próprios</MenuItem>
                                  <MenuItem value={"none"}>Nenhum</MenuItem>
                                </Field>

                              </FormControl>
                            </TableCell>
                          </TableRow>



                          <TableRow>
                            <TableCell>{i18n.t("userModal.form.allowViewPendingTickets")}</TableCell>
                            <TableCell>

                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                margin="dense"
                              >
                                <Field
                                  as={Select}
                                  name="allowViewPendingTickets"
                                  id="allowViewPendingTickets-selection"
                                >
                                  <MenuItem value={1}>Sim</MenuItem>
                                  <MenuItem value={0}>Não</MenuItem>
                                </Field>
                              </FormControl>
                            </TableCell>
                          </TableRow>


                          <TableRow>
                            <TableCell>{i18n.t("userModal.form.allowTicketExport")}</TableCell>
                            <TableCell>

                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                margin="dense"
                              >
                                <Field
                                  as={Select}

                                  name="allowTicketExport"

                                  id="allowTicketExport-selection"

                                >
                                  <MenuItem value={1}>Sim</MenuItem>
                                  <MenuItem value={0}>Não</MenuItem>
                                </Field>
                              </FormControl>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>{i18n.t("userModal.form.allowEnableGreeting")}</TableCell>
                            <TableCell>

                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                margin="dense"
                              >
                                <Field
                                  as={Select}
                                  name="allowEnableGreeting"
                                  id="allowEnableGreeting-selection"
                                >
                                  <MenuItem value={1}>Sim</MenuItem>
                                  <MenuItem value={0}>Não</MenuItem>
                                </Field>

                              </FormControl>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <div>
                                {i18n.t("userModal.form.classificationPermission")}
                              </div>
                            </TableCell>
                            <TableCell>

                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                margin="dense"
                              >
                                <Field
                                  as={Select}
                                  name="classificationPermission"
                                  id="classificationPermission-selection"
                                  fullWidth
                                >
                                  <MenuItem value={"disabled"}>Desabilitado</MenuItem>
                                  <MenuItem value={"optional"}>
                                    Opcional Classificar
                                  </MenuItem>
                                  <MenuItem value={"optional_S"}>
                                    Opcional Classificar e Subclassificar{" "}
                                  </MenuItem>
                                  <MenuItem value={"required"}>
                                    Obrigatório Classificar
                                  </MenuItem>
                                  <MenuItem value={"required_S"}>
                                    Obrigatório Classificar e Subclassificar
                                  </MenuItem>
                                </Field>

                              </FormControl>
                            </TableCell>
                          </TableRow>
                        </Table>
                      </TableContainer>

                    </div>
                  </> : null}


                  {(loggedInUser.allowEnableGreeting || loggedInUser.profile === "admin") ?
                    <div style={{ width: "50%", marginLeft: 6, marginRight: 6 }}>
                      <TableContainer component={Paper}>
                        <Table size="small" >
                          <TableRow>
                            <TableCell align="center" colSpan={2} > <h2 style={{ padding: 0, margin: 0 }}>Recursos de usuário</h2></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell><b>Descrição</b></TableCell>
                            <TableCell><b>Definição</b></TableCell>
                          </TableRow>
                          {(loggedInUser.allowEnableGreeting || loggedInUser.profile === "admin") ?
                            <>

                              <TableRow>
                                <TableCell>{i18n.t("Habilitar assinatura")}</TableCell>
                                <TableCell>
                                  <FormControl
                                    variant="outlined"
                                    style={{ width: 240 }}
                                    className={classes.formControlEnd}
                                    margin="dense"
                                  >
                                    <Field
                                      as={Select}
                                      name="enableSignature"
                                      id="enableSignature-selection"
                                    >

                                      <MenuItem value={true}>Sim</MenuItem>
                                      <MenuItem value={false}>Não</MenuItem>
                                    </Field>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>{i18n.t("userModal.form.enableGreeting")}</TableCell>
                                <TableCell>
                                  <FormControl
                                    variant="outlined"
                                    style={{ width: 240 }}
                                    className={classes.formControlEnd}
                                    margin="dense"
                                  >
                                    <Field
                                      as={Select}
                                      name="enableGreeting"
                                      id="enableGreeting-selection"
                                    >

                                      <MenuItem value={0}>Desabilitado</MenuItem>
                                      <MenuItem value={1}>Contatos</MenuItem>
                                      <MenuItem value={2}>Grupos</MenuItem>
                                      <MenuItem value={3}>Contatos e Grupos</MenuItem>
                                    </Field>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>{i18n.t("userModal.form.enableTransfer")}</TableCell>
                                <TableCell>
                                  <FormControl
                                    variant="outlined"
                                    style={{ width: 240 }}
                                    className={classes.formControlEnd}
                                    margin="dense"
                                  >
                                    <Field
                                      as={Select}
                                      name="enableTransfer"
                                      id="enableTransfer-selection"
                                    >
                                      <MenuItem value={0}>Desabilitado</MenuItem>
                                      <MenuItem value={1}>Contatos</MenuItem>
                                      <MenuItem value={2}>Grupos</MenuItem>
                                      <MenuItem value={3}>Contatos e Grupos</MenuItem>
                                    </Field>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>{i18n.t("userModal.form.enableFarewell")}</TableCell>
                                <TableCell>
                                  <FormControl
                                    variant="outlined"
                                    style={{ width: 240 }}
                                    className={classes.formControlEnd}
                                    margin="dense"
                                  >
                                    <Field
                                      as={Select}
                                      name="enableFarewell"
                                      id="enableFarewell-selection"
                                    >
                                      <MenuItem value={0}>Desabilitado</MenuItem>
                                      <MenuItem value={1}>Contatos</MenuItem>
                                      <MenuItem value={2}>Grupos</MenuItem>
                                      <MenuItem value={3}>Contatos e Grupos</MenuItem>
                                    </Field>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            </>
                            : null}
                          {loggedInUser.profile === "admin" ?

                            <TableRow>
                              <TableCell>Pequisa de satisfação NPS</TableCell>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: 240 }}
                                  className={classes.formControlEnd}
                                  margin="dense"
                                >
                                  <Autocomplete
                                    size="small"
                                    fullWidth
                                    value={currentNps}
                                    options={npss}
                                    required
                                    onChange={(e, nps) => {
                                      setCurrentNps(nps ? nps : initialState.initialNps);
                                    }}
                                    getOptionLabel={(nps) => nps.name}
                                    getOptionSelected={(nps, value) => {
                                      return value.id === nps.id;
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"

                                        placeholder="Pesquisa NPS"
                                      />
                                    )}
                                  />
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            : null}




                        </Table>
                      </TableContainer>
                    </div>
                    : null}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {userId
                    ? `${i18n.t("userModal.buttons.okEdit")}`
                    : `${i18n.t("userModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default UserModal;
