//caixaaqui@primecca.com.br 47999285938

import {
  Box,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
} from "@material-ui/core";
import { Label } from "@radix-ui/react-dropdown-menu";
import React, { useRef } from "react";
import { Chart } from "react-google-charts";
import _ from "lodash";
import ReactToExcel from "react-html-table-to-excel";
import { makeStyles } from "@material-ui/core/styles";
import { useReactToPrint } from "react-to-print";
import { PrintOutlined } from "@material-ui/icons";
import Classifications from "./classifications";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  buttonExportToExcel: {
    boxShadow: "rgb(62, 115, 39) 0px 10px 14px -7px",
    background:
      "linear-gradient(rgb(119, 181, 90) 5%, rgb(114, 179, 82) 100%) rgb(119, 181, 90)",
    borderRadius: "4px",
    border: "1px solid rgb(75, 143, 41)",
    display: "inline-block",
    cursor: "pointer",
    color: "rgb(255, 255, 255)",
    fontFamily: "Arial",
    fontSize: "13px",
    fontWeight: "bold",
    padding: "14px 17px",
    textDecoration: "none",
    textShadow: "rgb(91, 138, 60) 0px 1px 0px",
    "&:hover": {
      background:
        "linear-gradient(rgb(18, 89, 37) 5%, rgb(92, 191, 42) 100%) rgb(18, 89, 37)",
    },
  },
  dataReport: {
    border: "solid 2px gray",
    borderRadius: 16,
    padding: 12,
    marginTop: 16,
  },
  buttonExportToPdf: {
    marginLeft: "12px",
    boxShadow: "rgb(207, 134, 108) 0px 1px 0px 0px inset",
    background:
      "linear-gradient(rgb(208, 69, 27) 5%, rgb(188, 51, 21) 100%) rgb(208, 69, 27)",
    borderRadius: "4px",
    border: "1px solid rgb(75, 143, 41)",
    display: "inline-block",
    cursor: "pointer",
    color: "rgb(255, 255, 255)",
    fontFamily: "Arial",
    fontSize: "13px",
    fontWeight: "bold",
    padding: "8px 17px",
    textDecoration: "none",
    textShadow: "rgb(133, 70, 41) 0px 1px 0px",
    "&:hover": {
      background:
        "linear-gradient(rgb(110, 84, 77) 5%, rgb(188, 51, 21) 100%) rgb(110, 84, 77)",
    },
  },
  newPage: {
    pageBreakBefore: "always",
    marginTop: "20mm",
  },
  page: {
    margin: "18mm",
    border: "0",
  },
  reportData: {
    backgroundColor: "white",
  },
}));

export const optionsPie = {
  title: "Tickets por atendente",
  is3D: true,
  width: "100%",
  height: 260,
  backgroundColor: "transparent",
};

export const optionsPieQueue = {
  title: "Classificações",
  is3D: true,
  width: "100%",
  height: 260,
  backgroundColor: "transparent",
};

export const optionsBar = {
  title: "Tickets por atendente",
  is3D: true,
  width: "90%",
  height: 180,
  chartArea: {
    backgroundColor: "#fcfcfc",
  },
};

export const optionsBarQueue = {
  title: "Tickets por Fila",
  is3D: true,
  width: "100%",
  height: 180,
  chartArea: {
    backgroundColor: "#fcfcfc",
  },
};

const loadChartByClassification = (data) => {
  const values = _.groupBy(
    data,
    (value) => value?.categoryClassification?.name
  );
  const result = _.map(values, (value, key) => {
    return [key, parseFloat(value.length)];
  });
  return [["Classificação", ""], ...result];
};
const loadChartByUser = (data) => {
  const values = _.groupBy(data, (value) => value?.user?.name);
  const result = _.map(values, (value, key) => {
    return [key, parseFloat(value.length)];
  });
  return [["Usuário", "Usuário"], ...result];
};

const loadGroupByUser = (data) => {
  const values = _.groupBy(data, (value) => value?.user?.name);
  return values;
};
const loadGroupByClassification = (data) => {
  const values = _.groupBy(
    data,
    (value) => value?.categoryClassification?.name
  );
  return values;
};

export const ReportClassificationData = ({ data }) => {
  const objetoClassifications = loadGroupByClassification(
    data.report.classifications
  );
  const totalClassificationCount = data.report.classifications.length;
  let reportByClassifications = [];
  for (const chave in objetoClassifications) {
    const arrayDeObjetos = objetoClassifications[chave];
    reportByClassifications.push({
      name: chave,
      qtd: arrayDeObjetos.length,
      percentage: (arrayDeObjetos.length / totalClassificationCount) * 100,
    });
  }

  const objetoUsers = loadGroupByUser(data.report.classifications);
  const totalUserCount = data.report.classifications.length;
  let reportByUsers = [];
  for (const chave in objetoUsers) {
    const arrayDeObjetos = objetoUsers[chave];
    reportByUsers.push({
      name: chave,
      qtd: arrayDeObjetos.length,
      percentage: (arrayDeObjetos.length / totalUserCount) * 100,
    });
  }
//console.log(reportByUsers);

  //return(<></>)
  const componentRef = useRef();
  const classes = useStyles();
  let filter = { ...data.filter };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <Grid item xs={10}>
        <Box marginBottom={3}>
          <ReactToExcel
            className={classes.buttonExportToExcel}
            table="newReport"
            filename={
              "Relatório_período_" +
              filter.initialDate
                .toLocaleString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/") +
              "_a_" +
              filter.finalDate
                .toLocaleString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/")
            }
            sheet="relatório"
            buttonText="EXPORTAR PARA EXCEL"
          />
          <Button
            className={classes.buttonExportToPdf}
            onClick={handlePrint}
            endIcon={<PrintOutlined></PrintOutlined>}
          >
            IMPRIMIR
          </Button>
        </Box>
      </Grid>
      <div style={{ display: "none" }}>
        <Table
          border="1"
          id="newReport"
          cellPadding={"25"}
          cellSpacing={"8"}
          align="center"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={8} align="center">
                <h2>Relatório de Classificação</h2>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} align="center">
                Data do relatório
              </TableCell>
              <TableCell colSpan={1} align="center">
                Data Inicial
              </TableCell>
              <TableCell colSpan={1} align="center">
                Data Final
              </TableCell>
              <TableCell colSpan={1} align="center">
                Atendente
              </TableCell>
              <TableCell colSpan={1} align="center">
                Fila
              </TableCell>
              <TableCell colSpan={2} align="center">
                Total de Classificações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} align="center">
                {moment().format("DD/MM/YYYY")}
              </TableCell>
              <TableCell colSpan={1} align="center">
                {moment(filter.initialDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell colSpan={1} align="center">
                {moment(filter.finalDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell colSpan={1} align="center">
                {filter.currentUser.name}
              </TableCell>
              <TableCell colSpan={1} align="center">
                {filter.currentQueue.name}
              </TableCell>
              <TableCell colSpan={2} align="center">
                {totalClassificationCount}
              </TableCell>

              <TableRow>
                <TableCell colSpan={8} align="center"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <h3>Relatório por Classificação</h3>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="center">
                  Classificação
                </TableCell>
                <TableCell colSpan={2} align="center">
                  Qt.classificações
                </TableCell>
                <TableCell colSpan={2} align="center">
                  {" "}
                  % Classificações
                </TableCell>
              </TableRow>
              <TableBody>
                {reportByClassifications.map((row) => (
                  <TableRow
                    key={row?.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell colSpan={4} align="center">
                      {row?.name}
                    </TableCell>
                    <TableCell colSpan={2} align="center">
                      {row?.qtd}
                    </TableCell>
                    <TableCell colSpan={2} align="center">
                      {row?.percentage.toFixed(2)} %
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell colSpan={8} align="center"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <h3>Relatório por Usuário</h3>
                  </TableCell>
                </TableRow>
              </TableBody>
            </TableRow>

            <TableRow>
              <TableCell colSpan={4} align="center">
                Usuário
              </TableCell>
              <TableCell colSpan={2} align="center">
                Qt.classificações
              </TableCell>
              <TableCell colSpan={2} align="center">
                {" "}
                % Classificações
              </TableCell>
            </TableRow>

            <TableBody>
              {reportByUsers.map((row) => (
                <TableRow
                  key={row?.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell colSpan={4} align="center">
                    {row?.name}
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    {row?.qtd}
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    {row?.percentage.toFixed(2)} %
                  </TableCell>
                </TableRow>
              ))}

              {data?.report?.classifications ? (
                <>
                  <TableRow>
                    <TableCell colSpan={8} align="center"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <h3>Classificações</h3>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">{"Classificação"}</TableCell>
                    <TableCell align="center">{"Subclassificação"}</TableCell>
                    <TableCell colSpan={2} align="center">
                      {"Observação"}
                    </TableCell>
                    <TableCell colSpan={2} align="center">
                      <b>{"Atendente"}</b> <br /> {"Fila"}
                    </TableCell>
                    <TableCell align="center">{"Conexão"}</TableCell>
                    <TableCell align="center">{"Contato"}</TableCell>
                  </TableRow>
                  <>
                    {data?.report?.classifications?.map((classification) => (
                      <TableRow key={classification.id}>
                        <TableCell align="center">
                          {classification?.categoryClassification?.name}
                        </TableCell>
                        <TableCell align="center">
                          {classification?.subCategoryClassification?.name}
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                          {classification?.description}
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                          <b>{classification?.user?.name}</b>
                          <br />
                          {classification?.queue?.name}
                        </TableCell>
                        <TableCell align="center">
                          {classification?.whatsapp?.name}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                          console.log(classification?.ticket?.uuid)
                          }
                          align="center"
                        >
                          {classification?.contact?.name} <br />
                          {"'" + classification?.contact?.number}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                </>
              ) : null}
            </TableBody>
          </TableBody>
        </Table>
      </div>

      <Grid item xs={10} ref={componentRef} className={classes.page}>
        <div className={classes.reportData}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Data de hoje:</Label>

                {new Date()
                  .toLocaleString()
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Data Inicial:</Label>
                {filter.initialDate
                  .toLocaleString()
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Data Final:</Label>
                {filter.finalDate
                  .toLocaleString()
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Atendente:</Label>
                {filter.currentUser.name}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Fila:</Label>
                {filter.currentQueue.name}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Total de Classificações:</Label>
                {totalClassificationCount}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container className={classes.dataReport} spacing={1}>
                <hr></hr>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <h3>Relatório por Classificação</h3>
                    <hr></hr>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table striped bordered hover size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Classificação</TableCell>
                          <TableCell align="center">
                            Qt.classificações
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            % Classificações
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportByClassifications.map((row) => (
                          <TableRow
                            key={row?.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">{row?.name}</TableCell>
                            <TableCell align="center">{row?.qtd}</TableCell>
                            <TableCell align="center">
                              {row?.percentage.toFixed(2)} %
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <hr></hr>
                    </Grid>
                  </Grid>

                  <Grid item align="center" xs={12}>
                    <div
                      style={{
                        margin: "auto",
                        width: "50%",
                        paddingRight: "100px",
                      }}
                    >
                      <Chart
                        chartType="PieChart"
                        data={loadChartByClassification(
                          data.report.classifications
                        )}
                        options={optionsPieQueue}
                      />
                      <Chart
                        chartType="Bar"
                        data={loadChartByClassification(
                          data.report.classifications
                        )}
                        options={optionsBarQueue}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.newPage}></div>
              <Grid container className={classes.dataReport} spacing={1}>
                <hr></hr>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <h3>Relatório por Usuário</h3>
                    <hr></hr>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table striped bordered hover size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Usuário</TableCell>
                          <TableCell align="center">
                            Qt.classificações
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            % Classificações
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportByUsers.map((row) => (
                          <TableRow
                            key={row?.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">{row?.name}</TableCell>
                            <TableCell align="center">{row?.qtd}</TableCell>
                            <TableCell align="center">
                              {row?.percentage.toFixed(2)} %
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <hr></hr>
                    </Grid>
                  </Grid>

                  <Grid item align="center" xs={12}>
                    <div
                      style={{
                        margin: "auto",
                        width: "50%",
                        paddingRight: "100px",
                      }}
                    >
                      <Chart
                        chartType="PieChart"
                        data={loadChartByUser(data.report.classifications)}
                        options={optionsPieQueue}
                      />
                      <Chart
                        chartType="Bar"
                        data={loadChartByUser(data.report.classifications)}
                        options={optionsBarQueue}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.newPage}></div>
              <Grid className={classes.dataReport} item xs={12}>
                <Classifications
                  classifications={data.report.classifications}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};
