import React from "react";
import { Avatar, CardHeader, Tooltip, Typography } from "@material-ui/core";
import { parseISO, format, isSameDay, isYesterday } from "date-fns";
import { i18n } from "../../translate/i18n";
import { TruncateString } from "../../helpers/truncateString";


const TicketInfo = ({ contact, ticket, onClick }) => {
	return (
		<>
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer", maxWidth: 230 }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
				title={
					<p style={{ margin: 0 }}>
						<TruncateString body={contact?.name} length={25} />
					</p>
				}
				subheader={
					<>
						<p style={{ margin: 0 }}>
							{
								ticket.user &&
								`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
							}
						</p>
						<p style={{ margin: 0 }}>
							iniciado:
							{isSameDay(parseISO(ticket.createdAt), new Date()) ? (<>{format(parseISO(ticket.createdAt), "HH:mm")}</>) :
								isYesterday(parseISO(ticket.createdAt), new Date()) ? (<> Ontem {format(parseISO(ticket.createdAt), "HH:mm")}</>) :
									(
										<>{format(parseISO(ticket.createdAt), "dd/MM/yyyy HH:mm")}</>
									)
							}
						</p>
					</>

				}
			/>
		</>
	);
};

export default TicketInfo;
