import React from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";
import { Table, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import MainHeader from "../MainHeader";
import Title from "../Title";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    //height: "300px"
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  userForm: {
    width: "100%",
  },
  autoComplete: {
    padding: -10,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const MessageLogModal = ({ open, onClose, messagesLog }) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();

  };

  const handleReprocessMessage = async () => {
    //console.log("Reprocessar", messagesLog)

    try {
      if (messagesLog?.id) {
        console.log("Aqui")
        await api.post(`/reprocessMessage/${messagesLog?.id}`);
      }

      //toast.success(i18n.t("Mensagem enviada para processamento"));
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog

        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullScreen
        scroll="paper"
      >
        <div style={{ padding: 30 }} >
          <MainHeader>
            <Title>{i18n.t("Log de Mensagem Bruta ")}</Title>
            <MainHeaderButtonsWrapper>

              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                {i18n.t("Voltar")}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleReprocessMessage}
              >
                {i18n.t("Reprocessar mensagem")}
              </Button>
            </MainHeaderButtonsWrapper>
          </MainHeader>
          <Grid container spacing={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableRow>
                  <TableCell>DATA</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>remoteJid</TableCell>
                  <TableCell>messageId</TableCell>
                  <TableCell>fromMe</TableCell>
                  <TableCell>TicketId</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>WhatsappId</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{
                    moment(messagesLog?.createdAt).format("DD-MM-YY HH:mm:ss")
                  }
                  </TableCell>
                  <TableCell>{messagesLog?.id}</TableCell>
                  <TableCell>{messagesLog?.remoteJid}</TableCell>
                  <TableCell>{messagesLog?.messageId}</TableCell>
                  <TableCell>{messagesLog?.fromMe ? "fromMe" : ""}</TableCell>
                  <TableCell>{messagesLog?.ticketId}</TableCell>
                  <TableCell>{messagesLog?.messageType}</TableCell>
                  <TableCell>{messagesLog?.whatsappId}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Grid>
          <Grid style={{ marginTop: 35 }} container>


            <Grid item xs={12}>
              <hr></hr>
            </Grid>
            <Grid item xs={12}>
              <div style={{width:"100%",overflow:"hidden"}} >
              <pre>{messagesLog?.jsonValue ? JSON.stringify(JSON.parse(messagesLog?.jsonValue),null,2):null}</pre>
              </div>
            </Grid>

          </Grid>

        </div>
      </Dialog>
    </div>
  );
};

export default MessageLogModal;
