import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import QueueSelectSingle from "../QueueSelectSingle";
import UserSelect from "../UserSelect";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import CommandTypeSelect from "../CommandTypeSelect";
import UserListSelect from "../UserListSelect";
import AutoCompleteGeneric from "../AutoCompleteGeneric";
import { IconButton, Table, TableCell, TableRow } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const CommandSchema = Yup.object().shape({
	commandBot: Yup.string()
		.min(1, "Muito curto!")
		.max(50, "Muito longo!")
		.required("Obrigatório"),
	commandType: Yup.number(),
});

const CommandModal = ({ open, onClose, commandId }) => {
	const classes = useStyles();

	const initialState = {
		commandBot: "",
		commandType: "",
		descriptionBot: "",
		showMessage: "",
		userId: null,
		queueId: null,
	};
	const initialUser = {
		name: "",
		id: ""
	}

	const [showFields, setShowFields] = useState({ queues: false, users: false, message: false, descriptionBot: false, commandType: "", userList: false, });
	const [command, setCommand] = useState(initialState);
	const [currentUser, setCurrentUser] = useState(initialUser);
	const [loading, setLoading] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [limitTicketsPorUsuario, setLimitTicketsPorUsuario] = useState(1000);
	const greetingRef = useRef();

	

	useEffect(() => {
		const fetchCommand = async () => {
			setShowFields({ queues: false, users: false, message: false, descriptionBot: false, commandType: "" });
			//setSelectedCommandTypeId("");
			if (!commandId) return;
			try {
				setLoading(true)
				const { data } = await api.get(`/bot/${commandId}`);
				if(data?.users){
					const transformedUsers = data?.users.map(user => ({
						id: user.id,
						name: user.name,
						limiteTickets: user.UsersBotList.limiteTickets,
						createdAt: user.UsersBotList.createdAt,
						updatedAt: user.UsersBotList.updatedAt
					  }));
					  setSelectedUsers(transformedUsers)

				}
				setCommand(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.users?.map((user) => user.id);
				//setSelectedUserIds(userQueueIds);

				switch (data.commandType) {
					case 1:
						setShowFields({ queues: false, users: false, message: true, descriptionBot: true, commandType: data.commandType });
						break;
					case 2:
						setShowFields({ queues: false, users: false, message: true, descriptionBot: true, commandType: data.commandType });
						break;
					case 3:
						setShowFields({ queues: true, users: false, message: true, descriptionBot: true, commandType: data.commandType });
						break;
					case 4:
						setShowFields({ queues: true, users: true, message: true, descriptionBot: true, commandType: data.commandType });
						break;
					case 5:
						setShowFields({ queues: false, users: false, message: true, descriptionBot: true, commandType: data.commandType });
						break;
					case 6:
						setShowFields({ queues: true, users: false, userList: true, message: true, descriptionBot: true, commandType: data.commandType });
						break;
					default:
						setShowFields({ queues: false, users: false, message: true, descriptionBot: false, commandType: data.commandType });
				}
				setLoading(false)
			} catch (err) {
				console.error(err)
				toastError(err);
			}
		};

		fetchCommand();
	}, [commandId, open]);

	const handleClose = () => {
		onClose();
		setCommand(initialState);
		setSelectedUsers([])
	};


	const addOrEditUser = (newUser) => {
		// Verifique se o novo usuário já existe na lista com base no id
		const index = selectedUsers.findIndex(user => user.id === newUser.id);

		// Se o usuário não existir na lista, adicione-o
		if (index === -1) {
			setSelectedUsers([...selectedUsers, newUser,]);
		} else {
			// Se o usuário já existir na lista, edite-o
			const updatedUsers = [...selectedUsers];
			updatedUsers[index] = { ...updatedUsers[index], ...newUser };
			setSelectedUsers(updatedUsers);
		}
	};

	const deleteUser = (id) => {
		// Filtrar o array para excluir o item com o id fornecido
		const updatedUsers = selectedUsers.filter(user => user.id !== id);
		// Atualizar o estado com o novo array filtrado
		setSelectedUsers(updatedUsers);
	  };

	const handleEdit = (user, newData) => {
		// Encontre o índice do item com o id fornecido
		setCurrentUser(user)
	};




	const handleSaveCommand = async values => {
		const commandData = { ...values, selectedUsers };
		try {
			commandData.commandType = showFields.commandType;
			//console.log(('Dados => ', commandData);
			if (commandId) {
				await api.put(`/bot/${commandId}`, commandData);
			} else {
				await api.post("/bot", commandData);
			}
			toast.success(i18n.t("botModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (

		<>
			{!loading &&
				<div className={classes.root}>
					<Dialog
						open={open}
						onClose={handleClose}
						maxWidth="lg"
						fullWidth
						scroll="paper"
					>
						<DialogTitle id="form-dialog-title">
							{commandId
								? `${i18n.t("botModal.title.edit")}`
								: `${i18n.t("botModal.title.add")}`}
						</DialogTitle>
						<Formik
							initialValues={command}
							enableReinitialize={true}
							validationSchema={CommandSchema}
							onSubmit={(values, actions) => {
								setTimeout(() => {
									handleSaveCommand(values);
									actions.setSubmitting(false);
								}, 400);
							}}
						>
							{({ touched, errors, isSubmitting }) => (
								<Form>
									<DialogContent dividers>
										<div style={{ display: "flex" }} >
											<div style={{ minWidth: 200 }}>
												<Field
													as={TextField}
													label={i18n.t("botModal.form.commandBot")}
													autoFocus
													name="commandBot"
													error={touched.commandBot && Boolean(errors.commandBot)}
													helperText={touched.commandBot && errors.commandBot}
													variant="outlined"
													margin="dense"
													fullWidth
												/>
											</div>

											<CommandTypeSelect
												selectedCommandId={showFields.commandType}
												onChange={values => setShowFields(values)}
											/>
										</div>
										{showFields.descriptionBot && <Field
											as={TextField}
											label={i18n.t("botModal.form.descriptionBot")}
											name="descriptionBot"
											error={touched.descriptionBot && Boolean(errors.descriptionBot)}
											helperText={touched.descriptionBot && errors.descriptionBot}
											variant="outlined"
											margin="dense"
											fullWidth
										/>}
										{showFields.users && <UserSelect />}
										{showFields.userList &&

											<div>
												<div style={{ display: "flex", border: "solid 2px #e9e9e9 ", borderRadius: 8, padding: 12, gap: 10 }}>
													<AutoCompleteGeneric
														style={{ minWidth: 250 }}
														name="users"
														label={i18n.t(
															"Atendentes"
														)}
														route={"users"}
														value={currentUser}
														onChange={(values) => setCurrentUser(values)}
													/>
													<div style={{ width: 130 }}>
														<Field
															name="limitDeTickets"
															variant="outlined"
															margin="dense"
															component={TextField}
															label={i18n.t("Limite de tickets")}
															type="number"
															InputProps={{ inputProps: { min: 0, max: 1000 } }}
															value={limitTicketsPorUsuario}
															onChange={(e) => setLimitTicketsPorUsuario(e.target.value)}
															fullWidth
														/>
													</div>
													<div style={{ width: 130, marginTop: 10 }}>
														<Button
															variant="contained"
															color="primary"
															size="medium"
															onClick={() => addOrEditUser({ ...currentUser, limiteTickets: limitTicketsPorUsuario })}
														>
															{!selectedUsers.some(user => user?.id === currentUser?.id) ? "Adicionar" : "Editar"}

														</Button>
													</div>

													{selectedUsers?.length ?
														<div>
															<Table>
																<TableRow>
																	<TableCell>Nome</TableCell>
																	<TableCell>Limite de tickets</TableCell>
																	<TableCell>Ações</TableCell>

																</TableRow>
																{selectedUsers.map((row) => (
																	<TableRow key={row?.id}>
																		<TableCell>{row?.name}</TableCell>
																		<TableCell>{row?.limiteTickets}</TableCell>
																		<TableCell>
																			<IconButton 
																			onClick={() => handleEdit(row)}
																			>
																				<Edit/>
																			</IconButton>
																			<IconButton 
																			onClick={() => deleteUser(row.id)}
																			>
																				<Delete/>
																			</IconButton>
																	</TableCell>

																	</TableRow>


																))}

															</Table>
														</div>
														: null}


												</div>
											</div>

										}
										{showFields.queues && <QueueSelectSingle />}
										{showFields.message && <Field
											as={TextField}
											label={i18n.t("botModal.form.showMessage")}
											name="showMessage"
											multiline
											inputRef={greetingRef}
											minRows={5}
											fullWidth
											error={touched.showMessage && Boolean(errors.showMessage)}
											helperText={touched.showMessage && errors.showMessage}
											variant="outlined"
											margin="dense"
										/>}
									</DialogContent>
									<DialogActions>
										<Button
											onClick={handleClose}
											color="secondary"
											disabled={isSubmitting}
											variant="outlined"
										>
											{i18n.t("botModal.buttons.cancel")}
										</Button>
										<Button
											type="submit"
											color="primary"
											disabled={isSubmitting}
											variant="contained"
											className={classes.btnWrapper}
										>
											{commandId
												? `${i18n.t("botModal.buttons.okEdit")}`
												: `${i18n.t("botModal.buttons.okAdd")}`}
											{isSubmitting && (
												<CircularProgress
													size={24}
													className={classes.buttonProgress}
												/>
											)}
										</Button>
									</DialogActions>
								</Form>
							)}
						</Formik>
					</Dialog>
				</div>
			}
		</>

	);
};

export default CommandModal;
