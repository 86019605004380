import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



const DashStatusGeral = (props) => {
    const {
        title,
        data

    } = props;
    let reportData = {...data}.reportData
    //console.log("DashSkeleton");
    //console.log(reportData)
    return (
        <table border="1" id="table-to-xls"  style={{ width: "100%", border: '0' }} cellPadding={"5"} align="center">
            <tr>
                <th>Aguardando Atendimento</th>
                <th>Em Atendimento</th>
                <th>Fechados</th>
                <th>Total</th>
            </tr>
            <tr>
                <td align="center">{reportData?.countPending}</td>
                <td align="center">{reportData?.countOpenGeneral}</td>
                <td align="center">{reportData?.countClosed}</td>
                <td align="center">{reportData?.totalTickets}</td>
            </tr>
            <tr>
            </tr>
        </table>
    )
}

export default DashStatusGeral;