import React from "react";
import { Chart } from "react-google-charts";
import { Box } from "@material-ui/core";
import _ from 'lodash'
import { Grid } from "@mui/material";

export const optionsPie = {
    title: "Tickets em atendimento",
    is3D: true,
    width: 450,
    height: 350,
    backgroundColor: 'transparent',
}
export const optionsPieClosed = {
    title: "Tickets Fechados",
    is3D: true,
    width: 450,
    height: 350,
    backgroundColor: 'transparent',
}



const loadChartUser = (data) => {
    const values = _.groupBy(data, (value) => value.name);
    const result = _.map(values, (value, key) => {
        return [
            key,
            parseFloat(value[0].countOpen)
        ]
    });
    return [
        ["Ticket", "Usuário"], ...result,];
}
const loadChartUserClosed = (data) => {
    const values = _.groupBy(data, (value) => value.name);
    const result = _.map(values, (value, key) => {
        return [
            key,
            parseFloat(value[0].countClosed)
        ]
    });
    return [
        ["Ticket", "Usuário"], ...result,];
}

const DashStatusByUser = (props) => {
    const {
        title,
        data

    } = props;
    let reportData = { ...data }.reportData
    //console.log("DashSkeleton");
    //console.log(reportData)
    return (
        <table border="1" id="table-to-xls" style={{ width: "100%", border: '0' }} cellPadding={"5"} align="center">
            <tr>
                <th>Nome</th>
                <th bgcolor="#f0f0f5">Em Atendimento</th>
                <th bgcolor="#c2c2d6">Fechados</th>
                <th>Total</th>
                <th>TMA</th>
                <th>TME</th>
            </tr>
            {reportData?.dataUsers.map((row) => (
                <tr
                    key={row.name}
                    sx={{}}
                >
                    <th align="center" ><b>{row.name}:</b></th>

                    <th align="center" bgcolor="#f0f0f5"> <b>{row.countOpen}</b></th>

                    <th align="center" bgcolor="#c2c2d6" ><b>{row.countClosed}</b></th>

                    <th align="center"><b>{row.qtdAtendimentos}</b></th>

                    <th align="center"><b>{row.tma}</b></th>

                    <th align="center"><b>{row.tme}</b></th>

                </tr>
            ))}
            <tr>



                <th colSpan={7}>
                    <Grid container>
                        <Grid item sx={{
                            width: '50%',
                            height: 300,
                            backgroundColor: '#f0f0f5',

                        }}
                        >
                            <Chart
                                chartType="PieChart"
                                data={loadChartUser(reportData?.dataUsers)}
                                options={optionsPie}
                            />


                        </Grid>
                        <Grid item sx={{
                            width: '50%',
                            height: 300,
                            backgroundColor: '#c2c2d6'
                        }}
                        >
                            <Chart
                                chartType="PieChart"
                                data={loadChartUserClosed(reportData?.dataUsers)}
                                options={optionsPieClosed}
                            />


                        </Grid>
                    </Grid>
                </th>
            </tr>
        </table>
    )
}

export default DashStatusByUser;