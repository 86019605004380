import {
  Box,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
} from "@material-ui/core";
import { Label } from "@radix-ui/react-dropdown-menu";
import React, { useRef } from "react";
import { Chart } from "react-google-charts";
import _ from "lodash";
import ReactToExcel from "react-html-table-to-excel";
import { makeStyles } from "@material-ui/core/styles";
import { useReactToPrint } from "react-to-print";
import { PrintOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  buttonExportToExcel: {
    boxShadow: "rgb(62, 115, 39) 0px 10px 14px -7px",
    background:
      "linear-gradient(rgb(119, 181, 90) 5%, rgb(114, 179, 82) 100%) rgb(119, 181, 90)",
    borderRadius: "4px",
    border: "1px solid rgb(75, 143, 41)",
    display: "inline-block",
    cursor: "pointer",
    color: "rgb(255, 255, 255)",
    fontFamily: "Arial",
    fontSize: "13px",
    fontWeight: "bold",
    padding: "14px 17px",
    textDecoration: "none",
    textShadow: "rgb(91, 138, 60) 0px 1px 0px",
    "&:hover": {
      background:
        "linear-gradient(rgb(18, 89, 37) 5%, rgb(92, 191, 42) 100%) rgb(18, 89, 37)",
    },
  },
  buttonExportToPdf: {
    marginLeft: "12px",
    boxShadow: "rgb(207, 134, 108) 0px 1px 0px 0px inset",
    background:
      "linear-gradient(rgb(208, 69, 27) 5%, rgb(188, 51, 21) 100%) rgb(208, 69, 27)",
    borderRadius: "4px",
    border: "1px solid rgb(75, 143, 41)",
    display: "inline-block",
    cursor: "pointer",
    color: "rgb(255, 255, 255)",
    fontFamily: "Arial",
    fontSize: "13px",
    fontWeight: "bold",
    padding: "8px 17px",
    textDecoration: "none",
    textShadow: "rgb(133, 70, 41) 0px 1px 0px",
    "&:hover": {
      background:
        "linear-gradient(rgb(110, 84, 77) 5%, rgb(188, 51, 21) 100%) rgb(110, 84, 77)",
    },
  },
  newPage: {
    pageBreakBefore: "always",
    marginTop: "20mm",
  },
  page: {
    margin: "18mm",
    border: "0",
  },
  reportData: {
    backgroundColor: "white",
  },
}));

export const optionsPie = {
  title: "Tickets por atendente",
  is3D: true,
  width: "80%",
  height: 260,
  backgroundColor: "transparent",
};

export const optionsPieQueue = {
  title: "Tickets por Fila",
  is3D: true,
  width: "80%",
  height: 260,
  backgroundColor: "transparent",
};

export const optionsBar = {
  title: "Tickets por atendente",
  is3D: true,
  width: "100%",
  height: 180,
  chartArea: {
    backgroundColor: "#fcfcfc",
  },
};

export const optionsBarQueue = {
  title: "Tickets por Fila",
  is3D: true,
  width: "100%",
  height: 180,
  chartArea: {
    backgroundColor: "#fcfcfc",
  },
};

const loadChartUser = (data) => {
  //console.log(">>>>>>DATA>>>", data)
  const values = _.groupBy(data, (value) => value.name);
  const result = _.map(values, (value, key) => {
    return [key, parseFloat(value[0].qtdAtendimentos)];
  });
  //console.log(">>>>>>RETURN>>>", [["Ticket", "Usuário"], ...result])
  return [["Ticket", "Usuário"], ...result];
};
const loadChartQueue = (data) => {
  const values = _.groupBy(data, (value) => value.queue);
  const result = _.map(values, (value, key) => {
    return [key, parseFloat(value[0].qtdAtendimentos)];
  });
  return [["Ticket", "Filas"], ...result];
};

export const ReportAttendanceData = ({ data }) => {
  const componentRef = useRef();
  const classes = useStyles();
  let filter = { ...data.filter };
  let report = { ...data.report.reportData };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <Grid item xs={10}>
        <Box marginBottom={3}>
          <ReactToExcel
            className={classes.buttonExportToExcel}
            table="newReport"
            filename={
              "Relatório_período_" +
              filter.initialDate
                .toLocaleString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/") +
              "_a_" +
              filter.finalDate
                .toLocaleString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/")
            }
            sheet="relatório"
            buttonText="EXPORTAR PARA EXCEL"
          />
          <Button
            className={classes.buttonExportToPdf}
            onClick={handlePrint}
            endIcon={<PrintOutlined></PrintOutlined>}
          >
            IMPRIMIR
          </Button>
        </Box>
      </Grid>
      <div style={{ display: "none" }}>
        <table
          border="1"
          id="newReport"
          cellPadding={"25"}
          cellSpacing={"8"}
          align="center"
        >
          <caption>
            <h2>
              Indicadores de atendimentos{" "}
              {new Date()
                .toLocaleString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/")}
            </h2>
          </caption>

          <tr>
            <th colSpan={"2"}>Data Inicial</th>
            <th colSpan={"2"}>Data Final</th>
            <th colSpan={"2"}>Atendente</th>
            <th>Fila</th>
            <th>Origem</th>
          </tr>
          <tr>
            <td colSpan={"2"} align="center">
              {filter.initialDate
                .toLocaleString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/")}
            </td>
            <td colSpan={"2"} align="center">
              {filter.finalDate
                .toLocaleString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/")}
            </td>
            <td colSpan={"2"} align="center">
              {filter.currentUser.name}
            </td>
            <td align="center">{filter.currentQueue.name}</td>
            <td align="center">{filter.source === 2 ? <span>Receptivos</span> : <span>Ativos</span>}</td>
          </tr>
          <tr>
            <th colSpan={"8"}></th>
          </tr>
          <tr>
            <th colSpan={"2"}>Tickets Fechados</th>
            <th colSpan={"2"}>TAT</th>
            <th>Tma Total</th>
            <th>Tickets Respondidos</th>
            {filter.source === 2 ?
              <th>TET</th>
              : null}
            {filter.source === 2 ?
              <th colSpan={"1"}>Tme Total</th>
              : null}


          </tr>
          <tr>
            <td colSpan={"2"} align="center">
              <h2>{report?.totalTickets}</h2>
            </td>
            <td colSpan={"2"}>
              <h2>{report?.tmaTotalMoment}</h2>
            </td>
            <td>
              <h2>{report?.tmaTotalMedioMoment}</h2>
            </td>
            <td>
              <h2>{report?.ticketsAnswered}</h2>
            </td>
            {filter.source === 2 ?
                        <td>
                        <h2>{report?.tmeTotalMoment}</h2>
                      </td>
              
              : null}
            {filter.source === 2 ?
              <td colSpan={"1"}>
                <h2>{report?.tmeTotalMedioMoment}</h2>
              </td>

              : null}

          </tr>
          <tr>
            <th colSpan={"8"}>
              <h2>Por Atendente</h2>
            </th>
          </tr>
          <TableHead>
            <TableRow>
              <TableCell align="center">Atendente</TableCell>
              <TableCell align="center">Ti. Fechados</TableCell>
              <TableCell align="center">Tickets %</TableCell>
              <TableCell align="center">TA</TableCell>
              <TableCell align="center">TMA</TableCell>
              <TableCell align="center">Ti. Respondidos</TableCell>
              {filter.source === 2 ?
                <TableCell align="center">TE</TableCell>

                : null}
              {filter.source === 2 ?
                <TableCell align="center">TME</TableCell>
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {report?.dataUsers.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.qtdAtendimentos}</TableCell>
                <TableCell align="center">
                  {row.porcentagemAtendimentos} %
                </TableCell>
                <TableCell align="center">{row.tmaTotalUser}</TableCell>
                <TableCell align="center">{row.tma}</TableCell>
                <TableCell align="center">{row.ticketsAnswered}</TableCell>
                {filter.source === 2 ?
                  <TableCell align="center">{row.tmeTotalUser}</TableCell>
                  : null}
                {filter.source === 2 ?
                  <TableCell align="center">{row.tme}</TableCell>
                  : null}
              </TableRow>
            ))}
            <tr>
              <th colSpan={"8"}>
                <h2>Por Fila</h2>
              </th>
            </tr>
            <TableHead>
              <TableRow>
                <th colSpan={"4"} align="center">
                  Fila
                </th>
                <th colSpan={"2"} align="center">
                  Quantidade
                </th>
                <th colSpan={"2"} align="center">
                  Quantidade %
                </th>
              </TableRow>
            </TableHead>
            <TableBody>
              {report?.dataQueuesWitchPercent.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <th colSpan={"4"} align="center">
                    {row.queue}
                  </th>
                  <th colSpan={"2"} align="center">
                    {row.qtdAtendimentos}
                  </th>
                  <th colSpan={"2"} align="center">
                    {row.percent} %
                  </th>
                </TableRow>
              ))}
            </TableBody>
          </TableBody>
        </table>
      </div>

      <Grid item xs={10} ref={componentRef} className={classes.page}>
        <div className={classes.reportData}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Relatório de Atendimento:</Label>

                {new Date()
                  .toLocaleString()
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Data Inicial:</Label>
                {filter.initialDate
                  .toLocaleString()
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Data Final:</Label>
                {filter.finalDate
                  .toLocaleString()
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Atendente:</Label>
                {filter.currentUser.name}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Fila:</Label>
                {filter.currentQueue.name}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Origin:</Label>
                {filter.source === 2 ? <span>Receptivos</span> : <span>Ativos</span>}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <hr></hr>
            </Grid>
          </Grid>
          <Box marginLeft={3} marginBottom={3}>
            <b>Tickets Atendidos por Usuários:</b>
          </Box>

          <Grid container spacing={6}>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Ti. Fechados</Label>

                <h2>{report?.totalTickets}</h2>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>TAT</Label>
                <h2>{report?.tmaTotalMoment}</h2>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>TMA Total</Label>
                <h2>{report?.tmaTotalMedioMoment}</h2>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign={"center"}>
                <Label>Ti. Respondidos</Label>
                <h2>{report?.ticketsAnswered}</h2>
              </Box>
            </Grid>

            {filter.source === 2 ?
              <Grid item xs={2}>
                <Box textAlign={"center"}>
                  <Label>TET</Label>
                  <h2>{report?.tmeTotalMoment}</h2>
                </Box>
              </Grid>
              : null}
            {filter.source === 2 ?
              <Grid item xs={2}>
                <Box textAlign={"center"}>
                  <Label>TME Total</Label>
                  <h2>{report?.tmeTotalMedioMoment}</h2>
                </Box>
              </Grid>
              : null}



          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <hr></hr>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TableContainer>
                <Table striped bordered hover size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Atendente</TableCell>
                      <TableCell align="center">Ti. Fechados</TableCell>
                      <TableCell align="center">Tickets %</TableCell>
                      <TableCell align="center">TA</TableCell>
                      <TableCell align="center">TMA</TableCell>
                      <TableCell align="center">Ti.Respondidos</TableCell>
                      {filter.source === 2 ?
                        <TableCell align="center">TE</TableCell>
                        : null}
                      {filter.source === 2 ?
                        <TableCell align="center">TME</TableCell>
                        : null}


                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report?.dataUsers.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">
                          {row.qtdAtendimentos}
                        </TableCell>
                        <TableCell align="center">
                          {row.porcentagemAtendimentos} %
                        </TableCell>
                        <TableCell align="center">{row.tmaTotalUser}</TableCell>
                        <TableCell align="center">{row.tma}</TableCell>
                        <TableCell align="center">
                          {row.ticketsAnswered}
                        </TableCell>
                        {filter.source === 2 ?
                          <TableCell align="center">{row.tmeTotalUser}</TableCell>
                          : null}
                        {filter.source === 2 ?
                          <TableCell align="center">{row.tme}</TableCell>
                          : null}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid item align="center" xs={10}>
                <Chart
                  chartType="PieChart"
                  data={loadChartUser(report?.dataUsers)}
                  options={optionsPie}
                />
              </Grid>

              <Grid item align="center" xs={9}>
                <Chart
                  chartType="Bar"
                  data={loadChartUser(report?.dataUsers)}
                  options={optionsBar}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.newPage} container spacing={1}>
            <Grid item xs={12}>
              <hr></hr>
            </Grid>
          </Grid>
          <Box marginLeft={3} marginBottom={3}>
            <b>Tickets Atendidos por Filas</b>
          </Box>

          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Fila</TableCell>
                    <TableCell align="center">Quantidade</TableCell>
                    <TableCell align="center">Quantidade %</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.dataQueuesWitchPercent.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{row.queue}</TableCell>
                      <TableCell align="center">
                        {row.qtdAtendimentos}
                      </TableCell>
                      <TableCell align="center">{row.percent} %</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item align="center" xs={10}>
            <Chart
              chartType="PieChart"
              data={loadChartQueue(report.dataQueuesWitchPercent)}
              options={optionsPieQueue}
            />
          </Grid>
          <Grid item align="center" xs={9}>
            <Chart
              chartType="Bar"
              data={loadChartQueue(report?.dataQueuesWitchPercent)}
              options={optionsBarQueue}
            />
          </Grid>
        </div>
      </Grid>
    </>
  );
};
