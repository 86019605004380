import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { green, yellow, blue } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import MarkdownWrapper from "../MarkdownWrapper";
import { Button, Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import Cronometro from "../Cronometro";
import { TruncateString } from "../../helpers/truncateString";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import api from "../../services/api";
const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 38,
    marginLeft: "auto",
  },
  badgeTicketClosed: {
    color: "#ffffff",
    textAlign: "center",
    backgroundColor: "#337ab7",
    width: "min-content",
    height: 16,
    overflow: "hidden",
    fontSize: 12,
    borderRadius: 2,
    paddingLeft: 8,
    paddingRight: 8,
    marginRight: 8,
    marginLeft: "auto",
  },
  badgeTicketOutHour: {
    color: "#ffffff",
    textAlign: "center",
    backgroundColor: "#337ab7",
    // width: "min-content",
    height: 16,
    overflow: "hidden",
    fontSize: 12,
    borderRadius: 2,
    paddingLeft: 8,
    paddingRight: 8,
    marginRight: 8,
    marginLeft: "auto",
  },
  badgeTicketUserAndConn: {
    color: "#ffffff",
    backgroundColor: "#595959",
    //width: "100%",
    maxWidth: "170px",
    marginTop: 3,
    height: 14,
    //textAlign:"right",
    overflow: "hidden",
    fontSize: 11,
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: 3,

    marginLeft: "auto",
  },


  contactLastMessage: {
    paddingRight: 20,
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
  },
  cronometro: {
    minWidth: 125,
  },
  queueSelect: {
    alignSelf: "center",
    marginRight: "auto",
    marginLeft: "auto",
  },

  green: {
    color: "white",
    backgroundColor: green[500],
  },
  yellow: {
    color: "white",
    backgroundColor: yellow[500],
  },
  blue: {
    color: "white",
    backgroundColor: blue[500],
  },

  acceptButton: {
    position: "absolute",
    left: "50%",
  },

  ticketSource: {
    flex: "none",
    width: "1px",
    //height: "20%",
    position: "absolute",
    top: "-6px",
    left: "-8px",
  },
  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "80%",
    position: "absolute",
    top: "20%",
    left: "0%",
  },
}));

const TicketListItem = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const [acceptTicketWithouSelectQueueOpen, setAcceptTicketWithouSelectQueueOpen] = useState(false);


  useEffect(() => {
    return () => {
      isMounted.current = false;
    };

  }, []);


  const handleSelectTicket = async (uuid) => {

    history.push(`/tickets/${uuid}`);
  };



  const handleOpenAcceptTicketWithouSelectQueue = async () => {
    if (ticket?.queueId || user?.queues.length === 1) {
      try {
        if (ticket?.queueId) {
          await api.put(`/tickets/${ticket.id}`, {
            status: "open",
            userId: user?.id || null,
            reason: "user-accept"
          });
        }
        if (user?.queues.length === 1 && !ticket?.queueId) {
          await api.put(`/tickets/${ticket.id}`, {
            status: "open",
            userId: user?.id || null,
            reason: "user-accept",
            queueId: user?.queues[0].id
          });
        }
      } catch (error) {
        console.log(error)
      }
      if (user.allowViewPendingTickets) {
        history.push(`/tickets/`);
        await new Promise(r => setTimeout(r, 200));
      }
      history.push(`/tickets/${ticket?.uuid}`);

    }
    else
      setAcceptTicketWithouSelectQueueOpen(true);
  };

  return (
    <React.Fragment key={ticket.id}>

      {acceptTicketWithouSelectQueueOpen &&
        <AcceptTicketWithouSelectQueue
          modalOpen={acceptTicketWithouSelectQueueOpen}
          onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
          ticket={ticket}

        />
      }

      {(user.allowNoQueue === 0 &&
        ticket.queue === null &&
        !(ticket.userId === user.id)) || (
          <>
            {(user.allowViewCloseTickets === "user" &&
              ticket.status === "closed" &&
              !(ticket.userId === user.id)) ||
              (user.allowViewCloseTickets === "none" &&
                ticket.status === "closed") || (
                <>
                  <ListItem
                    dense
                    button
                    title={
                      ticket.status === "pending"
                        ? "Para aceitar a conversa clique na cor da fila respectiva"
                        : ""
                    }
                    onClick={(e) => {

                      if (!user.allowViewPendingTickets) {
                        if (ticket.status === "pending") return;
                      }

                      handleSelectTicket(ticket.uuid);
                    }}
                    selected={ticketId && +ticketId === ticket.id}
                    className={clsx(classes.ticket, {
                      [classes.pendingTicket]: ticket.status === "pending",
                    })}
                    style={{ cursor: ((user.allowViewPendingTickets && ticket.status === "pending") || ticket.status === "open") ? "pointer" : "unset" }}
                  >


                    <div className={classes.ticketSource}>
                      {ticket?.source === 1 ?

                        <Tooltip
                          arrow
                          placement="right"
                          title={"Ativo - ticket iniciada através do atendente"}
                        >
                          <ArrowDropUpIcon
                            style={{ color: "#30af16" }}
                            fontSize="medium"
                          />
                        </Tooltip>


                        :

                        <Tooltip
                          arrow
                          placement="right"
                          title={"Receptivo - ticket iniciada através do contato"}
                        >
                          <ArrowDropDownIcon
                            style={{ color: "#2576d2" }}
                            fontSize="medium"
                          />
                        </Tooltip>
                      }
                    </div>
                    <Tooltip
                      arrow
                      placement="right"
                      title={ticket.queue?.name || "Sem fila"}
                    >
                      <span
                        style={{
                          backgroundColor: ticket.queue?.color || "#7C7C7C",
                        }}
                        className={classes.ticketQueueColor}
                      ></span>
                    </Tooltip>
                    <ListItemAvatar style={{ marginLeft: -6, marginRight: -12 }}>
                      <Avatar src={ticket?.contact?.profilePicUrl} />
                    </ListItemAvatar>
                    <ListItemText

                      disableTypography
                      primary={
                        <span className={classes.contactNameWrapper}>
                          <Typography

                            noWrap
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            {ticket?.contact?.queue?.color ?
                              <Tooltip title={<h3>{ticket?.contact?.name || ""}</h3>}>
                                <div style={{ border: "solid 3px" + (ticket?.contact?.queue?.color), padding: "1px 4px 1px 4px", borderRadius: "4px", marginTop: 3, maxWidth: 190 }}>
                                  <TruncateString
                                    body={ticket.contact.name}
                                  />
                                </div>
                              </Tooltip>
                              : <Tooltip title={<h3>{ticket?.contact?.name || ""}</h3>}>
                                <div style={{ padding: "1px 4px 1px 4px", borderRadius: "4px" }}>
                                  <TruncateString
                                    body={ticket.contact.name}
                                  /></div>
                              </Tooltip>
                            }
                          </Typography>
                          <Tooltip title={<div>
                            {ticket?.whatsapp?.name ? <h3>Conexão: {ticket?.whatsapp?.name}</h3> : ""}
                            {ticket?.user?.name ? <h3>Atendente:: {ticket?.user?.name}</h3> : ""}

                          </div>}>
                            <div
                              className={classes.badgeTicketUserAndConn}
                            >

                              <TruncateString
                                body={ticket?.whatsapp?.name}
                                positionVisible="end"
                                length={10}
                              />

                              {ticket?.user?.name ? <>


                                -(
                                <TruncateString
                                  body={ticket?.user?.name}
                                  positionVisible="start"
                                  length={15}
                                />
                                )


                              </> : null}
                            </div>
                          </Tooltip>




                          {ticket.lastMessage && (
                            <Tooltip
                              arrow
                              placement="right"
                              title={
                                isSameDay(parseISO(ticket.updatedAt), new Date())
                                  ? "Horário da ultima visualização"
                                  : ""
                              }
                            >
                              <Typography
                                className={classes.lastMessageTime}
                                component="span"
                                variant="body2"
                                color="textSecondary"
                              >
                                {isSameDay(
                                  parseISO(ticket.updatedAt),
                                  new Date()
                                ) ? (
                                  <>
                                    {format(parseISO(ticket.updatedAt), "HH:mm")}
                                  </>
                                ) : (
                                  <>
                                    {format(
                                      parseISO(ticket.updatedAt),
                                      "dd/MM/yyyy"
                                    )}
                                  </>
                                )}
                              </Typography>
                            </Tooltip>
                          )}
                        </span>
                      }
                      secondary={
                        <>
                          <span className={classes.contactNameWrapper}>
                            <Typography
                              className={classes.contactLastMessage}
                              noWrap
                              component="span"
                              variant="body2"
                              color="textSecondary"
                            >
                              {ticket.lastMessage ? (
                                <MarkdownWrapper>
                                  {ticket.lastMessage}
                                </MarkdownWrapper>
                              ) : (
                                <br />
                              )}
                            </Typography>

                            <Badge
                              overlap="rectangular"
                              className={classes.newMessagesCount}
                              badgeContent={ticket.unreadMessages}
                              classes={{
                                badge: classes.green,
                              }}
                            />
                          </span>

                          {ticket.status === "closed" && (
                            <div
                              className={classes.badgeTicketClosed}
                            >
                              encerrado
                            </div>
                          )}

                          {(ticket.status === "pending" ||
                            ticket.status === "open") && (
                              <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                {(ticket.pendingOutOffExpedient && !ticket.openOutOffExpedient) ? (
                                  <div

                                    className={classes.badgeTicketOutHour}

                                  >
                                    fora de expediente
                                  </div>) :
                                  <div className={classes.cronometro}>
                                    <Cronometro
                                      ticketStatus={ticket.status}
                                      createdAt={ticket.createdAt}
                                      updatedAt={ticket.updatedAt}
                                      ticketQueue={ticket.queue}
                                    ></Cronometro>
                                  </div>
                                }
                                {ticket.status === "pending" && (
                                  <div >
                                    <Button
                                      style={{ padding: 0, }}
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      onClick={(e) =>
                                        handleOpenAcceptTicketWithouSelectQueue()
                                      }
                                    >

                                      aceitar
                                    </Button>
                                  </div>
                                )}
                              </div>
                            )}
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              )}
          </>
        )
      }
    </React.Fragment >
  );
};

export default TicketListItem;
