import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { Grid, IconButton, InputAdornment } from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import AutoCompleteGeneric from "../AutoCompleteGeneric";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap"
	},

	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},
	selectColor: {
		minWidth: 80,
		maxWidth: 80,
		textColor: "#2AB912"
	}
	,

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
	const classes = useStyles();

	const initialState = {
		name: "✅ AGENDAMENTO DE ....",
		color: "",
		greetingMessage: "{{name}}, você escolheu a opção ................, aguarde por favor que já iremos atendê-lo...",
		timeOpeningGren: 5,
		timeOpeningYellow: 10,
		timeOpeningOrange: 15,
		timeOpeningRed: 60,
		timeIdlenessGren: 2,
		timeIdlenessYellow: 4,
		timeIdlenessOrange: 6,
		timeIdlenessRed: 20,
		expedient:null
	};

	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [queue, setQueue] = useState(initialState);
	const [timeOpeningGren, setTimeOpeningGren] = useState(initialState.timeOpeningGren);
	const [timeOpeningYellow, setTimeOpeningYellow] = useState(initialState.timeOpeningYellow);
	const [timeOpeningOrange, setTimeOpeningOrange] = useState(initialState.timeOpeningOrange);
	const [timeOpeningRed, setTimeOpeningRed] = useState(initialState.timeOpeningRed);
	const [timeIdlenessGren, setTimeIdlenessGren] = useState(initialState.timeIdlenessGren);
	const [timeIdlenessYellow, setTimeIdlenessYellow] = useState(initialState.timeIdlenessYellow);
	const [timeIdlenessOrange, setTimeIdlenessOrange] = useState(initialState.timeIdlenessOrange);
	const [timeIdlenessRed, setTimeIdlenessRed] = useState(initialState.timeIdlenessRed);
	const [expedient, setExpedient] = useState(initialState.timeIdlenessRed);
	const [loading, setLoading] = useState(false);

	const greetingRef = useRef();

	useEffect(() => {
		(async () => {
			
			if (!queueId) return;
			try {
				setLoading(true)
				const { data } = await api.get(`/queue/${queueId}`);
				setTimeIdlenessGren(data.timeIdlenessGren)
				setTimeIdlenessYellow(data.timeIdlenessYellow)
				setTimeIdlenessOrange(data.timeIdlenessOrange)
				setTimeIdlenessRed(data.timeIdlenessRed)
				setTimeOpeningGren(data.timeOpeningGren)
				setTimeOpeningYellow(data.timeOpeningYellow)
				setTimeOpeningOrange(data.timeOpeningOrange)
				setTimeOpeningRed(data.timeOpeningRed)
				setExpedient(data?.expedient)
				setQueue(prevState => {
					return {
						...prevState, ...data
						,
					};
				});
				setLoading(false)
			} catch (err) {
				toastError(err);
			}
			
		}
		
		)();

		return () => {
			setQueue({
				...initialState

			});
		};
	}, [queueId, open]);

	const handleClose = () => {
		onClose();
		setQueue(initialState);
		setExpedient(initialState.expedient)
		setTimeIdlenessGren(initialState.timeIdlenessGren)
		setTimeIdlenessYellow(initialState.timeIdlenessYellow)
		setTimeIdlenessOrange(initialState.timeIdlenessOrange)
		setTimeIdlenessRed(initialState.timeIdlenessRed)
		setTimeOpeningGren(initialState.timeOpeningGren)
		setTimeOpeningYellow(initialState.timeOpeningYellow)
		setTimeOpeningOrange(initialState.timeOpeningOrange)
		setTimeOpeningRed(initialState.timeOpeningRed)
	};

	const handleSaveQueue = async values => {
		try {
			values.timeIdlenessGren = timeIdlenessGren
			values.timeIdlenessYellow = timeIdlenessYellow
			values.timeIdlenessOrange = timeIdlenessOrange
			values.timeIdlenessRed = timeIdlenessRed
			values.timeOpeningGren = timeOpeningGren
			values.timeOpeningYellow = timeOpeningYellow
			values.timeOpeningOrange = timeOpeningOrange
			values.timeOpeningRed = timeOpeningRed
			values.expedientId = expedient?.id || null
			if (queueId) {
				await api.put(`/queue/${queueId}`, values);
			} else {
				await api.post("/queue", values);
			}
			toast.success("Queue saved successfully");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<>
		{loading ? <></> : 
			<Dialog maxWidth="lg" fullWidth={true} open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{queueId
						? `${i18n.t("queueModal.title.edit")}`
						: `${i18n.t("queueModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={queue}
					enableReinitialize={true}
					validationSchema={QueueSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveQueue(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<div style={{display:"flex"}}>
								<div style={{width:440 , paddingRight:12}}>
								<Field
									fullWidth
									as={TextField}
									label={i18n.t("queueModal.form.name")}
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								</div>
								<div style={{width:160, marginRight:12}}>
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.color")}
									name="color"
									id="color"
									onFocus={() => {
										setColorPickerModalOpen(true);
										greetingRef.current.focus();
									}}
									error={touched.color && Boolean(errors.color)}
									helperText={touched.color && errors.color}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<div
													style={{ backgroundColor: values.color }}
													className={classes.colorAdorment}
												></div>
											</InputAdornment>
										),
										endAdornment: (
											<IconButton
												size="small"
												color="default"
												onClick={() => setColorPickerModalOpen(true)}
											>
												<Colorize />
											</IconButton>
										),
									}}
									variant="outlined"
									margin="dense"
								/>
								
								<ColorPicker
									open={colorPickerModalOpen}
									handleClose={() => setColorPickerModalOpen(false)}
									onChange={color => {
										values.color = color;
										setQueue(() => {
											return { ...values, color };
										});
									}}
								/>
								</div>
								<div style={{width:240}}>
								<AutoCompleteGeneric
									name="expedient"
									label={i18n.t(
										"queueModal.form.expedient"
									)}
									route={"expedient"}
									value={expedient}
									//required={user?.classificationPermission === "optional_S" || user?.classificationPermission === "required_S"}
									onChange={(values) => setExpedient(values)}
								/>

								</div>

								</div>
								<div>
									<Grid container >

										<Grid container xs={6}>
											<h3>Tempo Total dos tickets alterar cor por tempo em minutos:</h3>
										</Grid>
										<Grid container xs={6}>
											<h3>Ociosidade alterar cor por tempo em minutos: </h3>
										</Grid>
									</Grid>
									<Grid container >



										<Grid container xs={6}>
											<Grid item xs={2}>
												<Field
													as={TextField}
													style={{ borderBottom: '3px solid #FBA72A' }}
													className={classes.selectColor}
													name="timeOpeningOrange"
													//component={TextField}
													title="Acima deste valor sera exibido em Vermelho"
													label="Laranja"
													type="number"

													InputProps={{ inputProps: { min: parseInt(timeOpeningYellow) + 1, max: 120 } }}
													fullWidth
													margin="dense"
													variant="outlined"
													onChange={e => setTimeOpeningOrange(e.target.value)}
													value={timeOpeningOrange}
												/>

											</Grid>



											<Grid item xs={2}>
												<Field
													as={TextField}
													style={{ borderBottom: '3px solid #fafa05' }}
													className={classes.selectColor}
													name="timeOpeningYellow"
													label="Amarelo"
													type="number"
													InputProps={{ inputProps: { min: parseInt(timeOpeningGren) + 1, max: parseInt(timeOpeningOrange) - 1 } }}
													fullWidth
													margin="dense"
													variant="outlined"
													onChange={e => setTimeOpeningYellow(e.target.value)}
													value={timeOpeningYellow}
												/>

											</Grid>
											<Grid item xs={2}>
												<Field
													as={TextField}
													style={{ borderBottom: '3px solid #2AB912' }}
													className={classes.selectColor}
													name="timeOpeningGren"
													label="Verde"
													type="number"
													InputProps={{ inputProps: { min: 1, max: parseInt(timeOpeningYellow) - 1 } }}
													fullWidth
													margin="dense"
													variant="outlined"
													onChange={e => setTimeOpeningGren(e.target.value)}
													value={timeOpeningGren}
												/>

											</Grid>

											<Grid item xs={5} style={{ backgroundColor: "#d1d1e0", padding: 5, borderRadius: 8 }}>

												<strong style={{ color: "#2AB912" }}>De 0 a {timeOpeningGren} minutos: Verde </strong><br />
												<strong style={{ color: "#fafa05" }}>De {timeOpeningGren} a {timeOpeningYellow} minutos: Amarelo </strong><br />
												<strong style={{ color: "#FBA72A" }}>De {timeOpeningYellow} a {timeOpeningOrange} minutos: Laranja </strong><br />
												<strong style={{ color: "#FB2A2A" }}>Acima de {timeOpeningOrange} minutos: Vermelho </strong><br />


											</Grid>


										</Grid>
										<Grid container xs={6}>
											<Grid item xs={2}>
												<Field
													as={TextField}
													style={{ borderBottom: '3px solid #FBA72A' }}
													className={classes.selectColor}
													name="timeIdlenessOrange"
													//component={TextField}
													label="Laranja"
													type="number"

													InputProps={{ inputProps: { min: parseInt(timeIdlenessYellow) + 1, max: 120 } }}
													fullWidth
													margin="dense"
													variant="outlined"
													onChange={e => setTimeIdlenessOrange(e.target.value)}
													value={timeIdlenessOrange}
												/>

											</Grid>



											<Grid item xs={2}>
												<Field
													as={TextField}
													style={{ borderBottom: '3px solid #fafa05' }}
													className={classes.selectColor}
													name="timeIdlenessYellow"
													label="Amarelo"
													type="number"
													InputProps={{ inputProps: { min: parseInt(timeIdlenessGren) + 1, max: parseInt(timeIdlenessOrange) - 1 } }}
													fullWidth
													margin="dense"
													variant="outlined"
													onChange={e => setTimeIdlenessYellow(e.target.value)}
													value={timeIdlenessYellow}
												/>

											</Grid>

											<Grid item xs={2}>
												<Field
													as={TextField}
													style={{ borderBottom: '3px solid #2AB912' }}
													className={classes.selectColor}
													name="timeIdlenessGren"
													label="Verde"
													type="number"
													InputProps={{ inputProps: { min: 1, max: parseInt(timeIdlenessYellow) - 1 } }}
													fullWidth
													margin="dense"
													variant="outlined"
													onChange={e => setTimeIdlenessGren(e.target.value)}
													value={timeIdlenessGren}
												/>

											</Grid>
											<Grid item xs={5} style={{ backgroundColor: "#d1d1e0", padding: 5, borderRadius: 8 }}>

												<strong style={{ color: "#2AB912" }}>De 0 a {timeIdlenessGren} minutos: Verde </strong><br />
												<strong style={{ color: "#fafa05" }}>De {timeIdlenessGren} a {timeIdlenessYellow} minutos: Amarelo </strong><br />
												<strong style={{ color: "#FBA72A" }}>De {timeIdlenessYellow} a {timeIdlenessOrange} minutos: Laranja </strong><br />
												<strong style={{ color: "#FB2A2A" }}>Acima de {timeIdlenessOrange} minutos: Vermelho </strong><br />


											</Grid>

										</Grid>

									</Grid>



									{/* <Field
										as={TextField}
										label={i18n.t("queueModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										inputRef={greetingRef}
										minRows={5}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
									/> */}
								</div>
								{/* <QueueOptions queueId={queueId} /> */}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{queueId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
			}
		</>
	);
};

export default QueueModal;
