import React from "react";
import Chart from "react-google-charts";
import _ from 'lodash'
import { Grid } from "@mui/material";

export const optionsPieQueue = {
	title: "Tickets por Fila",
	is3D: true,
	width: 450,
	height: 300,
	backgroundColor: 'transparent',
}

export const optionsBarQueue = {
	title: "Tickets por Fila",
	is3D: true,
	width: 450,
	height: 300,
	chartArea: {
		backgroundColor: '#fcfcfc'
	}

}




const loadChartQueue = (data) => {
    const values = _.groupBy(data, (value) => value.queue);
    const result = _.map(values, (value, key) => {
        return [
            key,
            parseFloat(value[0].qtdAtendimentos)
        ]
    });
    return [
        ["Ticket", "Usuário"], ...result,];
}

const DashStatusByQueue = (props) => {
    const {
        title,
        data

    } = props;
    let reportData = { ...data }.reportData
    //console.log("DashSkeleton");
    //console.log(reportData)
    return (
        <table border="1" id="table-to-xls" style={{ width: "100%", border: '0' }} cellPadding={"5"} align="center">
            <tr>
                <th>Setor</th>
                <th>Quantidade de atendimentos</th>
                <th>% Quantidade de Atendimentos</th>
            </tr>
            {reportData?.dataQueuesWitchPercent.map((row) => (
                <tr
                    key={row.name}
                    sx={{}}
                >
                    <th align="center" >{row.queue}</th>

                    <th align="center">{row.qtdAtendimentos}</th>

                    <th align="center">{row.percent}%</th>


                </tr>
            ))}
            <tr>

                <th colSpan={3}>
                    <Grid container>
                        <Grid item sx={{
                            width: '50%',
                            height: 300,
                            

                        }}
                        >
                            <Chart
                                chartType="PieChart"
                                data={loadChartQueue(reportData?.dataQueuesWitchPercent)}
                                options={optionsPieQueue}
                            />


                        </Grid>
                        <Grid item sx={{
                            width: '50%',
                            height: 300,
                        }}
                        >
                            <Chart
								chartType="Bar"
								data={loadChartQueue(reportData?.dataQueuesWitchPercent)}
								options={optionsBarQueue}
							/>


                        </Grid>
                    </Grid>
                </th>

            </tr>
        </table>
    )
}

export default DashStatusByQueue;