import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

export function ListItemLink(props) {
    const { icon, primary, to, className } = props;
  
    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <RouterLink to={to} ref={ref} {...itemProps} />
        )),
      [to]
    );
  
    return (
      <li>
        <ListItem style={{ width: "300px" }} button component={renderLink} className={className}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  }