import React, { useState, useEffect, useContext } from "react";
import openSocket from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import SuperAdminMenu from "../../components/SuperAdminMenu/index.js";
import { Button, DialogActions, Paper, Table, TableCell, TableContainer, TableRow, TextField } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import MainHeader from "../../components/MainHeader/index.js";
import Title from "../../components/Title/index.js";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper/index.js";
import MainContainer from "../../components/MainContainer/index.js";
import { AuthContext } from "../../context/Auth/AuthContext.js";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(4),
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12
	},

	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},
}));

const Settings = () => {
	const classes = useStyles();

	const [settings, setSettings] = useState([]);
	const [numberUsers, setNumberUsers] = useState(0);
	const [numberWhatsapps, setNumberWhatsapps] = useState(0);
	
	const [initialSettings, setInitialSettings] = useState([]);
	const { user } = useContext(AuthContext);

	const fetchSession = async () => {
		try {
			const { data } = await api.get("/settings");
			console.log(data)
			setSettings(data?.setting);
			setInitialSettings(data?.setting)
			setNumberWhatsapps(data?.numberWhatsapps)
			setNumberUsers(data?.numberUsers)
		} catch (err) {
			toastError(err);
		}
	};
	useEffect(() => {

		fetchSession();
	}, []);

	const handleSaveSettings = async (values) => {
		const settingsData = { ...values };
		//console.log(values);
		try {

			await api.put(`/settings`, settingsData);
			toast.success(i18n.t("Salvo com sucesso"));
		} catch (err) {
			toastError(err);
		}
		fetchSession();
	}




	return (
		<>
			{user.id === 1 ?
				<div style={{ margin: 12 }}>
					<MainContainer >
						<MainHeader>
							<Title>{i18n.t("Super Admin")}</Title>
							<MainHeaderButtonsWrapper>
								<SuperAdminMenu />
							</MainHeaderButtonsWrapper>
						</MainHeader>
						<div>
							<Formik
								initialValues={settings}

								enableReinitialize={true}

								onSubmit={(values, actions) => {
									setTimeout(() => {
										handleSaveSettings(values);
										actions.setSubmitting(false);
									}, 400);
								}}
							>
								{({ touched, errors, isSubmitting }) => (
									<Form>
										<TableContainer component={Paper}>
											<Table size="small" >
												<TableRow>
													<TableCell
														align="center" colSpan={3} > <h2 style={{ padding: 0, margin: 0 }}>Configurações Gerais</h2></TableCell>
												</TableRow>
												<TableRow>
													<TableCell><b>Descrição</b></TableCell>
													<TableCell><b>Em Uso</b></TableCell>
													<TableCell><b>Definição</b></TableCell>
												</TableRow>
												<TableRow>
													<TableCell>Numero de usuários</TableCell>
													<TableCell><b>{numberUsers}</b></TableCell>
													<TableCell>

														<Field
															name="limitUsers"
															component={TextField}
															type="number"
															InputProps={{ inputProps: { min: 1, max: 100000 } }}
															value={settings.limitUsers}
															onChange={(e) => setSettings({ ...settings, limitUsers: e.target.value })}
															focused
															fullWidth
														/>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														Numero de Conexões
													</TableCell>
													<TableCell><b>{numberWhatsapps}</b></TableCell>
													<TableCell>
														<Field
															name="limitWhatsapps"
															component={TextField}
															type="number"
															InputProps={{ inputProps: { min: 1, max: 100000 } }}
															value={settings.limitWhatsapps}
															onChange={(e) => setSettings({ ...settings, limitWhatsapps: e.target.value })}
															focused
															fullWidth
														/>
													</TableCell>
												</TableRow>
											</Table>
										</TableContainer>
										<DialogActions>
											<Button
												type="submit"
												color="primary"
												disabled={initialSettings === settings}
												variant="contained"
												className={classes.btnWrapper}
											//onClick={handleSaveSettings}
											>
												Salvar
											</Button>
										</DialogActions>
									</Form>
								)}
							</Formik>


						</div>

					</MainContainer>
				</div>

				: null}
		</>





	);
};

export default Settings;
