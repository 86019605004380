import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NotWorkingHoursModal from "../../components/NotWorkingHoursModal";
import moment from "moment";

const reducer = (state, action) => {
  if (action.type === "LOAD_DATAS") {
    const notWorkingHours = action.payload;
    const newnotWorkingHours = [];

    notWorkingHours.forEach((notWorkingHour) => {
      const notWorkingHourIndex = state.findIndex(
        (u) => u.id === notWorkingHour.id
      );
      if (notWorkingHourIndex !== -1) {
        state[notWorkingHourIndex] = notWorkingHour;
      } else {
        newnotWorkingHours.push(notWorkingHour);
      }
    });

    return [...state, ...newnotWorkingHours];
  }

  if (action.type === "UPDATE_DATAS") {
    const notWorkingHour = action.payload;
    const notWorkingHourIndex = state.findIndex(
      (u) => u.id === notWorkingHour.id
    );

    if (notWorkingHourIndex !== -1) {
      state[notWorkingHourIndex] = notWorkingHour;
      return [...state];
    } else {
      return [notWorkingHour, ...state];
    }
  }

  if (action.type === "DELETE_DATA") {
    const notWorkingHourId = action.payload;

    const notWorkingHourIndex = state.findIndex(
      (u) => u.id === notWorkingHourId
    );
    if (notWorkingHourIndex !== -1) {
      state.splice(notWorkingHourIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    margin: theme.spacing(1),
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  headerBar: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-around",
  },
}));

const diasDaSemana = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

const NotWorkingHours = ({ expedientId }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedWorkingHour, setSelectedWorkingHour] = useState(null);

  const [deletingNpsSurvey, setDeletingNpsSurvey] =
    useState(null);
  const [notWorkingHourModalOpen, setSelectedWorkingHourModalOpen] =
    useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [notWorkingHours, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  const fetchnotWorkingHours = async () => {
    try {
      const { data } = await api.get(`/notWorkingHourList/${expedientId}`, {
        params: { searchParam, pageNumber },
      });
      dispatch({
        type: "LOAD_DATAS",
        payload: data.notWorkingHours,
      });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchnotWorkingHours();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);



  const handleOpenNpsSurveyModal = () => {
    setSelectedWorkingHour(null);
    setSelectedWorkingHourModalOpen(true);
  };


  const handleCloseNpsSurveyModal = () => {
    setSelectedWorkingHour(null);
    setSelectedWorkingHourModalOpen(false);
    fetchnotWorkingHours();
  };



  const handleEdit = (notWorkingHour) => {
    console.log(notWorkingHour)
    setSelectedWorkingHour(notWorkingHour);
    setSelectedWorkingHourModalOpen(true);
  };

  const handleDeleteNpsSurvey = async (
    notWorkingHourId
  ) => {
    try {
      await api.delete(`/notWorkingHour/${notWorkingHourId}`);
      toast.success(i18n.t("notWorkingHour.toasts.deleted"));
      dispatch({ type: "RESET" });
      fetchnotWorkingHours();
    } catch (err) {
      toastError(err);
    }
    setDeletingNpsSurvey(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const RenderDate = ({ type, date }) => {

    const dataAtual = new Date(date);
    const numeroDiaSemana = dataAtual.getDay();

    return (
      <>
        {type === "weekly" ?
          diasDaSemana[numeroDiaSemana] + " " + moment(dataAtual).format('HH:mm:ss')
          :
          moment(dataAtual).format('DD/MM/YYYY HH:mm:ss')

        }
      </>

    )
  }

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingNpsSurvey &&
          `${i18n.t("notWorkingHour.confirmationModal.deleteTitle")} ${deletingNpsSurvey.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() =>
          handleDeleteNpsSurvey(deletingNpsSurvey.id)
        }
      >
        {i18n.t("notWorkingHour.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <NotWorkingHoursModal
        open={notWorkingHourModalOpen}
        expedientId={expedientId}
        onClose={handleCloseNpsSurveyModal}
        aria-labelledby="form-dialog-title"
        notWorkingHoursId={
          selectedWorkingHour && selectedWorkingHour.id
        }
      />
      <MainHeader>
        <Title>{i18n.t("notWorkingHours.title")}</Title>
        <Button
          style={{ marginLeft: 56 }}
          variant="contained"
          color="primary"
          onClick={handleOpenNpsSurveyModal}
        >
          {i18n.t("notWorkingHours.buttons.add")}
        </Button>
      </MainHeader>
      <Grid spacing={12} container>
        <Grid item xs={12}>

          <Paper
            style={{ minHeight: "500px", maxHeight: "500px" }}
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {i18n.t("notWorkingHours.table.type")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("notWorkingHours.table.description")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("notWorkingHours.table.startHour")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("notWorkingHours.table.endHour")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("common.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {notWorkingHours.map((notWorkingHour) => (
                    <TableRow key={notWorkingHour.id}>
                      <TableCell align="center">
                        {i18n.t(`notWorkingHours.type.${notWorkingHour?.type}`)}
                      </TableCell>
                      <TableCell align="center">
                      {notWorkingHour?.description}
                      </TableCell>
                      <TableCell align="center">
                        {RenderDate({ type: notWorkingHour?.type, date: notWorkingHour?.startHour })}
                      </TableCell>
                      <TableCell align="center">
                        {RenderDate({ type: notWorkingHour?.type, date: notWorkingHour?.endHour })}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => { handleEdit(notWorkingHour) }
                          }
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingNpsSurvey(
                              notWorkingHour
                            );
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton columns={1} />}
                </>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default NotWorkingHours;
