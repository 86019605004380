import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { isArray } from "lodash";
import Select from "@material-ui/core/Select";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ReportAttendanceModal = ({
  open,
  onClose,
  queueId,
  userId,
  onAddReport,
}) => {
  const classes = useStyles();
  const initialState = {
    initialDate: moment().add(-30, "days").format("YYYY-MM-DDTHH:mm"),
    finalDate: moment().add(0, "days").format("YYYY-MM-DDTHH:mm"),
    initialUser: { id: -1, name: "Todos" },
    initialQueue: { id: -1, name: "Todos" },
  };
  const [initial, setInitial] = useState(initialState);
  const [currentQueue, setCurrentQueue] = useState(initialState.initialQueue);
  const [currentUser, setCurrentUser] = useState(initialState.initialUser);
  const [queues, setQueues] = useState([initialState.initialQueue]);
  const [users, setUsers] = useState([]);
  const [source, setSource] = useState(2);
  useEffect(() => {
    if (queueId && queues.length) {
      const queue = queues.find((c) => c.id === queueId);
      if (queue) {
        setCurrentQueue(queue);
      }
    }
  }, [queueId, queues]);

  useEffect(() => {
    if (userId && users.length) {
      const user = users.find((c) => c.id === userId);
      if (user) {
        setCurrentUser(user);
      }
    }
  }, [userId, users]);

  useEffect(() => {
    if (open) {
      try {
        (async () => {
          const { data: queueList } = await api.get("/queue");
          queueList.push(initialState.initialQueue);
          let customQueueList = queueList.map((c) => ({
            id: c.id,
            name: c.name,
          }));

          if (isArray(customQueueList)) {
            setQueues([{ id: "", name: "" }, ...customQueueList]);
          }
          if (queueId) {
            setInitial((prevState) => {
              return { ...prevState, queueId };
            });
          }
          const { data: userList } = await api.get("/users/list");
          userList.push(initialState.initialUser);
          let customUserList = userList.map((c) => ({ id: c.id, name: c.name }));
          if (isArray(customUserList)) {
            setUsers([{ id: "", name: "" }, ...customUserList]);
          }
        })();
      } catch (err) {
        toastError(err);
      }
    }

  }, [userId, open, queueId]);

  const handleClose = () => {
    onClose();
    setInitial(initialState);
  };
  const handleGenerateReport = async (values) => {
    const { initialDate, finalDate } = values;
    const filter = { initialDate, finalDate, currentQueue, currentUser, source };
    const { data: report } = await api.get("/report/attendance", {
      params: { initialDate, finalDate, currentQueue, currentUser, source},
    });
    const dataList = { filter: filter, report };
    onAddReport(dataList);
    handleClose();
  };
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >

        <Formik
          initialValues={initial}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleGenerateReport(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, props }) => (
            <>

              <Form>
                <DialogContent dividers>
                  <h3>{i18n.t("reports.attendance")}</h3>
                  <div className={classes.multFieldLine}>
                    <Field
                      as={TextField}
                      label={i18n.t("reports.initialDate")}
                      type="datetime-local"
                      name="initialDate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={touched.sendAt && Boolean(errors.sendAt)}
                      helperText={touched.sendAt && errors.sendAt}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <hr></hr>
                  <div className={classes.multFieldLine}>
                    <Field
                      as={TextField}
                      label={i18n.t("reports.finalDate")}
                      type="datetime-local"
                      name="finalDate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={touched.sendAt && Boolean(errors.sendAt)}
                      helperText={touched.sendAt && errors.sendAt}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <hr></hr>
                  <div className={classes.multFieldLine}>
                    <FormControl variant="outlined" fullWidth>
                      <Autocomplete
                        fullWidth
                        name="queue"
                        value={currentQueue}
                        options={queues}
                        required
                        onChange={(e, queue) => {
                          setCurrentQueue(
                            queue ? queue : initialState.initialQueue
                          );
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => {
                          return value.id === option.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Fila"
                            placeholder="Fila"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <hr></hr>
                  <div className={classes.multFieldLine}>
                    <FormControl variant="outlined" fullWidth>
                      <Autocomplete
                        fullWidth
                        value={currentUser}
                        options={users}
                        required
                        onChange={(e, user) => {
                          setCurrentUser(user ? user : initialState.initialUser);
                        }}
                        getOptionLabel={(user) => user.name}
                        getOptionSelected={(user, value) => {
                          return value.id === user.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Atendente"
                            placeholder="Atendente"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div style={{marginTop:10}} className={classes.multFieldLine}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                    >
                      <InputLabel id="source-report-modal-label">
                        {i18n.t("Origem")}
                      </InputLabel>

                      <Field
                        as={Select}
                        label={i18n.t("Origem")}
                        name="source"
                        labelId="source-report-modal-label"
                        id="source-report-modal"
                        required
                        value={source}
                        onChange={(e)=> setSource(e.target.value)}
                      >
                        <MenuItem key={1} value={1}>Ativo</MenuItem>
                        <MenuItem key={2} value={2}>Receptivo</MenuItem>
                      </Field>
                    </FormControl>
                  </div>
                  <br />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("common.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    //disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {i18n.t("reports.generate")}
                  </Button>
                </DialogActions>
              </Form>
            </>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ReportAttendanceModal;
