import moment from "moment";
import React, { useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	ticketOpening: {
		marginBottom: 2,
		paddingBottom: 0,
		paddingLeft: 6,
		margin: 0,
		maxHeight: 16,
		marginRight: 12,
		overflow:"hidden",
		textAlign: 'center',
		paddingRight: 6,
		fontWeight: 'bold',
		backgroundColor: "#c2c9d6",
		border: 'solid 1px',
		borderRadius: 12,
		fontSize: 11,
	},
	ticketIdleness: {
		marginBottom: 0,
		overflow:"hidden",
		paddingBottom: -2,
		paddingLeft: 6,
		margin: 0,
		textAlign: 'center',
		fontWeight: 'bold',
		backgroundColor: "#f2f2f2",
		border: 'solid 1px',
		borderRadius: 12
	}
}))

const Cronometro = (props) => {
	const classes = useStyles();
	const clientTimestampDife = localStorage.getItem("clientTimestampDife") || 0
	const { createdAt, ticketStatus, updatedAt, ticketQueue } = props
	const [createTimePending, setCreateTimePending] = useState();
	const [createDayPending, setCreateDayPending] = useState();
	const [updatedTimePending, setUpdatedTimePending] = useState();
	const [updatedDayPending, setUpdatedDayPending] = useState();
	const renderDelaySecond = 60 // Define o tempo a cada atualização a renderização do cronometro em segundos
	useEffect(() => {
		const startDate = new Date();
		const created = new Date(createdAt);
		const updated = new Date(updatedAt);
		const updatedDiff = (updated.getTime() - startDate.getTime() - clientTimestampDife);
		const updatedTimeMicroSeconds = Math.abs(Math.round(updatedDiff))
		setUpdatedTimePending(updatedTimeMicroSeconds);
		setUpdatedDayPending(parseInt(updatedTimeMicroSeconds / 1000 / 60 / 60 / 24))
		const createdDiff = (created.getTime() - startDate.getTime() - clientTimestampDife);
		const createdTimeMicroSeconds = Math.abs(Math.round(createdDiff))
		setCreateTimePending(createdTimeMicroSeconds);
		setCreateDayPending(parseInt(createdTimeMicroSeconds / 1000 / 60 / 60 / 24))
		const interval = setInterval(() => {
			const startDate = new Date();
			const created = new Date(createdAt);
			const updated = new Date(updatedAt);
			const updatedDiff = (updated.getTime() - startDate.getTime() - clientTimestampDife);
			const updatedTimeMicroSeconds = Math.abs(Math.round(updatedDiff))
			setUpdatedTimePending(updatedTimeMicroSeconds);
			setUpdatedDayPending(parseInt(updatedTimeMicroSeconds / 1000 / 60 / 60 / 24))
			const createdDiff = (created.getTime() - startDate.getTime() - clientTimestampDife);
			const createdTimeMicroSeconds = Math.abs(Math.round(createdDiff))
			setCreateTimePending(createdTimeMicroSeconds);
			setCreateDayPending(parseInt(createdTimeMicroSeconds / 1000 / 60 / 60 / 24))
		}, 1000 * renderDelaySecond);
		return () => clearInterval(interval);
	}, [updatedAt]);
	//console.log(ticketQueue);
	return (
		<>
			{ticketQueue ?
				<div style={{ display: 'flex', alignContent: 'center' }} >
					<div className={classes.ticketOpening} style={
						(   createTimePending > 0   && createTimePending <= (ticketQueue?.timeOpeningGren * 60 * 1000)) //define o texto como verde
							? { color: "#2AB912", backgroundColor: "#ffffff" } :
							( createTimePending > (ticketQueue?.timeOpeningGren * 60 * 1000) && createTimePending <= (ticketQueue?.timeOpeningYellow * 60 * 1000)) ? //amarelo
								{ color: "#fafa05", backgroundColor: "#000000" } :
								(createTimePending > (ticketQueue?.timeOpeningYellow * 60 * 1000) && createTimePending <= (ticketQueue?.timeOpeningOrange * 60 * 1000)) ? //laranja
									{ color: "#FBA72A", backgroundColor: "#000000" } :
										
										{ color: "#FB2A2A", backgroundColor: "#ffffff" } 

								
					}>
						{ticketStatus === "open" || ticketStatus === "pending" ? ` Aberto:${createDayPending === 1 && createTimePending >= 10000 ? ` ${createDayPending} dia` : createDayPending > 1 ? `${createDayPending} dias` : ""} ${createTimePending >= 10000 ? moment.utc(createTimePending).format('HH:mm'): "00:00"}` : null}
					</div>

					<div className={classes.ticketOpening} style={
						(updatedTimePending > 0 && updatedTimePending <= (ticketQueue?.timeIdlenessGren * 60 * 1000)) //define o texto como verde
							? { color: "#2AB912", backgroundColor: "#ffffff" } :
							( updatedTimePending > (ticketQueue?.timeIdlenessGren * 60 * 1000) && updatedTimePending <= (ticketQueue?.timeIdlenessYellow * 60 * 1000)) ?
								{ color: "#fafa05", backgroundColor: "#000000" } :
								(updatedTimePending > (ticketQueue?.timeIdlenessYellow * 60 * 1000) && updatedTimePending <= (ticketQueue?.timeIdlenessOrange * 60 * 1000)) ?
									{ color: "#FBA72A", backgroundColor: "#000000" } :
									{ color: "#FB2A2A", backgroundColor: "#ffffff" } 
					}>
						{ticketQueue ? `Ociosidade: ${updatedDayPending === 1 && updatedTimePending >= 10000   ? ` ${updatedDayPending} dia` : updatedDayPending > 1 ? `${updatedDayPending} dias` : ""} ${updatedTimePending >= 10000 ? moment.utc(updatedTimePending).format('HH:mm'): "00:00"}` : null}
					</div>
				</div>
				: <div style={{ display: 'flow', alignContent: 'center' }}>
					<div className={classes.ticketOpening} style={{ color: "#00000" }}>
						Recebido:
						{ticketStatus === "pending" || ticketStatus === "open" ? ` ${createDayPending === 1  && createTimePending >= 10000 ? `${createDayPending} dia` : createDayPending > 1 ? `${createDayPending} dias` : ""} ${createTimePending >= 10000 ? moment.utc(createTimePending).format('HH:mm'): "00:00"}` : null}
					</div>
					<div className={classes.ticketOpening} style={{ color: "#00000" }}>
						Ociosidade:
						{ticketStatus === "pending" || ticketStatus === "open" ? ` ${updatedDayPending === 1 && updatedTimePending >= 10000  ? `${updatedDayPending} dia` : updatedDayPending > 1 ? `${updatedDayPending} dias` : ""} ${updatedTimePending >= 10000 ? moment.utc(updatedTimePending).format('HH:mm'): "00:00"}` : null}
					</div>
				</div>
			}
		</>


	);
};
export default Cronometro;