export const TruncateString = ({body, length = 20, positionVisible = "start"}) =>{

    


    try {
        if(body === null) return ""
        let result = ""
        if(body){
            if(body.length > length){
                if(positionVisible === "start"){
                    result = body.substring(0, length) + "..."
                }else
                if(positionVisible === "end"){
                    result = "..." + body.substring(body.length - length, body.length) 
                }
    
            }else
            result = body
            
            
        }
        return result
        
    } catch (error) {
        console.error(error)
        return ""
    }


}