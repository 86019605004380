import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import CategoryClassificationModal from "../../components/CategoryClassificationModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Grid } from "@material-ui/core";

const reducer = (state, action) => {
  if (action.type === "LOAD_DATAS") {
    const categoryClassifications = action.payload;
    const newCategoryClassifications = [];

    categoryClassifications.forEach((categoryClassification) => {
      const categoryClassificationIndex = state.findIndex(
        (u) => u.id === categoryClassification.id
      );
      if (categoryClassificationIndex !== -1) {
        state[categoryClassificationIndex] = categoryClassification;
      } else {
        newCategoryClassifications.push(categoryClassification);
      }
    });

    return [...state, ...newCategoryClassifications];
  }

  if (action.type === "UPDATE_DATAS") {
    const categoryClassification = action.payload;
    const categoryClassificationIndex = state.findIndex(
      (u) => u.id === categoryClassification.id
    );

    if (categoryClassificationIndex !== -1) {
      state[categoryClassificationIndex] = categoryClassification;
      return [...state];
    } else {
      return [categoryClassification, ...state];
    }
  }

  if (action.type === "DELETE_DATA") {
    const categoryClassificationId = action.payload;

    const categoryClassificationIndex = state.findIndex(
      (u) => u.id === categoryClassificationId
    );
    if (categoryClassificationIndex !== -1) {
      state.splice(categoryClassificationIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    margin: theme.spacing(1),
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  headerBar: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-around",
  },
}));

const CategoryClassification = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedCategoryClassification, setSelectedCategoryClassification] =
    useState(null);

  const [deletingCategoryClassification, setDeletingCategoryClassification] =
    useState(null);
  const [categoryClassificationModalOpen, setCategoryClassificationModalOpen] =
    useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [categoryClassifications, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  const fetchCategoryClassifications = async () => {
    try {
      const { data } = await api.get("/categoryCl/", {
        params: { searchParam, pageNumber },
      });
      dispatch({
        type: "LOAD_DATAS",
        payload: data.categoryClassifications,
      });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchCategoryClassifications();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("categoryClassification", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({
          type: "UPDATE_DATAS",
          payload: data.categoryClassification,
        });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_DATA",
          payload: +data.categoryClassificationId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenCategoryClassificationModal = () => {
    setSelectedCategoryClassification(null);
    setCategoryClassificationModalOpen(true);
  };


  const handleCloseCategoryClassificationModal = () => {
    setSelectedCategoryClassification(null);
    setCategoryClassificationModalOpen(false);
    fetchCategoryClassifications();
  };


  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditCategoryClassification = (categoryClassification) => {
    setSelectedCategoryClassification(categoryClassification);
    setCategoryClassificationModalOpen(true);
  };

  const handleDeleteCategoryClassification = async (
    categoryClassificationId
  ) => {
    try {
      await api.delete(`/categoryCl/${categoryClassificationId}`);
      toast.success(i18n.t("classifications.toasts.deleted"));
      dispatch({ type: "RESET" });
      fetchCategoryClassifications();
    } catch (err) {
      toastError(err);
    }
    setDeletingCategoryClassification(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingCategoryClassification &&
          `${i18n.t("classifications.confirmationModal.deleteTitle")} ${
            deletingCategoryClassification.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() =>
          handleDeleteCategoryClassification(deletingCategoryClassification.id)
        }
      >
        {i18n.t("classifications.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <CategoryClassificationModal
        open={categoryClassificationModalOpen}
        onClose={handleCloseCategoryClassificationModal}
        aria-labelledby="form-dialog-title"
        categoryClassificationId={
          selectedCategoryClassification && selectedCategoryClassification.id
        }
      />
      <MainHeader>
        <Title>{i18n.t("classifications.title")}</Title>
      </MainHeader>
      <Grid spacing={12} container>
        <Grid item xs={12}>
          <div className={classes.headerBar}>
            <TextField
              placeholder={i18n.t("contacts.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenCategoryClassificationModal}
            >
              {i18n.t("classifications.buttons.classification")}
            </Button>
          </div>

          <Paper
            style={{ minHeight: "500px", maxHeight: "500px" }}
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {i18n.t("classifications.table.classification")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("classifications.table.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {categoryClassifications.map((categoryClassification) => (
                    <TableRow key={categoryClassification.id}>
                      <TableCell align="center">
                        {categoryClassification.name}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleEditCategoryClassification(
                              categoryClassification
                            )
                          }
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingCategoryClassification(
                              categoryClassification
                            );
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton columns={1} />}
                </>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default CategoryClassification;
