import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { i18n } from "../../translate/i18n";




const useStyles = makeStyles((theme) => ({
  mainPaper: {
    margin: theme.spacing(1),
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  headerBar: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-around",
  },
}));

const Classifications = ({classifications}) => {
  const classes = useStyles();

  return (


          <Paper
            style={{ minHeight: "500px", maxHeight: "500px" }}
            className={classes.mainPaper}
            variant="outlined"
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {i18n.t("Classificação")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("Subclassificação")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("Observação")}
                  </TableCell>
                  <TableCell align="center">
                   <b>{i18n.t("Atendente")}</b>  <br/> {i18n.t("Fila")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("Conexão")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("Contato")}
                  </TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {classifications.map((classification) => (
                    <TableRow  key={classification.id}>
                      <TableCell align="center">
                        {classification?.categoryClassification?.name}
                      </TableCell>
                      <TableCell align="center">
                        {classification?.subCategoryClassification?.name}
                      </TableCell>
                      <TableCell align="center">
                        {classification?.description}
                      </TableCell>
                      <TableCell align="center">
                       <b>{classification?.user?.name}</b> 
                        <br/>
                        {classification?.queue?.name}
                      </TableCell>
                      <TableCell align="center">
                        {classification?.whatsapp?.name}
                      </TableCell>
                      <TableCell onClick={()=>console.log(classification?.ticket?.uuid)} align="center">
                     {classification?.contact?.name} <br/> {classification?.contact?.number}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </Paper>

  );
};

export default Classifications;
