import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NpsOptionModal from "../../components/NpsOptionModal";

const reducer = (state, action) => {
  if (action.type === "LOAD_DATAS") {
    const npsOptions = action.payload;
    const newnpsOptions = [];

    npsOptions.forEach((npsOption) => {
      const npsOptionIndex = state.findIndex(
        (u) => u.id === npsOption.id
      );
      if (npsOptionIndex !== -1) {
        state[npsOptionIndex] = npsOption;
      } else {
        newnpsOptions.push(npsOption);
      }
    });

    return [...state, ...newnpsOptions];
  }

  if (action.type === "UPDATE_DATAS") {
    const npsOption = action.payload;
    const npsOptionIndex = state.findIndex(
      (u) => u.id === npsOption.id
    );

    if (npsOptionIndex !== -1) {
      state[npsOptionIndex] = npsOption;
      return [...state];
    } else {
      return [npsOption, ...state];
    }
  }

  if (action.type === "DELETE_DATA") {
    const npsOptionId = action.payload;

    const npsOptionIndex = state.findIndex(
      (u) => u.id === npsOptionId
    );
    if (npsOptionIndex !== -1) {
      state.splice(npsOptionIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    margin: theme.spacing(1),
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  headerBar: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-around",
  },
}));

const NpsOptions = ({ npsSurveyId }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedNpsOption, setSelectedNpsSurvey] =
    useState(null);

  const [deletingNpsSurvey, setDeletingNpsSurvey] =
    useState(null);
  const [npsOptionModalOpen, setNpsSurveyModalOpen] =
    useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [npsOptions, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  const fetchnpsOptions = async () => {
    try {
      const { data } = await api.get(`/npsSurveyOption/${npsSurveyId}`, {
        params: { searchParam, pageNumber },
      });

      dispatch({
        type: "LOAD_DATAS",
        payload: data.npsOptions,
      });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchnpsOptions();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);



  const handleOpenNpsSurveyModal = () => {
    setSelectedNpsSurvey(null);
    setNpsSurveyModalOpen(true);
  };


  const handleCloseNpsSurveyModal = () => {
    setSelectedNpsSurvey(null);
    setNpsSurveyModalOpen(false);
    fetchnpsOptions();
  };



  const handleEditNpsSurvey = (npsOption) => {
    setSelectedNpsSurvey(npsOption);
    setNpsSurveyModalOpen(true);
  };

  const handleDeleteNpsSurvey = async (
    npsOptionId
  ) => {
    try {
      await api.delete(`/npsOption/${npsOptionId}`);
      toast.success(i18n.t("npsOption.toasts.deleted"));
      dispatch({ type: "RESET" });
      fetchnpsOptions();
    } catch (err) {
      toastError(err);
    }
    setDeletingNpsSurvey(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingNpsSurvey &&
          `${i18n.t("Deseja apagar esta nota de classificação? ")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() =>
          handleDeleteNpsSurvey(deletingNpsSurvey.id)
        }
      >
        {i18n.t("Este processo não pode ser desfeito")}
      </ConfirmationModal>
      <NpsOptionModal
        open={npsOptionModalOpen}
        npsSurveyId={npsSurveyId}
        onClose={handleCloseNpsSurveyModal}
        aria-labelledby="form-dialog-title"
        npsOptionId={
          selectedNpsOption && selectedNpsOption.id
        }
      />
      <MainHeader>
        <Title>{i18n.t("npsOptions.title")}</Title>
        <Button
          style={{ marginLeft: 56 }}
          variant="contained"
          color="primary"
          onClick={handleOpenNpsSurveyModal}
        >
          {i18n.t("npsOptions.buttons.add")}
        </Button>
      </MainHeader>
      <Grid spacing={12} container>
        <Grid item xs={12}>
          <div className={classes.headerBar}>

          </div>

          <Paper
            style={{ minHeight: "500px", maxHeight: "500px" }}
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {i18n.t("npsOptions.table.order")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("npsOptions.table.command")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("npsOptions.table.description")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("npsOptions.table.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {npsOptions.map((npsOption) => (
                    <TableRow key={npsOption.id}>
                      <TableCell align="center">
                        {npsOption?.order}
                      </TableCell>
                      <TableCell align="center">
                        {npsOption?.command}
                      </TableCell>
                      <TableCell align="center">
                        {npsOption?.description}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => { handleEditNpsSurvey(npsOption) }
                          }
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingNpsSurvey(
                              npsOption
                            );
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton columns={1} />}
                </>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default NpsOptions;
