import React from "react";
import CategoryClassification from "./category";
import { Grid } from "@material-ui/core";
import SubCategoryClassification from "./subCategory";

const Classification = () => {
  return (
    <div style={{margin:16}}>
    
      <Grid spacing={2} container>
        <Grid item xs={6}>
          <CategoryClassification />
        </Grid>
        <Grid item xs={6}>
          <SubCategoryClassification />
        </Grid>
      </Grid>
    </div>
  );
};

export default Classification;
